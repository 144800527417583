import React from "react";

type Props = {
  className: string;
};

const CrossIcon: React.FC<Props> = ({ className }) => (
  <svg className={className} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.41189 7L12.7119 2.71C12.9002 2.5217 13.006 2.2663 13.006 2C13.006 1.7337 12.9002 1.47831 12.7119 1.29C12.5236 1.1017 12.2682 0.995911 12.0019 0.995911C11.7356 0.995911 11.4802 1.1017 11.2919 1.29L7.00189 5.59L2.71189 1.29C2.52359 1.1017 2.26819 0.995911 2.00189 0.995911C1.73559 0.995911 1.4802 1.1017 1.29189 1.29C1.10359 1.47831 0.9978 1.7337 0.9978 2C0.9978 2.2663 1.10359 2.5217 1.29189 2.71L5.59189 7L1.29189 11.29C1.19816 11.383 1.12377 11.4936 1.073 11.6154C1.02223 11.7373 0.996094 11.868 0.996094 12C0.996094 12.132 1.02223 12.2627 1.073 12.3846C1.12377 12.5064 1.19816 12.617 1.29189 12.71C1.38486 12.8037 1.49546 12.8781 1.61732 12.9289C1.73917 12.9797 1.86988 13.0058 2.00189 13.0058C2.1339 13.0058 2.26461 12.9797 2.38647 12.9289C2.50833 12.8781 2.61893 12.8037 2.71189 12.71L7.00189 8.41L11.2919 12.71C11.3849 12.8037 11.4955 12.8781 11.6173 12.9289C11.7392 12.9797 11.8699 13.0058 12.0019 13.0058C12.1339 13.0058 12.2646 12.9797 12.3865 12.9289C12.5083 12.8781 12.6189 12.8037 12.7119 12.71C12.8056 12.617 12.88 12.5064 12.9308 12.3846C12.9816 12.2627 13.0077 12.132 13.0077 12C13.0077 11.868 12.9816 11.7373 12.9308 11.6154C12.88 11.4936 12.8056 11.383 12.7119 11.29L8.41189 7Z"
      fill="#1591F4"
    />
  </svg>
);

export default CrossIcon;
