import ClockIcon from "./ClockIcon";
import ReadMoreIcon from "./ReadMoreIcon";
import SudokuProIcon from "./SudokuProIcon";
import NextArrowIcon from "./NextArrowIcon";
import PrevArrowIcon from "./PrevArrowIcon";
import CrossIcon from "./CrossIcon";
import BurgerMenuIcon from "./BurgerMenuIcon";
import BurgerMenuCrossIcon from "./BurgerMenuCrossIcon";
import TwitterIcon from "./TwitterIcon";
import InstagramIcon from "./InstagramIcon";
import FacebookIcon from "./FacebookIcon";
import ChevronDownIcon from "./ChevronDownIcon";
import LoginIcon from "./LoginIcon";
import MedalIcon from "./MedalIcon";
import RocketIcon from "./RocketIcon";
import CupIcon from "./CupIcon";
import LogOutIcon from "./LogOutIcon";
import ChevronUpIcon from "./ChevronUpIcon";
import LoginFacebookIcon from "./LoginFacebookIcon";
import PlayIcon from "./PlayIcon";
import DeleteIcon from "./DeleteIcon";
import BuyMeACoffeeIcon from "./BuyMeACoffeeIcon";
import BuyMeACoffeeHeaderIcon from "./BuyMeACoffeeHeaderIcon";
import FacebookLegacyIcon from "./FacebookLegacyIcon";
import DiscordLegacyIcon from "./DiscordLegacyIcon";
import TelegramLegacyIcon from "./TelegramLegacyIcon";

export {
  LoginIcon,
  ChevronDownIcon,
  TwitterIcon,
  ReadMoreIcon,
  ClockIcon,
  SudokuProIcon,
  NextArrowIcon,
  PrevArrowIcon,
  CrossIcon,
  BurgerMenuIcon,
  BurgerMenuCrossIcon,
  InstagramIcon,
  FacebookIcon,
  MedalIcon,
  RocketIcon,
  CupIcon,
  LogOutIcon,
  ChevronUpIcon,
  LoginFacebookIcon,
  PlayIcon,
  DeleteIcon,
  BuyMeACoffeeIcon,
  BuyMeACoffeeHeaderIcon,
  FacebookLegacyIcon,
  DiscordLegacyIcon,
  TelegramLegacyIcon
};
