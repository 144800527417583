import { CONFIG } from "@/config";
import { Pagination } from "../types/post";

/**
 * @description Object containing all Content endpoints
 */
export const API_ROUTES = {
  POSTS: {
    GET_BY_URL: (url: string | string[] | undefined) => `posts/blog/${url}/`,
    ALL_POSTS: (
      limit: number = Pagination.limitPosts,
      page: number | string | string[] | undefined = Pagination.firstPage,
      language: string = "En"
    ) => `posts/blog/?page=${page}&limit=${Pagination.limitPosts}&language=${language}`,
    ALL_POSTS_URL: () => `posts/blog/url/`,
    GET_BY_ID: (id: string) => `posts/${id}/`,
  },
  CONTENT: {
    GET_CONTENT_BY_PATH: (path: string) => `projects/${CONFIG.CONTENT_SERVER_SECRET}/content-path/${path}/`,
    GET_CONTENT_SUPPORTED_TRANSLATIONS: (path: string) =>
      `projects/${CONFIG.CONTENT_SERVER_SECRET}/content-path/${path}/supported-translations`,
    GET_CONTENT_TRANSLATIONS: (path: string, languageCode: string) =>
      `projects/${CONFIG.CONTENT_SERVER_SECRET}/content-path/${path}/translations/${languageCode}`,
  },
};

/**
 * @description Object containing all Game endpoints
 */
export const API_ROUTES_GAME = {
  AUTH: {
    LOGIN_FACEBOOK: () => "auth/facebook-login/",
    LOGIN_GOOGLE: () => "auth/google-login/",
    FINISH_REGISTRATION: () => "auth/social-finish-registration/",
    REFRESH_TOKEN: () => "auth/refresh-token",
  },
  SUDOKU: {
    GET_SUDOKU_FOR_MONTH: () => `game/get-sudoku-for-month/`,
    PASSED_DAILY_LEVEL: () => `game/passed-daily-level`,
    SEND_USER_MONTH_STATS: () => `game/user-stats`,
    SYNC_OFFLINE_DAILY_GAMES: () => `game/sync-offline-daily-games`,
    SYNC_OFFLINE_USER_MONTH_STATS: () => `game/sync-offline-user-stats`,
    GET_CONSECUTIVE_DAYS: () => `game/consecutive-days`,
    GET_DAILY_PROGRESS: "game/daily-progress",
  },
  AWARDS: {
    GET_ANNUAL_AWARDS: (year: string) => `awards/annual/?year=${year}`,
  },
  POINTS: {
    GET_POINTS_FOR_MONTH: () => `points/get-points-for-month`,
    GET_POINTS: () => `points/get-points`,
  },
  USER: {
    DELETE_ACCOUNT: () => `user/delete-account/`,
  },
};
