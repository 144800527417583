import Container from "../Container";
import { BuyUsACoffee, Logo } from "../common";
import {
  Copyright,
  LogoWrapper,
  FooterWrapper,
  Levels,
  SiteMap,
  Wrapper,
  OtherGames,
  Topics,
  LinksWrapper,
} from "./Footer.components";

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <Container>
        <Wrapper>
          <LogoWrapper>
            <Logo section="footer" />
            <BuyUsACoffee section="footer" />
          </LogoWrapper>

          <LinksWrapper>
            <Levels />

            <SiteMap />

            <Topics />

            <OtherGames />
          </LinksWrapper>
        </Wrapper>
        <Copyright />
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
