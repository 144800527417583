import React from "react";
import { Icon } from "./types";

const ChevronUpIcon: React.FC<Icon> = ({ className }) => (
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M1.04327 6.71C1.13579 6.8027 1.24568 6.87625 1.36665 6.92643C1.48762 6.97661 1.61731 7.00244 1.74827 7.00244C1.87924 7.00244 2.00892 6.97661 2.1299 6.92643C2.25087 6.87625 2.36076 6.8027 2.45327 6.71L6.33327 2.83L10.2133 6.71C10.3059 6.80258 10.4158 6.87602 10.5367 6.92612C10.6577 6.97623 10.7873 7.00202 10.9183 7.00202C11.0492 7.00202 11.1789 6.97623 11.2998 6.92613C11.4208 6.87602 11.5307 6.80258 11.6233 6.71C11.7159 6.61742 11.7893 6.50751 11.8394 6.38654C11.8895 6.26558 11.9153 6.13593 11.9153 6.005C11.9153 5.87407 11.8895 5.74442 11.8394 5.62346C11.7893 5.50249 11.7159 5.39258 11.6233 5.3L7.03327 0.709998C6.94076 0.617295 6.83087 0.543747 6.7099 0.493565C6.58893 0.443384 6.45924 0.417554 6.32827 0.417554C6.19731 0.417554 6.06762 0.443384 5.94665 0.493565C5.82568 0.543747 5.71579 0.617294 5.62327 0.709998L1.03327 5.3C0.653274 5.68 0.653274 6.32 1.04327 6.71Z"
      fill="white"
    />
  </svg>
);

export default ChevronUpIcon;
