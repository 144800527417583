export const nl: any = {
  ISOcode: "nl",
  countryName: "Dutch",
  language: "Nederlands",
  dailyChallenges: "Dagelijkse Uitdagingen",
  dailyChallenge: "DAGELIJKSE UITDAGING",
  level: "Niveau: ",
  levels: "Niveaus",
  blog: "Bloggen",
  privacyPolicy: "Privacybeleid",
  howToPlay: "Hoe te spelen",
  newGame: "NIEUW SPEL",
  undo: "Ongedaan maken",
  notes: "Opmerkingen",
  hints: "Hints",
  erase: "Wis",
  nowDownload: "NU DOWNLOAD NU DOWNLOAD NU DOWNLOAD NU DOWNLOAD NU DOWNLOAD NU DOWNLOAD NU DOWNLOAD NU ",
  playBest: "Speel het beste",
  online: "Online",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Zie je, we hebben je verteld dat regels makkelijk zijn!",
  dontWasteYourTime: "Verspil geen tijd met twijfelen, ",
  appIsSudokuPro: "de beste sudoku app is Sudoku Pro, en die moet je proberen!",
  playOnline: "online spelen",
  points: "punten",
  yes: "Ja",
  no: "Nee",
  playMahjong: "Speel Mahjong online",
  siteMap: "Sitemap",
  otherGame: "Andere spellen",
  historySudoku: "Sudoku-geschiedenis",
  rulesSudoku: "Regels voor Sudoku",
  tipsSudoku: "Tips voor Sudoku",

  sudokuTipsAndTrick: "Sudoku Tips en Trucs - Van Noob tot SudokuPro",
  goBack: "Terug",
  readBlog: "Lees Blog",
  playSudokuOnline: "Sudoku online spelen",
  playNow: "NU SPELEN",
  cookieText:
    "Onze website gebruikt cookies om de inhoud te personaliseren en ons verkeer te analyseren, wat ook kan leiden tot profilering.",
  cancel: "Annuleren",
  oops: "Oeps...",
  errorPageNotFound: "404 fout. Pagina niet gevonden",
  sorryGoBackHome: "Het spijt ons, de gevraagde pagina kon niet gevonden worden. Ga terug naar de homepage.",
  backToHomepage: "TERUG NAAR DE HOMEPAGE",
  similarArticles: "Vergelijkbare artikelen",
  startYourBrainTraining:
    "Begin nu met je hersentraining, download het gratis online spel Sudoku Pro, en klaag niet dat je niet kunt stoppen met oplossen.",
  //////// Meta and Description /////////
  metaPageTitle: "Hoe Sudoku spelen - van beginner tot SudokuPro",
  metaPageDescription:
    "Lees de SudokuPro blog om alle tips en trucs te leren om Sudoku te spelen, of je nu een absolute beginner of een gevorderde speler bent. Krijg een eenvoudige uitleg van pro-strategieën om effectiever te spelen en de gezondheid van je hersenen een boost te geven.",

  metaBlogTitle: "Sudoku Tips en Trucs - Van Noob tot SudokuPro",
  metaBlogDescription:
    "Lees onze blog om alle trucs in Sudoku te leren voor beginners en gevorderde spelers. Krijg een eenvoudige uitleg van pro strategieën en tips voor het oplossen van Sudoku",

  /////////////Basic rules/////////////
  gameRules: {
    subtitle: "Sudoku gemakkelijk gemaakt",
    title: "Basisregels van Sudoku",
    titleText:
      "Als je een nieuweling bent in de Sudoku-wereld, hoef je je nergens voor te schamen. Dit spel is heel logisch en gemakkelijk te begrijpen. Er zijn 3 basisregels en afhankelijk van het type dat je kiest kunnen er wijzigingen zijn, maar de basisregels veranderen zelden.",
    goal: "Doel:",
    goalText:
      "Het invullen van een 9×9 rooster, zodat elke kolom, rij en 3×3 sectie de cijfers tussen 1 en 9 bevat. Traditioneel is er geen tijdslimiet, maar sommige Sudoku spellen voor de hersenen voegen tijdslimieten toe die aanzetten tot sneller denken.",
    ruleTitle: "Hoe speel je Sudoku?",
    ruleText:
      "Als je begint, hebben sommige vakjes van het 9×9 rooster al cijfers. Jouw taak is om je hersenkracht te gebruiken om de ontbrekende plekken in te vullen en het rooster compleet te maken.",
    fail: "Je faalt als:",
    list: "Elke rij heeft twee dezelfde cijfers <list> Elke kolom heeft twee dezelfde cijfers <list> Elk 3×3 rooster heeft twee dezelfde cijfers",
    remember: "Onthoud:",
    rememberText: "Elk cijfer van 1 tot 9 kan maar één keer gebruikt worden in een rij, kolom of 3×3 rooster.",
  },
  /////////// Months ////////
  January: "Januari",
  February: "Februari",
  March: "Maart",
  April: "April",
  May: "Mei",
  June: "Juni",
  July: "Juli",
  August: "Augustus",
  September: "September",
  October: "Oktober",
  November: "November",
  December: "December",

  /////Win poup////
  youWin: "Jij wint!",
  youWinText: "Download de app om altijd en zonder enige beperking te spelen",
  followUs: "Volg ons op Social Media:",
  winLevel: "Niveau",
  startNewGame: "Start nieuw spel",
  time: "Tijd",
  bestResult: "Beste resultaat",
  EASY: "Gemakkelijk",
  MEDIUM: "Medium",
  HARD: "Moeilijk",
  EXPERT: "Expert",
  EXTREME: "Extreem",
  /////////NoGame RC//////
  sorry: "sorry",
  gameNotFount: "spel niet gevonden",
  chooseChallenge: "Kies hier een uitdaging:",

  // Login Page
  loginMetaTitle: "Log in op Sudoku Pro",
  loginMetaDescription: "Log in om je spelvoortgang op te slaan en te concurreren met andere spelers",
  logIn: "Log in",
  welcomeBack: "Welkom terug",
  continueWithGoogle: "Ga verder met Google",
  continueWithFacebook: "Ga verder met Facebook",
  authorizationFailedMessage: "Autorisatie mislukt. Probeer het later opnieuw",

  // Registration Page
  registrationMetaTitle: "Registratie voltooien - Sudoku Pro",
  registrationMetaDescription:
    "Er is nog maar één kleine stap over om de registratie te voltooien en het meeste uit het Sudoku-spel te halen",
  createAnAccount: "Account aanmaken",
  nickName: "Bijnaam",
  country: "Land",
  signUp: "Aanmelden",
  createYourNickNameL: "Creëer je bijnaam",
  registrationFailedMessage: "Registratie mislukt. Probeer het later opnieuw",

  // Logout Dialog
  logOutMessage: "Weet u zeker dat u wilt uitloggen? Je voortgang wordt niet opgeslagen",
  logOut: "Uitloggen",

  // Awards Page
  awardsMetaTitle: "Jouw prijzen - Sudoku Pro",
  awardsMetaDescription: "Voltooi de dagelijkse uitdagingen in Sudoku Pro en verzamel alle beloningen voor het jaar!",
  awards: "Onderscheidingen",
  yourAwards: "Uw onderscheidingen",
  yourAwardsDescription: "Voltooi dagelijkse uitdagingen en ontvang beloningen aan het einde van elke maand!",
  awardsDontMissASingleDay: "Mis geen enkele dag en speel sudoku!",

  // Monthly Award Dialog
  awardDialogTitle: "Maandelijkse prijs ontvangen",
  awardDialogDescription:
    "Je hebt een maand lang alle dagelijkse uitdagingen voltooid en een prijs ontvangen! Gefeliciteerd!",
  awardShare: "Deel",

  // Authorization banner
  authorizationBannerMessage: "Log in om je voortgang en prijzen op te slaan 🏆",

  // You Won Dialog
  levelsLeftMessage: "Resterende niveaus om de beloning te krijgen: %s",

  // Ratings Block
  topCountriesToday: "TOP 20 landen vandaag",
  topUsersToday: "TOP 100 gebruikers vandaag",
  numberOfUsers: "Aantal gebruikers",
  updatedEvery24hours: "Elke 24 uur bijgewerkt",
  experiencePoints: "Ervaringspunten",
  showFullList: "Volledige lijst weergeven",
  showShortList: "Laat een korte lijst zien",
};
