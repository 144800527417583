export const mk: any = {
  ISOcode: "mk",
  countryName: "Македонија",
  language: "Македонски",
  blog: "Блог",
  dailyChallenges: "Дневни предизвици",
  dailyChallenge: "ДНЕВЕН ПРЕДИЗВИК",
  readBlog: "Прочитајте го блогот",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Судоку Про - загатка игра",
  sudokuTipsAndTrick: "Судоку онлајн - совети и трикови или како да станете професионалец",
  oops: "Упс...",
  errorPageNotFound: "Грешка 404. страната не е пронајдена",
  sorryGoBackHome:
    "Жал ни е, но страницата што ја побаравте не може да се најде. Ве молиме вратете се на почетната страница.",
  backToHomepage: "НАРАЗ НА ПОЧЕТНА СТРАНИЦА",
  cookieText:
    "Нашата веб-локација користи колачиња за да ја персонализира содржината и да го анализира нашиот сообраќај што исто така може да резултира со профилирање.",
  ok: "добро",
  privacyPolicy: "Политика за приватност",
  howToPlay: "Како да играш",
  undo: "Врати",
  notes: "Белешки",
  hints: "Совети",
  erase: "Избриши",
  newGame: "Нова игра",
  level: "Ниво: ",
  levels: "Нивоа",
  similarArticles: "Слични написи",
  playSudokuOnline: "Играјте Судоку онлајн",
  startYourBrainTraining:
    "Започнете со тренирање на мозокот сега, преземете ја бесплатната онлајн игра Судоку Про и не се жалете дека не можете да престанете да решавате.",
  nowDownload: "СЕГА ПРЕЗЕМИ СЕГА ПРЕЗЕМИ СЕГА ПРЕЗЕМИ СЕГА ПРЕЗЕМИ СЕГА ПРЕЗЕМИ",
  playBest: "Играј најдобро",
  online: "Онлајн",
  playSudoku: "Судоку!",
  seeWeToldYou: "Видете, ви кажавме дека правилата се лесни!",
  dontWasteYourTime: "Сега, не трошете го вашето време сомневајќи се, најдобрата",
  appIsSudokuPro: "апликација за судоку е Судоку Про, и мора да ја испробате!",
  goBack: "Врати се назад",
  facebook: "Facebook",
  twitter: "Twitter",
  telegram: "Telegram",
  points: "поени",
  yes: "Да",
  no: "бр",
  playMahjong: "Играјте Mahjong онлајн",
  siteMap: "Мапа на локацијата",
  otherGame: "Други игри",
  historySudoku: "Историја на Судоку",
  rulesSudoku: "Правила за Судоку",
  tipsSudoku: "Совети за Судоку",
  /////Win poup////
  youWin: "Ти Победи!",
  youWinText: "Преземете ја апликацијата за да играте во секое време со неколку предности",
  followUs: "Следете не на социјалните медиуми:",
  winLevel: "Ниво",
  startNewGame: "Започнете нова игра",
  time: "Време",
  bestResult: "Најдобар резултат",
  ////////////////////////
  playOnline: "игра онлајн",
  cancel: "Откажи",
  //////// Meta and Description /////////

  metaPageTitle: "Како да се игра Судоку - од Noob до СудокуПро",
  metaPageDescription:
    "Прочитајте го блогот SudokuPro за да ги научите сите совети и трикови за играње Судоку без разлика дали сте апсолутен почетник или напреден играч. Добијте лесно објаснување за професионални стратегии за поефикасно играње и зајакнување на здравјето на вашиот мозок",

  metaBlogTitle: "Судоку совети и трикови - од Noob до SudokuPro",
  metaBlogDescription:
    "Прочитајте го нашиот блог за да ги научите сите трикови во Судоку за почетници и стручни играчи. Добијте лесно објаснување за професионални стратегии и совети за решавање на Судоку",

  //////Basic rules///////

  gameRules: {
    subtitle: "Судоку е лесно",
    title: "Основни правила на Судоку",
    titleText:
      "Ако сте почетник во светот на Судоку, нема од што да се срамите. Оваа игра е многу логична и лесна за добивање. Постојат 3 основни правила и во зависност од типот што ќе го изберете, може да има измени, меѓутоа, поставените основни правила ретко се менуваат.",
    goal: "Цел:",
    goalText:
      "Пополнување на мрежа 9×9, така што секоја колона, ред и секција 3×3 ги содржи цифрите од 1 до 9. Традиционално не постои временско ограничување, но некои Судоку игри за мозокот додаваат временски ограничувања што поттикнуваат да размислуваат побрзо.",
    ruleTitle: "Како да се игра Судоку?",
    ruleText:
      "Кога ќе започнете, некои квадрати од решетката 9×9 веќе ќе имаат цифри. Ваша задача е да ја искористите вашата мозочна моќ за да ги пополните местата што недостасуваат и да ја завршите мрежата.",
    fail: "Не успеете ако:",
    list: "Секој ред има два исти броја <листа> Секоја колона има два исти броја <листа> Секоја мрежа 3×3 има два исти броја",
    remember: "Запомнете:",
    rememberText: "Секоја цифра од 1 до 9 може да се користи само еднаш по ред, колона или мрежа 3×3",
  },

  playNow: "ИГРАЈ СЕГА",
  /////////// Months ////////
  January: "Јануари",
  February: "Февруари",
  March: "Март",
  April: "Април",
  May: "Мај",
  June: "Јуни",
  July: "Јули",
  August: "Август",
  September: "Септември",
  October: "Октомври",
  November: "Ноември",
  December: "Декември",
  ///////// Game levels //////
  EASY: "Лесно",
  MEDIUM: "Средно",
  HARD: "Тешко",
  EXPERT: "Експерт",
  EXTREME: "Екстремни",
  /////////NoGame RC//////
  sorry: "извини",
  gameNotFount: "играта не е пронајдена",
  chooseChallenge: "Изберете предизвик овде:",

  // Login Page
  loginMetaTitle: "Најавете се на Судоку Про",
  loginMetaDescription:
    "Најавете се за да го зачувате напредокот на вашата игра и да се натпреварувате со другите играчи",
  logIn: "Логирај Се",
  welcomeBack: "Добредојде назад",
  continueWithGoogle: "Продолжете со Google",
  continueWithFacebook: "Продолжете со Фејсбук",
  authInfoModalTitleFailed: "Овластувањето не успеа",
  authorizationFailedMessage: "Овластувањето не успеа, обидете се повторно подоцна",

  // Registration Page
  registrationMetaTitle: "Завршете ја регистрацијата - Судоку Про",
  registrationMetaDescription:
    "Останува само еден мал чекор за да се заврши регистрацијата и да се извлече максимумот од играта Судоку",
  createAnAccount: "Креирај сметка",
  nickName: "Прекар",
  country: "Земја",
  signUp: "Пријавете се",
  createYourNickName: "Направете го вашиот прекар",
  authInfoModalRegistrationTitleErrors: "Регистрацијата не успеа",
  registrationFailedMessage: "Регистрацијата не успеа, обидете се повторно подоцна",

  // Logout Dialog
  logOutMessage: "Дали сте сигурни дека сакате да се одјавите? Вашиот напредок нема да биде зачуван",
  logOut: "Одјавете се",

  // Awards Page
  awardsMetaTitle: "Вашите награди - Судоку Про",
  awardsMetaDescription: "Завршете ги секојдневните предизвици во Судоку Про и соберете ги сите награди за годината!",
  awards: "Награди",
  yourAwards: "Вашите награди",
  yourAwardsDescription: "Завршете ги секојдневните предизвици и добијте награди на крајот од секој месец!",
  awardsDontMissASingleDay: "Не пропуштајте ниту еден ден - играјте судоку!",
  getAwards: "Добијте награди!",

  // Monthly Award Dialog
  awardDialogTitle: "Добиена месечна награда",
  awardDialogDescription: "Ги завршивте сите дневни предизвици за еден месец и добивте награда! Секоја чест!",
  awardShare: "Споделете",

  // Authorization banner
  authorizationBannerMessage: "Најавете се за да го зачувате вашиот напредок и награди 🏆",

  // You Won Dialog
  levelsLeftMessage: "🏆Преостанати нивоа за да ја добиете наградата: %s",
  finalPoints: "Final points",

  // Ratings Block
  topCountriesToday: "ТОП 20 земји денес",
  topUsersToday: "ТОП 100 корисници денес",
  numberOfUsers: "Број на корисници",
  updatedEvery24hours: "Ажурирано на секои 24 часа",
  experiencePoints: "Поени за искуство",
  showFullList: "Прикажи ја целата листа",
  showShortList: "Прикажи краток список",

  // ControlHintsModalWarning
  controlHintsModalWarningMessage: "Оваа функција не е достапна во секојдневните предизвици",

  // ConsecutiveDaysWarningModal
  consecutiveDaysModalText:
    "Број на последователни денови за игра. Играјте секој ден за да го подобрите ова достигнување!",
  consecutiveDaysModalTextLogIn: "Најавете се за да ги зачувате вашите достигнувања",
};
