import { BaseModal } from "..";
import {
  ButtonWrapper,
  CancelButton,
  DeleteButton,
  Description,
  Hint,
  Input,
  Title,
} from "./DeleteAccountModal.components";
import { DeleteAccountModalProps } from "./types";
import styles from "./DeleteAccountModal.module.scss";
import { ChangeEvent, useState } from "react";
import { useSession } from "@/context/SessionProvider";
import syncManager from "@/api/SyncManager";
import pointsManager from "@/api/PointsManager";
import { deleteAccout } from "@/api/userApis";
import { useRoutes } from "@/helpers/translationTexts/useTranslation";
import Router from "next/router";

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({ isOpen, onCloseClicked }) => {
  const [inputValue, setInputValue] = useState<string>("");
  const { userData, setUserPoints, setShockModeData, setUserData } = useSession();

  const rootPath = useRoutes().Root;

  const titleText = "Deleting account";
  const descText =
    "Deleting your account is permanent can cannot be undone. Are you sure you want to delete your account? If so, enter your nickname in the input field.";
  const hintText = userData?.name;
  const placeholderText = "Full name";
  const cancelText = "cancel";
  const deleteText = "delete";
  const inputName = "userName";
  const labelText = "Enter full name";
  const isDisabled = hintText?.toLowerCase().trim() !== inputValue.toLowerCase().trim();

  const handleClickDelete = async () => {
    await deleteAccout({ [inputName]: inputValue });
    handleCloseModal();
    syncManager.deleteSyncAwardsData(userData?._id);
    syncManager.clearUserData();
    pointsManager.remove();
    setUserPoints(0);
    setShockModeData(null);
    setUserData(null);
    Router.push(rootPath);
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value);

  const handleCloseModal = () => {
    onCloseClicked();
    setInputValue("");
  };

  return (
    <BaseModal isOpen={isOpen} onClose={handleCloseModal} needCloseBtn={false} modalWrapperStyles={styles.modal}>
      <Title title={titleText} />
      <Description text={descText} />
      <Hint text={hintText} />

      <Input
        name={inputName}
        onChange={handleChangeName}
        value={inputValue}
        placeholder={placeholderText}
        labelText={labelText}
      />

      <ButtonWrapper>
        <CancelButton title={cancelText} onClick={handleCloseModal} />
        <DeleteButton title={deleteText} onClick={handleClickDelete} disabled={isDisabled} />
      </ButtonWrapper>
    </BaseModal>
  );
};

export default DeleteAccountModal;
