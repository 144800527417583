export const zh: any = {
  ISOcode: "zh",
  countryName: "中国",
  language: "中国人",
  blog: "博客",
  dailyChallenges: "每日挑战",
  dailyChallenge: "每日的挑战",
  readBlog: "阅读博客",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Sudoku Pro — 益智游戏",
  sudokuTipsAndTrick: "在线数独 - 提示和技巧，或如何成为专业人士",
  oops: "哦...",
  errorPageNotFound: "404 錯誤。 找不到網頁",
  sorryGoBackHome: "抱歉，無法找到您請求的頁面。 請返回主頁。",
  backToHomepage: "返回首页",
  cookieText: "我们的网站使用 cookie 来个性化内容并分析我们的流量，这也可能导致分析。",
  ok: "好的",
  privacyPolicy: "隐私政策",
  howToPlay: "怎么玩",
  undo: "撤消",
  notes: "笔记",
  hints: "提示",
  erase: "擦除",
  newGame: "新游戏",
  level: "等级: ",
  levels: "等级",
  similarArticles: "类似文章",
  playSudokuOnline: "在线玩数独",
  startYourBrainTraining: "现在就开始你的大脑训练，下载免费的在线游戏 Sudoku Pro，不要抱怨你无法停止解决问题。",
  nowDownload: "立即下载 立即下载 立即下载 立即下载 立即下载",
  playBest: "发挥最好",
  online: "在线的",
  playSudoku: "数独！",
  seeWeToldYou: "看，我们告诉过你规则很简单！",
  dontWasteYourTime: "现在，不要浪费时间怀疑，最好的数独应用程序是",
  appIsSudokuPro: "Sudoku Pro，你一定要尝试一下！",
  goBack: "回去",
  facebook: "Facebook",
  twitter: "Twitter",
  telegram: "Telegram",
  points: "點",
  yes: "是的",
  no: "不",
  playMahjong: "在線玩麻將",
  siteMap: "站点地图",
  otherGame: "其他游戏",
  historySudoku: "数独历史",
  rulesSudoku: "数独规则",
  tipsSudoku: "数独小贴士",
  /////Win poup////
  youWin: "你赢了！",
  youWinText: "下载应用程序即可随时玩，享受多种好处",
  followUs: "在社交媒体上关注我们：",
  winLevel: "等级",
  startNewGame: "开始新游戏",
  time: "时间",
  bestResult: "最好的结果",
  ////////////////////////
  playOnline: "在线玩",
  cancel: "取消",
  //////// Meta and Description /////////

  metaPageTitle: "如何玩数独 — 从菜鸟到数独专家",
  metaPageDescription:
    "阅读 SudokuPro 博客，了解玩数独的所有提示和技巧，无论您是初学者还是高级玩家。 获得专业策略的简单解释，以提高游戏效率并提高您的记忆力。",

  metaBlogTitle: "数独提示和技巧 — 从菜鸟到数独专业人士",
  metaBlogDescription:
    "阅读我们的博客，了解适合初学者和专家玩家的数独技巧。 获得有关解决数独的专业策略和技巧的简单说明",

  //////Basic rules///////

  gameRules: {
    subtitle: "数独变得简单",
    title: "数独的基本规则",
    titleText:
      "如果您是数独世界的新手，没有什么可感到羞耻的。 这个游戏逻辑性很强，而且很容易上手。 有 3 条基本规则，根据您选择的类型，可能会有变化，但基本规则集很少改变。",
    goal: "目标：",
    goalText:
      "填充 9×9 的网格，使每一列、行和 3×3 部分包含 1 到 9 之间的数字。传统上没有时间限制，但一些针对大脑的数独游戏增加了时间限制，促使思考更快 。",
    ruleTitle: "如何玩数独？",
    ruleText: "当你开始时，9×9 网格的一些方格已经有数字了。 你的任务是利用你的脑力来填补缺失的点并完成网格。",
    fail: "如果出现以下情况，你就会失败：",
    list: "任意行有两个相同的数字 <list> 任意列有两个相同的数字 <list> 任意 3×3 网格有两个相同的数字",
    remember: "记住：",
    rememberText: "1 到 9 中的每个数字在行、列或 3×3 网格中只能使用一次",
  },
  playNow: "现在播放",
  /////////// Months ////////
  January: "一月",
  February: "二月",
  March: "三月",
  April: "四月",
  May: "五月",
  June: "六月",
  July: "七月",
  August: "八月",
  September: "九月",
  October: "十月",
  November: "十一月",
  December: "十二月",
  ///////// Game levels //////
  EASY: "简单的",
  MEDIUM: "中等的",
  HARD: "难的",
  EXPERT: "专家",
  EXTREME: "极端",
  /////////NoGame RC//////
  sorry: "对不起",
  gameNotFount: "找不到游戏",
  chooseChallenge: "在这里选择一个挑战：",

  // Login Page
  loginMetaTitle: "登錄數獨專業版",
  loginMetaDescription: "登錄以保存您的遊戲進度並與其他玩家競爭",
  logIn: "登錄",
  welcomeBack: "歡迎回來",
  continueWithGoogle: "繼續使用谷歌",
  continueWithFacebook: "繼續使用 Facebook",
  authInfoModalTitleFailed: "授权失败",
  authorizationFailedMessage: "授權失敗，請稍後重試",

  // Registration Page
  registrationMetaTitle: "完成註冊 - Sudoku Pro",
  registrationMetaDescription: "只需一小步即可完成註冊並充分享受數獨遊戲的樂趣",
  createAnAccount: "創建一個帳戶",
  nickName: "暱稱",
  country: "國家",
  signUp: "報名",
  createYourNickName: "創建您的暱稱",
  authInfoModalRegistrationTitleErrors: "注册失败",
  registrationFailedMessage: "註冊失敗，請稍後重試",

  // Logout Dialog
  logOutMessage: "您確定要退出嗎？ 您的進度將不會被保存",
  logOut: "登出",

  // Awards Page
  awardsMetaTitle: "您的獎項 - Sudoku Pro",
  awardsMetaDescription: "完成 Sudoku Pro 中的每日挑戰並收集全年所有獎勵！",
  awards: "獎項",
  yourAwards: "您的獎項",
  yourAwardsDescription: "完成每日挑戰並在每個月底獲得獎勵！",
  awardsDontMissASingleDay: "不要錯過任何一天 - 玩數獨！",
  getAwards: "获得奖励！",

  // Monthly Award Dialog
  awardDialogTitle: "獲得的月度獎勵",
  awardDialogDescription: "您已完成一個月內的所有每日挑戰並獲得獎勵！ 恭喜！",
  awardShare: "分享",

  // Authorization banner
  authorizationBannerMessage: "登錄以保存您的進度和獎勵🏆",

  // You Won Dialog
  levelsLeftMessage: "🏆獲得獎勵還剩多少級：%s",
  finalPoints: "Final points",

  // Ratings Block
  topCountriesToday: "當今排名前 20 的國家",
  topUsersToday: "今日前 100 位用戶",
  numberOfUsers: "用戶數量",
  updatedEvery24hours: "每24小時更新一次",
  experiencePoints: "經驗值",
  showFullList: "顯示完整列表",
  showShortList: "顯示簡短列表",

  // ControlHintsModalWarning
  controlHintsModalWarningMessage: "該功能在日常挑戰中不可用",

  // ConsecutiveDaysWarningModal
  consecutiveDaysModalText: "連續比賽天數。 每天玩來提升這個成就！",
  consecutiveDaysModalTextLogIn: "登入以保存您的成就",
};
