import { Icon } from "./types";

const TelegramLegacyIcon: React.FC<Icon> = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    data-name="Livello 1"
    viewBox="0 0 250 250"
  >
    <defs>
      <linearGradient id="linear-gradient" x1="120" x2="120" y1="240" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#1d93d2"></stop>
        <stop offset="1" stopColor="#38b0e3"></stop>
      </linearGradient>
    </defs>
    <g transform="translate(5 5)">
      <circle cx="120" cy="120" r="120" fill="url(#linear-gradient)"></circle>
      <path
        fill="#c8daea"
        d="M81.229 128.77l14.237 39.406s1.78 3.687 3.686 3.687 30.255-29.492 30.255-29.492l31.525-60.89-79.195 37.117z"
      ></path>
      <path fill="#a9c6d8" d="M100.11 138.88l-2.733 29.046s-1.144 8.9 7.754 0 17.415-15.763 17.415-15.763"></path>
      <path
        fill="#fff"
        d="M81.486 130.18L52.2 120.638s-3.5-1.42-2.373-4.64c.232-.664.7-1.229 2.1-2.2 6.489-4.523 120.11-45.36 120.11-45.36s3.208-1.081 5.1-.362a2.766 2.766 0 011.885 2.055 9.357 9.357 0 01.254 2.585c-.009.752-.1 1.449-.169 2.542-.692 11.165-21.4 94.493-21.4 94.493s-1.239 4.876-5.678 5.043a8.13 8.13 0 01-5.925-2.292c-8.711-7.493-38.819-27.727-45.472-32.177a1.27 1.27 0 01-.546-.9c-.093-.469.417-1.05.417-1.05s52.426-46.6 53.821-51.492c.108-.379-.3-.566-.848-.4-3.482 1.281-63.844 39.4-70.506 43.607a3.21 3.21 0 01-1.48.09z"
      ></path>
    </g>
  </svg>
);

export default TelegramLegacyIcon;
