import { Base, Button, LangListProps } from "./types";
import styles from "./LanguageSelector.module.scss";
import { StyledImg } from "../common";

export const SelectorWrapper: React.FC<Base> = ({ children }) => (
  <div className={styles.selectorWrapper}>{children}</div>
);

export const ToggleModalBtn: React.FC<Button> = ({ onClick, children }) => (
  <button className={styles.toggleModalBtn} onClick={onClick} type="button">
    {children}
  </button>
);

export const LangList: React.FC<LangListProps> = ({
  sortedLang,
  activeLanguage,
  handleClose,
  handleChangeLanguage,
  isOpen,
}) => {
  return (
    <ul className={`${styles.langListWrapper} ${isOpen && styles.visible}`}>
      {sortedLang.map(
        ({ ISOcode, countryName, language }: { ISOcode: string; countryName: string; language: string }) => {
          return (
            <li
              key={ISOcode}
              onClick={() => {
                activeLanguage === ISOcode ? handleClose() : handleChangeLanguage(ISOcode, language);
              }}
              className={activeLanguage === ISOcode ? styles.langItem + " " + styles.active : styles.langItem}
            >
              <StyledImg
                src={`/images/flags/flag-${ISOcode}.svg`}
                alt={`flag of ${countryName}`}
                width={24}
                height={16}
              />
              {language}
            </li>
          );
        }
      )}
    </ul>
  );
};
