import { useCallback, useEffect } from "react";
import {
  CloseModalButton,
  MonthlyAwardModalOverlay,
  MonthlyAwardModalContent,
  MonthlyAwardModalTitle,
  MonthlyAwardModalText,
  MonthlyAwardModalCup,
  MonthlyAwardModalOKButton,
} from "./MonthlyAwardModal.component";

type Props = {
  onClose: () => void;
  isOpen: boolean;
  monthNumber: string;
};

const MonthlyAwardModal: React.FC<Props> = ({ onClose, isOpen, monthNumber }) => {
  const handleCloseModal = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        onClose();
      }
    };
    window.addEventListener("keydown", handleEscKeyPress);

    return () => {
      window.removeEventListener("keydown", handleEscKeyPress);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return (
    <MonthlyAwardModalOverlay handleCloseModal={handleCloseModal}>
      <MonthlyAwardModalContent>
        <CloseModalButton handleCloseModal={onClose} />
        <MonthlyAwardModalTitle />
        <MonthlyAwardModalText />
        <MonthlyAwardModalCup monthCup={`/images/cup/received/${monthNumber}.svg`} />
        <MonthlyAwardModalOKButton />
      </MonthlyAwardModalContent>
    </MonthlyAwardModalOverlay>
  );
};

export default MonthlyAwardModal;
