import { useState } from "react";
import { UserAnnualAward } from "../api/models/userAnnualAward";
import { UserMonthStatsBody } from "../types/UserMonthStatsBody";

export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const USER_DATA = "userData";
export const ANNUAL_AWARDS = "annualAwards";
export const DATA_MIGRATED_KEY = "isNeedMigrated";
export const REFRESH_TOKEN_TIME = "refreshTokenTime";
export const USER_MONTH_STATS = "userMonthStats";
export const POINTS = "userPoints";
export const PATH_AUTH = "savedPathAuth";

function useLocalStorage(key: string, initialValue?: any) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    const parseJson = (value?: string) => {
      if (!value) {
        return value;
      }

      try {
        return JSON.parse(value);
      } catch (e) {
        // If not valid json
        return value;
      }
    };

    try {
      // Don't run this code in the Server Side app
      if (typeof window == "undefined") {
        return;
      }

      // Get from local storage by key
      const item = localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? parseJson(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value?: any) => {
    try {
      // Don't run this code in the Server Side app
      if (typeof window == "undefined") {
        return;
      }

      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (valueToStore == null) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

/**у
 *
 * @description Class containing methods to work with localStorage
 */
class LocalStorage {
  static getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN)?.replaceAll('"', "");
  }

  static getUserData() {
    // Don't run this code in the Server Side app
    if (typeof window == "undefined") {
      return null;
    }

    return localStorage.getItem(USER_DATA);
  }

  static removeUserData() {
    localStorage.removeItem(USER_DATA);
  }

  static getProposeDownloadAppShown() {
    return localStorage.getItem("isShowedProposeDownloadApp");
  }
  static setProposeDownloadAppShown() {
    localStorage.setItem("isShowedProposeDownloadApp", "true");
  }

  static getAllowedCookie() {
    return localStorage.getItem("allowedCookies");
  }
  static setAllowedCookie() {
    localStorage.setItem("allowedCookies", "true");
  }

  static setAnnualAwards(userId: string, year: string, data: UserAnnualAward[]) {
    if (typeof window !== "undefined") {
      const key = userId !== "" ? `${userId}-${ANNUAL_AWARDS}${year}` : `${ANNUAL_AWARDS}${year}`;
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  static getAnnualAwards(userId: string, year: string): UserAnnualAward[] {
    if (typeof window !== "undefined") {
      const key = userId !== "" ? `${userId}-${ANNUAL_AWARDS}${year}` : `${ANNUAL_AWARDS}${year}`;
      const annualAwards = localStorage.getItem(key);
      return !!annualAwards && annualAwards !== "undefined" ? JSON.parse(annualAwards) : [];
    }

    return [];
  }

  static setDailyChallenge(year: string, month: string, data: []) {
    if (typeof window !== "undefined") {
      const validatedMonth = month.length == 2 ? month : `0${month}`;
      localStorage.setItem(`dataGame${validatedMonth}${year}`, JSON.stringify(data));
    }
  }

  static getDailyChallenge(sudokuMonth: string, sudokuYear: string) {
    let dailyChallenge;
    if (typeof window !== "undefined") {
      dailyChallenge = localStorage.getItem(`dataGame${sudokuMonth}${sudokuYear}`);
    }
    const objDailyChallenge = !!dailyChallenge && dailyChallenge !== "undefined" ? JSON.parse(dailyChallenge) : null;
    return objDailyChallenge;
  }

  static setTimeRefreshToken(time: Date): void {
    if (typeof window !== "undefined") {
      localStorage.setItem(REFRESH_TOKEN_TIME, JSON.stringify(time));
    }
  }

  static getTimeRefreshToken(): Date | null {
    if (typeof window === "undefined") {
      return null;
    }

    const time = localStorage.getItem(REFRESH_TOKEN_TIME);
    if (!time) return null;

    const date = new Date(JSON.parse(time));
    return date;
  }

  static setUserMonthStats(userId: string, data: UserMonthStatsBody[]) {
    if (typeof window !== "undefined") {
      const key = userId === "" ? USER_MONTH_STATS : `${userId}-${USER_MONTH_STATS}`;
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  static getUserMonthStats(userId: string): UserMonthStatsBody[] {
    if (typeof window === "undefined") {
      return [];
    }
    const key = `${userId}${USER_MONTH_STATS}`;
    const savedUserMonthStats = localStorage.getItem(key);

    return (savedUserMonthStats && JSON.parse(savedUserMonthStats)) || [];
  }

  static removeUserMonthStats(userId: string) {
    if (typeof window !== "undefined") {
      const key = userId === "" ? USER_MONTH_STATS : `${userId}-${USER_MONTH_STATS}`;
      localStorage.removeItem(key);
    }
  }

  static setPoints(points: number) {
    if (typeof window !== "undefined") {
      localStorage.setItem(POINTS, JSON.stringify(points));
    }
  }

  static getPoints(): number {
    if (typeof window === "undefined") {
      return 0;
    }

    const savedPoints = localStorage.getItem(POINTS);

    return (savedPoints && JSON.parse(savedPoints)) || 0;
  }

  static removePoints() {
    if (typeof window !== "undefined") {
      localStorage.removeItem(POINTS);
    }
  }

  static setPathRedirectAfterLogin(path: string) {
    if (typeof window !== "undefined") {
      localStorage.setItem(PATH_AUTH, JSON.stringify(path));
    }
  }

  static getPathRedirectAfterLogin(): string {
    if (typeof window === "undefined") {
      return "";
    }

    const savedPath = localStorage.getItem(PATH_AUTH);

    return (savedPath && JSON.parse(savedPath)) || "";
  }

  static removePathRedirectAfterLogin() {
    if (typeof window !== "undefined") {
      localStorage.removeItem(PATH_AUTH);
    }
  }

  /**
   * @deprecated The method should not be used, only for migration
   */
  static setWonDailyChallenge(month: string, data: {}, year: string) {
    let wonHistory;
    if (typeof window !== "undefined") {
      const oldWonHistory = localStorage.getItem(`wonDataGame${month}${year}`);
      if (oldWonHistory) {
        const historyObject = JSON.parse(oldWonHistory);
        wonHistory = { ...historyObject, ...data };
      }
      if (!oldWonHistory) {
        wonHistory = data;
      }
      localStorage.setItem(`wonDataGame${month}${year}`, JSON.stringify(wonHistory));
    }
  }

  /**
   * @deprecated The method should not be used, only for migration
   */
  static getWonDailyChallenge(sudokuMonth: string, sudokuYear: string) {
    let wonHistory;
    if (typeof window !== "undefined") {
      wonHistory = localStorage.getItem(`wonDataGame${sudokuMonth}${sudokuYear}`);
    }
    const objWonHistory = !!wonHistory ? JSON.parse(wonHistory) : null;
    return objWonHistory;
  }

  /**
   * @deprecated The method should not be used, only for migration
   */
  static setBestResult(day: string, month: string, year: string, time: number) {
    let bestResult;
    if (typeof window !== "undefined") {
      const oldBestResult = localStorage.getItem(`bestResultGame${month}${year}`);
      if (oldBestResult) {
        const historyBestTime = JSON.parse(oldBestResult);
        bestResult = { ...historyBestTime, [day]: time };
      }
      if (!oldBestResult) {
        bestResult = { [day]: time };
      }
      localStorage.setItem(`bestResultGame${month}${year}`, JSON.stringify(bestResult));
    }
  }

  /**
   * @deprecated The method should not be used, only for migration
   */
  static getBestResult(day: string, month: string, year: string) {
    let bestResultGame;
    if (typeof window !== "undefined") {
      bestResultGame = localStorage.getItem(`bestResultGame${month}${year}`);
    }
    let bestResultDay = !!bestResultGame ? JSON.parse(bestResultGame) : null;
    bestResultDay = bestResultDay?.[day];
    return bestResultDay;
  }

  /**
   * @deprecated The method should not be used, only for migration
   */
  static setIsDataMigrated(isMigrated: boolean) {
    if (typeof window !== "undefined") {
      localStorage.setItem(DATA_MIGRATED_KEY, JSON.stringify(isMigrated));
    }
  }
  /**
   * @deprecated The method should not be used, only for migration
   */
  static setIsNeedMigrated(isMigrated: boolean) {
    if (typeof window !== "undefined") {
      localStorage.setItem(DATA_MIGRATED_KEY, JSON.stringify(isMigrated));
    }
  }

  /**
   * @deprecated The method should not be used, only for migration
   */
  static getIsNeedMigrated() {
    if (typeof window !== "undefined") {
      return JSON.parse(localStorage.getItem(DATA_MIGRATED_KEY) || JSON.stringify(""));
    }
  }

  /**
   * @deprecated The method should not be used, only for migration
   */
  static getIsDataMigrated() {
    if (typeof window !== "undefined") {
      return JSON.parse(localStorage.getItem(DATA_MIGRATED_KEY) || JSON.stringify(""));
    }
  }
  /**
   * @deprecated The method should not be used, only for migration
   */
  static removeIsDataMigrated() {
    if (typeof window !== "undefined") {
      localStorage.removeItem("isDataMigrated");
    }
  }

  static getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  static setRefreshToken(refreshToken: string | null) {
    return localStorage.setItem(REFRESH_TOKEN, refreshToken || "");
  }

  static setAccessToken(accessToken: string | null) {
    return localStorage.setItem(ACCESS_TOKEN, accessToken || "");
  }

  static removeTokens() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN_TIME);
  }

  static removeAnnualAwards(year: string, userId?: string) {
    if (typeof window !== "undefined") {
      const keyWithUserId = `${userId}-${ANNUAL_AWARDS}${year}`;
      const keyWithOutUserId = `${ANNUAL_AWARDS}${year}`;
      if (userId) {
        localStorage.removeItem(keyWithUserId);
      }

      localStorage.removeItem(keyWithOutUserId);
    }
  }

  /**
   * @deprecated The method should not be used, only for migration
   */
  static removeOldDataDailyGames(year: string, month: string): void {
    if (typeof window !== "undefined") {
      const keyWonDataGame = `wonDataGame${month}${year}`;
      const keyBestResultGame = `bestResultGame${month}${year}`;
      localStorage.removeItem(keyWonDataGame);
      localStorage.removeItem(keyBestResultGame);
    }
  }
}

export { LocalStorage, useLocalStorage };
