export const ko: any = {
  ISOcode: "ko",
  countryName: "대한민국",
  language: "한국인",
  blog: "블로그",
  dailyChallenges: "매일의 도전",
  dailyChallenge: "매일 도전",
  readBlog: "블로그 읽기",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Sudoku Pro - 퍼즐 게임",

  // 404 Page
  oops: "오",
  errorPageNotFound: "404 오류. 페이지를 찾을 수 없음",
  sorryGoBackHome: "죄송합니다. 요청하신 페이지를 찾을 수 없습니다. 홈페이지로 돌아가주세요.",
  backToHomepage: "홈페이지로 돌아 가기",
  goBack: "돌아가기",
  // Cookie Popup
  cookieText:
    "저희 웹사이트는 콘텐츠를 개인화하고 트래픽을 분석하기 위해 쿠키를 사용하며, 이로 인해 프로파일링이 될 수도 있습니다.",
  ok: "좋아요",

  privacyPolicy: "개인 정보 정책",
  howToPlay: "게임 방법",
  undo: "실행 취소",
  notes: "노트",
  hints: "힌트",
  erase: "삭제",
  newGame: "새로운 게임",
  level: "수준: ",
  levels: "레벨",
  similarArticles: "유사한 기사",
  playSudokuOnline: "온라인 스도쿠 플레이",
  startYourBrainTraining:
    "지금 뇌 트레이닝을 시작하세요. 무료 온라인 게임 Sudoku Pro를 다운로드하고, 푸는 것을 멈출 수 없다고 불평하지 마세요.",
  nowDownload: "지금 다운로드 지금 다운로드 지금 다운로드 지금 다운로드 지금 다운로드 지금 다운로드 지금 다운로드",
  playBest: "최고의 플레이",
  online: "온라인",
  playSudoku: "스도쿠!",
  seeWeToldYou: "보세요, 우리가 말했듯이 규칙은 쉽죠!",
  dontWasteYourTime: "이제 의심하지 말고 시간을 낭비하지 마세요. ",
  appIsSudokuPro: "최고의 수도쿠 앱은 Sudoku Pro이며, 꼭 시도해보세요!",
  facebook: "페이스북",
  twitter: "트위터",
  telegram: "전보",
  points: "포인트들",
  yes: "예",
  no: "아니요",
  playMahjong: "마작 온라인 플레이",
  siteMap: "사이트 맵",
  otherGame: "다른 게임",
  historySudoku: "스도쿠 역사",
  rulesSudoku: "스도쿠 규칙",
  tipsSudoku: "스도쿠 팁",
  /////Win poup////
  youWin: "승리!",
  youWinText: "어느 시간이든 제한 없이 플레이할 수 있는 앱을 다운로드하세요.",
  followUs: "소셜 미디어에서 저희를 팔로우하세요.",
  winLevel: "수준",
  startNewGame: "새로운 게임을 시작하다",
  time: "시간",
  bestResult: "최상의 결과",
  ////////////////////////
  playOnline: "온라인 플레이",
  cancel: "취소",
  //////// Meta and Description /////////
  // Blog Page Sreen
  metaPageTitle: "스도쿠를 플레이하는 방법 - 초보자부터 SudokuPro까지",
  metaPageDescription:
    "SudokuPro 블로그를 읽어보면 절대 초보자이건 고급 플레이어이건 상관없이 스도쿠를 플레이하는 모든 팁과 요령을 배울 수 있습니다. 더 효과적으로 플레이하고 두뇌 건강을 향상시키기 위한 전문가 전략에 대한 쉬운 설명을 얻으세요.",

  // Blog Screen
  metaBlogTitle: "수도쿠 팁과 요령 - 초보부터 수도쿠 프로까지",
  metaBlogDescription:
    "수도쿠 초보자와 전문 플레이어를 위한 모든 요령을 배울 수 있는 블로그를 읽어보세요. 전문가 전략에 대한 쉬운 설명과 수도쿠 해결을 위한 팁을 얻을 수 있습니다.",
  sudokuTipsAndTrick: "스도쿠 팁 및 트릭 - 초보자부터 스도쿠프로까지",

  //////Basic rules///////

  gameRules: {
    subtitle: "스도쿠는 간단합니다!",
    title: "스도쿠 게임의 기본 규칙",
    titleText:
      "수도쿠 세계에 새로운 사람이라면 부끄러울 것이 없습니다. 이 게임은 매우 논리적이고 이해하기 쉽습니다. 3가지 기본 규칙이 있으며 선택한 유형에 따라 약간의 변형이 있을 수 있지만, 기본 규칙 세트는 거의 변하지 않습니다.",
    goal: "목표:",
    goalText:
      "9×9 그리드에 숫자 1부터 9까지가 각 열, 행 및 3×3 섹션에 중복 없이 채워지도록 합니다. 전통적으로 시간 제한이 없지만, 일부 두뇌 수도쿠 게임은 더 빨리 생각하도록 시간 제한을 추가합니다.",
    ruleTitle: "스도쿠를 플레이하는 방법?",
    ruleText:
      "시작할 때, 9×9 그리드의 일부 칸에는 이미 숫자가 있을 것입니다. 여러분의 고민 능력을 사용하여 빈 칸을 채우고 그리드를 완성하는 것이 여러분의 임무입니다.",
    fail: "다음과 같은 경우 실패한 것입니다.",
    list: "어떤 행에 두 개의 동일한 숫자가 있는 경우 <list> 어떤 열에 두 개의 동일한 숫자가 있는 경우 <list> 어떤 3×3 그리드에 두 개의 동일한 숫자가 있는 경우",
    remember: "기억하다:",
    rememberText: "1부터 9까지의 각 숫자는 행, 열 또는 3×3 그리드 내에서 한 번씩만 사용할 수 있습니다.",
  },

  playNow: "지금 플레이",
  /////////// Months ////////
  January: "1월",
  February: "2월",
  March: "3월",
  April: "4월",
  May: "5월",
  June: "6월",
  July: "7월",
  August: "8월",
  September: "9월",
  October: "10월",
  November: "11월",
  December: "12월",
  ///////// Game levels //////
  EASY: "쉬운",
  MEDIUM: "중간",
  HARD: "단단한",
  EXPERT: "전문가",
  EXTREME: "극심한",
  /////////NoGame RC//////
  sorry: "죄송합니다",
  gameNotFount: "게임을 찾을 수 없음",
  chooseChallenge: "여기에서 챌린지를 선택하세요:",

  // Login Page
  loginMetaTitle: "스도쿠 프로에 로그인하세요",
  loginMetaDescription: "게임 진행 상황을 저장하고 다른 플레이어와 경쟁하려면 로그인하세요.",
  logIn: "로그인",
  welcomeBack: "돌아온 것을 환영합니다",
  continueWithGoogle: "Google로 계속하기",
  continueWithFacebook: "페이스북으로 계속하기",
  authorizationFailedMessage: "승인에 실패했습니다. 나중에 다시 시도해 주세요.",

  // Registration Page
  registrationMetaTitle: "등록 완료 - 스도쿠 프로",
  registrationMetaDescription: "등록을 완료하고 스도쿠 게임을 최대한 활용하려면 한 단계만 남았습니다.",
  createAnAccount: "계정 만들기",
  nickName: "별명",
  country: "국가",
  signUp: "가입하기",
  createYourNickName: "닉네임 만들기",
  registrationFailedMessage: "등록하지 못했습니다. 나중에 다시 시도해 주세요.",

  // Logout Dialog
  logOutMessage: "정말로 로그아웃하시겠습니까? 진행 상황은 저장되지 않습니다",
  logOut: "로그 아웃",

  // Awards Page
  awardsMetaTitle: "귀하의 수상 내역 - 스도쿠 프로",
  awardsMetaDescription: "Sudoku Pro에서 일일 도전 과제를 완료하고 올해의 모든 보상을 받으세요!",
  awards: "수상 내역",
  yourAwards: "당신의 상",
  yourAwardsDescription: "일일 도전 과제를 완료하고 매월 말에 보상을 받으세요!",
  awardsDontMissASingleDay: "단 하루도 놓치지 마세요 - 스도쿠를 즐겨보세요!",

  // Monthly Award Dialog
  awardDialogTitle: "월간상 수상",
  awardDialogDescription: "한 달 동안 일일 챌린지를 모두 완료하고 보상을 받았습니다! 축하해요!",
  awardShare: "공유하다",

  // Authorization banner
  authorizationBannerMessage: "진행 상황과 보상을 저장하려면 로그인하세요 🏆",

  // You Won Dialog
  levelsLeftMessage: "보상을 받을 때까지 남은 레벨: %s",

  // Ratings Block
  topCountriesToday: "현재 상위 20개 국가",
  topUsersToday: "현재 상위 100명의 사용자",
  numberOfUsers: "사용자 수",
  updatedEvery24hours: "24시간마다 업데이트됨",
  experiencePoints: "경험치",
  showFullList: "전체 목록 표시",
  showShortList: "짧은 목록 표시",
};
