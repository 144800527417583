import { useRouter } from "next/router";
import { LANGUAGES } from "../../constants/language";
import changeLanguage from "../../helpers/changeLanguage";
import { useEffect, useState } from "react";
import { LangList, SelectorWrapper, ToggleModalBtn } from "./LanguageSelector.components";
import { ChevronUpIcon } from "../../svg";
import styles from "./LanguageSelector.module.scss";
import { StyledImg } from "../common";
import {
  en,
  bg,
  bs,
  cs,
  da,
  de,
  el,
  es,
  et,
  fi,
  fr,
  hr,
  hu,
  id,
  it,
  ja,
  ka,
  kk,
  ko,
  lt,
  lv,
  mk,
  ms,
  nl,
  no,
  pl,
  pt,
  rom,
  sk,
  sl,
  sq,
  sr,
  sv,
  th,
  tr,
  uk,
  vi,
  zh,
} from "../../helpers/translationTexts";

const LanguageSelector = () => {
  const router = useRouter();
  const pathLang = router.asPath.split("/")[1];

  const [open, setOpen] = useState(false);
  const [langISO, setLangISO] = useState<string>(LANGUAGES.EN);
  const [language, setLanguage] = useState<string>("english");

  const languagesArray = [
    en,
    bg,
    bs,
    cs,
    da,
    de,
    el,
    es,
    et,
    fi,
    fr,
    hr,
    hu,
    id,
    it,
    ja,
    ka,
    kk,
    ko,
    lt,
    lv,
    mk,
    ms,
    nl,
    no,
    pl,
    pt,
    rom,
    sk,
    sl,
    sq,
    sr,
    sv,
    th,
    tr,
    uk,
    vi,
    zh,
  ];
  const sortedLanguages = languagesArray.sort((a, b) => a.language.localeCompare(b.language, "en"));

  useEffect(() => {
    if (!pathLang.length) return;
    setLangISO(pathLang.length > 2 ? LANGUAGES.EN : pathLang);
  }, [pathLang]);

  const handleChangeLanguage = (blogLanguage: string, language: string) => {
    changeLanguage(blogLanguage, router);
    handleClose();
    setLangISO(blogLanguage);
    setLanguage(language);
  };

  const handleOpen = () => setOpen((prev) => !prev);

  const handleClose = () => setOpen(false);

  return (
    <SelectorWrapper>
      <ToggleModalBtn onClick={handleOpen}>
        <StyledImg
          src={`/images/flags/flag-${langISO.toLowerCase()}.svg`}
          alt="flag of chosen language"
          width={25}
          height={17}
        />
        {language}
        <ChevronUpIcon className={styles.chevronIcon} />
      </ToggleModalBtn>

      <LangList
        handleClose={handleClose}
        handleChangeLanguage={handleChangeLanguage}
        sortedLang={sortedLanguages}
        activeLanguage={langISO}
        isOpen={open}
      />
    </SelectorWrapper>
  );
};

export default LanguageSelector;
