import { API_GAME } from "./api";
import { API_ROUTES_GAME } from "./apiRoutes";
import { LEVELS } from "../constants/levels";
import { UserMonthStatsBody } from "../types/UserMonthStatsBody";
import type { UserAnnualAward } from "./models/userAnnualAward";

export const getSudokuForMonth = async (year: string, month: string) => {
  try {
    const { data } = await API_GAME.post(API_ROUTES_GAME.SUDOKU.GET_SUDOKU_FOR_MONTH(), {
      year,
      month,
      level: LEVELS.EASY.toUpperCase(),
    });
    return data;
  } catch (error: any) {
    return { error: error.message };
  }
};

export const sendPassedDailyLevel = async (
  sudoku: string,
  solvedSudoku: string,
  date: string,
  wastedTimeSec: number,
  notes: boolean,
  hints: number,
  currentDateGame: string
) => {
  try {
    const { data } = await API_GAME.post(API_ROUTES_GAME.SUDOKU.PASSED_DAILY_LEVEL(), {
      sudoku,
      solvedSudoku,
      date,
      wastedTimeSec,
      level: LEVELS.EASY.toUpperCase(),
      notes,
      hints,
      currentDateGame,
    });
    return data;
  } catch (error: any) {
    return { error: error.message };
  }
};

export const syncOfflineDailyGames = async (completedGames: UserAnnualAward[]) => {
  try {
    const { data } = await API_GAME.post(API_ROUTES_GAME.SUDOKU.SYNC_OFFLINE_DAILY_GAMES(), completedGames);
    return data;
  } catch (error: any) {
    return { error: error.message };
  }
};

export const sendUserMonthStats = async (game: UserMonthStatsBody) => {
  try {
    const { data } = await API_GAME.post(API_ROUTES_GAME.SUDOKU.SEND_USER_MONTH_STATS(), game);
    return data;
  } catch (error: any) {
    return { error: error.message };
  }
};

export const syncOfflineUserMonthStats = async (offlineGames: UserMonthStatsBody[]) => {
  try {
    const { data } = await API_GAME.post(API_ROUTES_GAME.SUDOKU.SYNC_OFFLINE_USER_MONTH_STATS(), offlineGames);
    return data;
  } catch (error: any) {
    return { error: error.message };
  }
};

export const getShockModeDataApi = async () => {
  try {
    const { data } = await API_GAME.get(API_ROUTES_GAME.SUDOKU.GET_CONSECUTIVE_DAYS());

    return data;
  } catch (error: any) {
    return { error: error.message };
  }
};

export const getDailyProgressApi = async () => {
  try {
    const { data } = await API_GAME.get(API_ROUTES_GAME.SUDOKU.GET_DAILY_PROGRESS);
    return data;
  } catch (error: any) {
    return { error: error.message };
  }
};
