import { LEVELS } from "../constants/levels";

const LEVEL_POINTS = {
  [LEVELS.EASY.toLowerCase()]: 200,
  [LEVELS.MEDIUM.toLowerCase()]: 300,
  [LEVELS.HARD.toLowerCase()]: 400,
  [LEVELS.EXPERT.toLowerCase()]: 700,
  [LEVELS.EXTREME.toLowerCase()]: 1000,
};
export default LEVEL_POINTS;
