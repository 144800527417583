export const parseDate = (date: string): { day: string; month: string; year: string } => {
  const dateSplit = date!.split("-");
  const year = dateSplit[0];
  const month = dateSplit[1];
  const day = dateSplit[2];
  return { year, month, day };
};

export const daysInMonth = (year: string, month: string): number => {
  return new Date(Number(year), Number(month), 0).getDate();
};

export const currentDateAsString = (): { date: string; day: string; month: string; year: string } => {
  const today = new Date();
  const year = today.getFullYear().toString();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return { date: `${year}-${month}-${day}`, day, month, year };
};
