export const bs: any = {
  ISOcode: "bs",
  countryName: "Bosna i Hercegovina",
  language: "Bosanski",
  blog: "Blog",
  dailyChallenges: "Dnevni izazov",
  dailyChallenge: "Dnevni izazov",
  readBlog: "Pročitajte blog",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Sudoku Pro je puzzle igra",
  sudokuTipsAndTrick: "Sudoku online - savjeti i trikovi, ili kako postati profesionalac",
  oops: "ooh...",
  errorPageNotFound: "404 greška. stranica nije pronađena",
  sorryGoBackHome: "Nažalost, stranica koju ste tražili nije pronađena. Vratite se na glavnu stranicu.",
  backToHomepage: "NAZAD NA POČETNU STRANICU",
  cookieText:
    "Nastavkom pretraživanja stranice potvrđujete da ste pročitali Politiku privatnosti stranice i da se slažete s korištenjem kolačića.",
  ok: "uredu",
  privacyPolicy: "Politika privatnosti",
  howToPlay: "Kako igrati",
  undo: "Otkaži",
  notes: "Bilješke",
  hints: "Hints",
  erase: "Izbriši",
  newGame: "Nova Igra",
  level: "Nivo: ",
  levels: "Nivoi",
  similarArticles: "Slični članci",
  playSudokuOnline: "Igrajte sudoku online",
  startYourBrainTraining:
    "Počnite razvijati svoj mozak odmah! Preuzmite besplatnu Sudoku Pro online igru ​​i prestanite se žaliti da ne možete prestati rješavati Sudoku zagonetke.",
  nowDownload: "PREUZMITE SADA PREUZMI SADA PREUZMI SADA PREUZMI SADA PREUZMI SADA PREUZMI SADA PREUZMI SADA",
  playBest: "Igraj najbolje",
  online: "Online",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Vidite, rekli smo da su pravila jednostavna!",
  dontWasteYourTime: "Sada ne gubite vrijeme na nedoumice, najbolja Sudoku",
  appIsSudokuPro: "aplikacija je Sudoku Pro i trebali biste je isprobati!",
  goBack: "Vrati se",
  facebook: "Facebook",
  twitter: "Twitter",
  telegram: "Telegram",
  points: "bodova",
  yes: "Da",
  no: "br",
  playMahjong: "Igrajte Mahjong Online",
  siteMap: "Mapa sajta",
  otherGame: "Ostale igre",
  historySudoku: "Sudoku History",
  rulesSudoku: "Rules for Sudoku",
  tipsSudoku: "Tips for Sudoku",
  /////Win poup////
  youWin: "Pobjeda!",
  youWinText: "Preuzmite aplikaciju da igrate bilo kada bez ograničenja",
  followUs: "Pratite nas na društvenim mrežama:",
  winLevel: "Nivo",
  startNewGame: "Započnite novu igru",
  time: "Vrijeme",
  bestResult: "Bolji rezultat",
  ////////////////////////
  playOnline: "igrati online",
  cancel: "Otkaži",
  //////// Meta and Description /////////
  metaPageTitle: "Kako igrati Sudoku — od početnika do Sudoku Pro",
  metaPageDescription:
    "Pročitajte Sudoku Pro blog za savjete i trikove o tome kako igrati Sudoku, bez obzira na sadašnjem nivou. Dobijte jednostavno objašnjenje složenih strategija zagonetke kako biste igrali efikasnije i poboljšali svoje pamćenje.",

  metaBlogTitle: "Sudoku Online - savjeti i trikovi | Od početnika do Sudoku Pro",
  metaBlogDescription:
    "Pročitajte naš Sudoku Pro blog da saznate o svim karakteristikama Sudokua za početnike i napredne igrače. Dobijte jednostavno objašnjenje profesionalnih strategija i savjeta za rješavanje sudokua na mreži.",

  //////Basic rules///////

  gameRules: {
    subtitle: "Sudoku je jednostavan!",
    title: "Osnovna pravila Sudoku igre",
    titleText:
      "Ako ste početnik i ne znate kako riješiti sudoku, nemate čega da se stidite. Ova igra je vrlo logična i naučiti kako igrati sudoku je vrlo lako. Postoje 3 osnovna pravila, a ovisno o vrsti slagalice koju odaberete, mogu postojati neke nijanse, ali osnovna pravila Sudokua ostaju ista.",
    goal: "Cilj:",
    goalText:
      "Popunite mrežu 9×9 tako da svaka kolona, ​​red i dio 3×3 sadrže brojeve od 1 do 9. Tradicionalno, ne postoji vremensko ograničenje, ali neke online sudoku zagonetke dodaju vremensko ograničenje i čini se da vas podstiču na brže razmišljanje.",
    ruleTitle: "Kako igrati Sudoku?",
    ruleText:
      "Kada počnete, neki od kvadrata mreže 9×9 će već imati brojeve. Vaš zadatak je da popunite praznine bez ostavljanja praznog prostora.",
    fail: "Ne uspijevate ako:",
    list: "Bilo koji red sadrži dva identična broja <list> Bilo koja kolona sadrži dva identična broja <list> Bilo 3×3 dio sadrži dva identična broja",
    remember: "ZAPAMTITE:",
    rememberText: "Svaki broj od 1 do 9 može se koristiti samo jednom u redu, koloni ili dijelu 3×3",
  },

  playNow: "Igraj sada",
  /////////// Months ////////
  January: "Januar",
  February: "Februar",
  March: "Mart",
  April: "April",
  May: "Maja",
  June: "Juna",
  July: "Jula",
  August: "Avgust",
  September: "Septembra",
  October: "Oktobar",
  November: "Novembar",
  December: "Decembar",
  ///////// Game levels //////
  EASY: "Lako",
  MEDIUM: "Prosjek",
  HARD: "Tesko",
  EXPERT: "Ekspert",
  EXTREME: "Ekstremno",
  /////////NoGame RC//////
  sorry: "Izvini",
  gameNotFount: "igra nije pronađena",
  chooseChallenge: "Odaberite izazov ovdje:",

  // Login Page
  loginMetaTitle: "Prijavite se na Sudoku Pro",
  loginMetaDescription: "Prijavite se da sačuvate napredak u igri i takmičite se s drugim igračima",
  logIn: "Ulogovati se",
  welcomeBack: "Prijavite se Dobrodošli nazad",
  continueWithGoogle: "Nastavite s Googleom",
  continueWithFacebook: "Nastavite s Facebookom",
  authorizationFailedMessage: "Autorizacija nije uspjela, pokušajte ponovo kasnije",

  // Registration Page
  registrationMetaTitle: "Završi registraciju - Sudoku Pro",
  registrationMetaDescription:
    "Ostao je samo jedan mali korak da završite registraciju i izvučete maksimum iz igre Sudoku",
  createAnAccount: "Kreirajte nalog",
  nickName: "Nadimak",
  country: "Država",
  signUp: "Prijaviti se",
  createYourNickName: "Kreirajte svoj nadimak",
  registrationFailedMessage: "Registracija nije uspjela, pokušajte ponovo kasnije",

  // Logout Dialog
  logOutMessage: "Jeste li sigurni da se želite odjaviti? Vaš napredak neće biti sačuvan",
  logOut: "Odjaviti se",

  // Awards Page
  awardsMetaTitle: "Vaše nagrade - Sudoku Pro",
  awardsMetaDescription: "Dovršite svakodnevne izazove u Sudoku Pro i sakupite sve nagrade za godinu!",
  awards: "Nagrade",
  yourAwards: "Vaše nagrade",
  yourAwardsDescription: "Završite dnevne izazove i osvojite nagrade na kraju svakog mjeseca!",
  awardsDontMissASingleDay: "Ne propustite nijedan dan - igrajte sudoku!",

  // Monthly Award Dialog
  awardDialogTitle: "Dobijena mjesečna nagrada",
  awardDialogDescription: "Odradili ste sve dnevne izazove za mjesec dana i dobili nagradu! Čestitamo!",
  awardShare: "Dijeli",

  // Authorization banner
  authorizationBannerMessage: "Prijavite se da sačuvate svoj napredak i nagrade 🏆",

  // You Won Dialog
  levelsLeftMessage: "Preostali nivoi da dobijete nagradu: %s",

  // Ratings Block
  topCountriesToday: "TOP 20 zemalja danas",
  topUsersToday: "TOP 100 korisnika danas",
  numberOfUsers: "Broj korisnika",
  updatedEvery24hours: "Ažurira se svaka 24 sata",
  experiencePoints: "Iskustveni bodovi",
  showFullList: "Prikaži punu listu",
  showShortList: "Show a short list",
};
