export const et: any = {
  ISOcode: "et",
  countryName: "Estonia",
  language: "Eesti",
  dailyChallenges: "Igapäevased väljakutsed",
  dailyChallenge: "VÄLJAKUTSE: IGAPÄEVANE VÄLJAKUTSE",
  level: "Tasand: ",
  levels: "Tasemed",
  blog: "Blogi",
  privacyPolicy: "Privaatsuspoliitika",
  howToPlay: "Kuidas mängida",
  newGame: "UUS MÄNG",
  undo: "Undo",
  notes: "Märkused",
  hints: "Vihjed",
  erase: "Kustuta",
  nowDownload:
    "NÜÜD LAE ALLA NÜÜD LAE ALLA NÜÜD LAE ALLA NÜÜD LAE ALLA NÜÜD LAE ALLA NÜÜD LAE ALLA NÜÜD LAE ALLA NÜÜD LAE ALLA NÜÜD ",
  playBest: "Mängige kõige paremini",
  online: "Internetis",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Näete, me ütlesime teile, et reeglid on lihtsad!",
  dontWasteYourTime: "Nüüd, ärge raiska oma aega kahtlema,",
  appIsSudokuPro: "parim sudoku rakendus on Sudoku Pro ja sa pead seda proovima!",
  playOnline: "mängida online",
  points: "punktid",
  yes: "Jah",
  no: "Ei",
  playMahjong: "Mängige võrgus Mahjongi",
  siteMap: "Saidi kaart",
  otherGame: "Muud mängud",
  historySudoku: "Sudoku ajalugu",
  rulesSudoku: "Sudoku reeglid",
  tipsSudoku: "Näpunäiteid Sudoku jaoks",
  sudokuTipsAndTrick: "Sudoku näpunäited ja nipid - Noobist SudokuPro'ni",
  goBack: "Mine tagasi",
  readBlog: "Loe blogi",
  playSudokuOnline: "Mängi Sudokut internetis",
  playNow: "MÄNGI NÜÜD",
  cookieText:
    "Meie veebisait kasutab küpsiseid sisu isikupärastamiseks ja meie liikluse analüüsimiseks, mis võib samuti viia profiilide koostamiseni.",
  cancel: "Tühista",
  oops: "Ooops...",
  errorPageNotFound: "404 viga. Lehekülge ei leitud",
  sorryGoBackHome: "Meil on kahju, taotletud lehekülge ei õnnestunud leida. Palun minge tagasi kodulehele.",
  backToHomepage: "TAGASI AVALEHELE",
  similarArticles: "Sarnased artiklid",
  startYourBrainTraining:
    "Alusta nüüd oma ajutreeningut, lae alla tasuta online mäng Sudoku Pro ja ära kurda, et sa ei saa lõpetada lahendamist.",
  //////// Meta and Description /////////
  metaPageTitle: "Kuidas mängida Sudokut - alates Noobist SudokuProni",
  metaPageDescription:
    "Loe SudokuPro blogi, et õppida kõiki nõuandeid ja nippe Sudoku mängimiseks, olenemata sellest, kas oled absoluutne algaja või edasijõudnud mängija. Saate lihtsa selgituse pro strateegiate kohta, et mängida tõhusamalt ja suurendada oma aju tervist",

  metaBlogTitle: "Sudoku näpunäited ja nipid - Noobist SudokuPro'ni",
  metaBlogDescription:
    "Loe meie blogi, et õppida kõiki trikke Sudoku algajatele ja ekspertidele. Saate lihtsa selgituse profistrateegiate ja nõuannete kohta Sudoku lahendamiseks.",

  /////////////Basic rules/////////////
  gameRules: {
    subtitle: "Sudoku lihtsaks tehtud",
    title: "Sudoku põhireeglid | Kuidas lahendada sudokut",
    titleText:
      "Kui oled Sudoku maailmas algaja, ei ole midagi häbeneda. See mäng on väga loogiline ja lihtne saada. On 3 põhireeglit ja sõltuvalt valitud tüübist võib olla muudatusi, kuid põhireeglite kogum muutub harva.",
    goal: "Eesmärk:",
    goalText:
      "Täita 9×9 ruudustik nii, et iga veerg, rida ja 3×3 jagu sisaldaks numbreid vahemikus 1 kuni 9. Traditsiooniliselt ei ole ajalist piirangut, kuid mõned Sudoku mängud ajudele lisavad ajalisi piiranguid, mis sunnivad kiiremini mõtlema.",
    ruleTitle: "Kuidas Sudokut mängida?",
    ruleText:
      "Kui alustad, on mõnes 9×9 ruudu ruudus juba numbreid. Sinu ülesanne on kasutada oma ajujõudu, et täita puuduolevad kohad ja täita ruudustik.",
    fail: "Sa ebaõnnestud, kui:",
    list: "Igas reas on kaks sama numbrit <list> Igas veerus on kaks sama numbrit <list> Igas 3×3 ruudustikus on kaks sama numbrit",
    remember: "Pea meeles:",
    rememberText: "Iga numbrit 1 kuni 9 võib kasutada ainult üks kord reas, veerus või 3×3 ruudustikus.",
  },

  /////////// Months ////////
  January: "Jaanuar",
  February: "Veebruar",
  March: "Märts",
  April: "Aprill",
  May: "Mai",
  June: "Juuni",
  July: "Juuli",
  August: "August",
  September: "September",
  October: "Oktoober",
  November: "November",
  December: "Detsember",

  /////Win poup////
  youWin: "Sa oled võitnud!!",
  youWinText: "Laadige rakendus alla, et mängida igal ajal ja ilma piiranguteta",
  followUs: "Jälgi meid sotsiaalmeedias:",
  winLevel: "Tasand",
  startNewGame: "Alusta uut mängu",
  time: "Aeg",
  bestResult: "Parim tulemus",
  EASY: "Lihtne",
  MEDIUM: "Keskmine",
  HARD: "Raske",
  EXPERT: "Ekspert",
  EXTREME: "Extreme",
  /////////NoGame RC//////
  sorry: "Vabandust",
  gameNotFount: "mängu ei leitud",
  chooseChallenge: "Vali väljakutse siin:",

  // Login Page
  loginMetaTitle: "Logige sisse Sudoku Pro-sse",
  loginMetaDescription: "Mängu edenemise salvestamiseks ja teiste mängijatega võistlemiseks logige sisse",
  logIn: "Logi sisse",
  welcomeBack: "Tere tulemast tagasi",
  continueWithGoogle: "Jätkake Google'iga",
  continueWithFacebook: "Jätka Facebookiga",
  authorizationFailedMessage: "Autorizácia zlyhala, skúste to znova neskôr",

  // Registration Page
  registrationMetaTitle: "Lõpeta registreerimine – Sudoku Pro",
  registrationMetaDescription:
    "Registreerimise lõpuleviimiseks ja Sudoku mängust maksimumi saamiseks on jäänud vaid üks väike samm",
  createAnAccount: "Kontot looma",
  nickName: "Hüüdnimi",
  country: "Riik",
  signUp: "Registreeri",
  createYourNickName: "Looge oma hüüdnimi",
  registrationFailedMessage: "Registreerimine ebaõnnestus, proovige hiljem uuesti",

  // Logout Dialog
  logOutMessage: "Kas soovite kindlasti välja logida? Teie edusamme ei salvestata",
  logOut: "Logi välja",

  // Awards Page
  awardsMetaTitle: "Teie auhinnad – Sudoku Pro",
  awardsMetaDescription: "Täitke Sudoku Pro igapäevased väljakutsed ja koguge aasta eest kõik auhinnad!",
  awards: "Auhinnad",
  yourAwards: "Teie auhinnad",
  yourAwardsDescription: "Täitke igapäevaseid väljakutseid ja saage iga kuu lõpus preemiaid!",
  awardsDontMissASingleDay: "Ära jäta ühtegi päeva vahele – mängi sudokut!",

  // Monthly Award Dialog
  awardDialogTitle: "Kuupreemia saadud",
  awardDialogDescription: "Olete kuu aja jooksul läbinud kõik igapäevased väljakutsed ja saanud auhinna! Palju õnne!",
  awardShare: "Jaga",

  // Authorization banner
  authorizationBannerMessage: "Oma edusammude ja auhindade salvestamiseks logige sisse 🏆",

  // You Won Dialog
  levelsLeftMessage: "Auhinna saamiseks jäänud tasemed: %s",

  // Ratings Block
  topCountriesToday: "TOP 20 riiki täna",
  topUsersToday: "TOP 100 kasutajat täna",
  numberOfUsers: "Kasutajate arv",
  updatedEvery24hours: "Värskendatakse iga 24 tunni järel",
  experiencePoints: "Kogemuspunktid",
  showFullList: "Kuva täielik nimekiri",
  showShortList: "Näita lühikest nimekirja",
};
