import { useRouter } from "next/router";
import { useSession } from "../../context/SessionProvider";
import useTranslation, { useRoutes } from "../../helpers/translationTexts/useTranslation";
import { BuyUsACoffee, Socials, StyledLink } from "../common";
import styles from "./Footer.module.scss";
import { Base } from "./types";
import { isEnglishLang } from "../../utils/checkPageByPath";
import { useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@/svg";
import useMediaQuery from "@/utils/useMediaQuery";
import { TABLET_MEDIA_QUERY } from "@/constants/breakpoints";
import LanguageSelector from "../LanguageSelector";

export const LogoWrapper: React.FC<Base> = ({ children }) => <div className={styles.logoWrapper}>{children}</div>;

export const FooterWrapper: React.FC<Base> = ({ children }) => <footer className={styles.footer}>{children}</footer>;

export const Wrapper: React.FC<Base> = ({ children }) => <div className={styles.wrapper}>{children}</div>;

export const LinksWrapper: React.FC<Base> = ({ children }) => <div className={styles.linksWrapper}>{children}</div>;

export const Copyright: React.FC = () => (
  <div className={styles.copyrightWrapper}>
    <LanguageSelector />
    <div>
      <span className={styles.copyrightText}>Copyright © {new Date().getFullYear()}.</span>{" "}
      <StyledLink
        href="https://omiplay.com?utm_source=sudokupro.app&utm_medium=referral"
        target="_blank"
        rel
        ariaLabel="Go to OmiPlay"
        className={styles.omiPlayLink}
      >
        OmiPlay
      </StyledLink>
      <span className={styles.copyrightText}>. All Rights Reserved</span>
    </div>
    <Socials section="footer" />
  </div>
);

export const Levels = () => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const isTablet = useMediaQuery(TABLET_MEDIA_QUERY);

  const easyLink = useRoutes().Easy;
  const mediumLink = useRoutes().Medium;
  const hardLink = useRoutes().Hard;
  const expertLink = useRoutes().Expert;
  const extremeLink = useRoutes().Extreme;

  const easyText = useTranslation("EASY");
  const mediumText = useTranslation("MEDIUM");
  const hardText = useTranslation("HARD");
  const expertText = useTranslation("EXPERT");
  const extremeText = useTranslation("EXTREME");
  const levelsText = useTranslation("levels");

  useEffect(() => {
    if (isTablet) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [isTablet]);

  const handleClickOnTitle = () => setIsActive((prev) => !prev);

  return (
    <div className={isActive ? styles.listItemWrapper + " " + styles.listItemWrapperActive : styles.listItemWrapper}>
      <h2 className={styles.listItemTitle} onClick={handleClickOnTitle}>
        {levelsText} {isActive ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </h2>
      <ul className={styles.listWrapper}>
        <li>
          <StyledLink className={styles.listLink} href={easyLink}>
            {easyText}
          </StyledLink>
        </li>
        <li>
          <StyledLink className={styles.listLink} href={mediumLink}>
            {mediumText}
          </StyledLink>
        </li>
        <li>
          <StyledLink className={styles.listLink} href={hardLink}>
            {hardText}
          </StyledLink>
        </li>
        <li>
          <StyledLink className={styles.listLink} href={expertLink}>
            {expertText}
          </StyledLink>
        </li>
        <li>
          <StyledLink className={styles.listLink} href={extremeLink}>
            {extremeText}
          </StyledLink>
        </li>
      </ul>
    </div>
  );
};

export const SiteMap = () => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const isTablet = useMediaQuery(TABLET_MEDIA_QUERY);

  const { annualAwardsCount } = useSession();
  const path = useRouter().asPath;
  const isEn = isEnglishLang(path);

  const dailySudokuLink = useRoutes().DailySudoku;
  const awardsLink = useRoutes().Awards;
  const blogPath = useRoutes().Blog as string;
  const privacyPolicyLink = useRoutes().Privacy;

  const dailySudokuText = useTranslation("dailyChallenges");
  const awardsText = useTranslation("awards");
  const blogText = useTranslation("blog");
  const privacyPolicyText = useTranslation("privacyPolicy");
  const siteMapText = useTranslation("siteMap");

  useEffect(() => {
    if (isTablet) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [isTablet]);

  const handleClickOnTitle = () => setIsActive((prev) => !prev);

  return (
    <div className={isActive ? styles.listItemWrapper + " " + styles.listItemWrapperActive : styles.listItemWrapper}>
      <h2 className={styles.listItemTitle} onClick={handleClickOnTitle}>
        {siteMapText}
        {isActive ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </h2>
      <ul className={styles.listWrapper}>
        <li>
          <StyledLink href={dailySudokuLink} className={styles.listLink}>
            {dailySudokuText}
          </StyledLink>
        </li>

        <li>
          <StyledLink href={awardsLink} className={styles.listLink}>
            {awardsText + ` (🏆${annualAwardsCount}/12)`}
          </StyledLink>
        </li>

        {isEn && (
          <li>
            <StyledLink href={blogPath} className={styles.listLink}>
              {blogText}
            </StyledLink>
          </li>
        )}

        <li>
          <StyledLink href={privacyPolicyLink} className={styles.listLink}>
            {privacyPolicyText}
          </StyledLink>
        </li>
      </ul>
    </div>
  );
};

export const Topics = () => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const isTablet = useMediaQuery(TABLET_MEDIA_QUERY);

  const howToPlayLink = useRoutes().HowToPlay;
  const deleteAccountLink = useRoutes().howDeleteAccount;
  const historySudokuLink = useRoutes().HistorySudoku;

  const topicsText = "Topics";
  const howToPlayText = useTranslation("howToPlay");
  const deleteAccountText = "Delete Account";
  const historyOfSudokuText = useTranslation("historySudoku");

  useEffect(() => {
    if (isTablet) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [isTablet]);

  const handleClickOnTitle = () => setIsActive((prev) => !prev);

  return (
    <div className={isActive ? styles.listItemWrapper + " " + styles.listItemWrapperActive : styles.listItemWrapper}>
      <h2 className={styles.listItemTitle} onClick={handleClickOnTitle}>
        {topicsText}
        {isActive ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </h2>
      <ul className={styles.listWrapper}>
        <li>
          <StyledLink href={howToPlayLink} className={styles.listLink}>
            {howToPlayText}
          </StyledLink>
        </li>

        <li>
          <StyledLink href={deleteAccountLink} className={styles.listLink}>
            {deleteAccountText}
          </StyledLink>
        </li>

        <li>
          <StyledLink href={historySudokuLink} className={styles.listLink}>
            {historyOfSudokuText}
          </StyledLink>
        </li>
      </ul>
    </div>
  );
};

export const OtherGames: React.FC = () => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const isTablet = useMediaQuery(TABLET_MEDIA_QUERY);

  const playMahjongLink = "https://mahjong-online.club/";

  const playMahjongText = useTranslation("playMahjong");
  const otherGameText = useTranslation("otherGame");

  useEffect(() => {
    if (isTablet) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [isTablet]);

  const handleClickOnTitle = () => setIsActive((prev) => !prev);
  return (
    <div className={isActive ? styles.listItemWrapper + " " + styles.listItemWrapperActive : styles.listItemWrapper}>
      <h2 className={styles.listItemTitle} onClick={handleClickOnTitle}>
        {otherGameText} {isActive ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </h2>
      <ul className={styles.listWrapper}>
        <li>
          <StyledLink
            href={playMahjongLink}
            className={styles.listLink}
            aria-label="Go to play Mahjong online"
            target="_blank"
            rel
          >
            {playMahjongText}
          </StyledLink>
        </li>
      </ul>
    </div>
  );
};
