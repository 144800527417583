type Props = {
  className?: string;
};

const RocketIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="23px"
    height="22px"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.14844 12.3286L3.95688 9.56146L8.73844 8.71411L8.26578 11.2166L1.80156 13.2035L1.38391 13.023L1.14844 12.3286Z"
      fill="#CA2C31"
    />
    <path
      d="M4.44262 10.2713L1.39355 13.0419L3.63652 14.1351L7.04137 12.3974V11.5776L7.73746 9.70068L4.44262 10.2713ZM11.6579 15.1508L10.185 15.7902L8.79449 18.7379C8.79449 18.7379 10.0182 21.4483 10.0732 21.4483C10.1282 21.4483 10.8243 21.5033 10.8243 21.5033L13.2993 18.7362L13.925 14.0096L11.6579 15.1508Z"
      fill="#A02422"
    />
    <path
      d="M10.2108 17.3319C10.2108 17.3319 11.0306 17.1652 11.615 16.8868C12.1994 16.6083 12.9212 16.1924 12.9212 16.1924L12.6566 18.5007L10.0698 21.4449C10.0698 21.4449 9.96844 21.3194 9.53875 20.3844C9.19672 19.6385 9.07812 19.3721 9.07812 19.3721L10.2108 17.3319Z"
      fill="#CA2C31"
    />
    <path
      d="M5.92822 13.8635C5.92822 13.8635 4.94166 13.781 3.86057 15.1474C2.874 16.3952 2.41166 18.4268 2.23291 18.9493C2.05416 19.4718 1.62619 19.5784 1.76885 19.8173C1.9115 20.0562 2.52854 20.0424 3.28994 19.9479C4.05135 19.8533 4.70447 19.6144 4.70447 19.6144C4.70447 19.6144 4.67182 19.6969 4.56182 19.8757C4.52229 19.9393 4.38479 20.0304 4.3951 20.1249C4.41916 20.3501 6.3476 20.3552 7.8876 18.7705C9.55135 17.0604 8.74354 15.6579 8.74354 15.6579L5.92822 13.8635Z"
      fill="#F7D74D"
    />
    <path
      d="M6.22498 14.6592C6.22498 14.6592 5.16107 14.7159 4.28107 16.0823C3.40107 17.4487 2.99201 19.0317 3.07623 19.1382C3.15873 19.2448 4.79842 18.6518 5.19201 18.7704C5.46185 18.8529 5.32263 19.1262 5.39482 19.1984C5.45498 19.2586 6.16654 19.36 7.3181 18.1121C8.47138 16.8643 8.1981 15.1112 8.1981 15.1112L6.22498 14.6592Z"
      fill="#FBF0B4"
    />
    <path
      d="M6.74727 12.7944C6.74727 12.7944 6.21274 13.2705 6.02196 13.5438C5.83118 13.8171 5.83118 13.8412 5.84321 13.9237C5.85524 14.0062 6.0529 14.9119 6.87618 15.706C7.88681 16.6805 8.78915 16.8112 8.91978 16.8112C9.0504 16.8112 9.91837 15.8366 9.91837 15.8366L6.74727 12.7944Z"
      fill="#858585"
    />
    <path
      d="M9.11133 15.6818C9.11133 15.6818 9.97758 16.2507 11.4299 15.7626C13.4151 15.0957 14.8709 13.6039 16.6738 11.7201C18.4132 9.90167 19.8724 7.64495 19.8724 7.64495L18.8498 4.21948L9.11133 15.6818Z"
      fill="#437687"
    />
    <path
      d="M12.1855 13.8067L12.4245 13.0642L13.0226 13.1484C13.0226 13.1484 13.478 13.3633 13.8132 13.5197C14.1484 13.6761 14.5798 13.7947 14.5798 13.7947L13.729 14.5131C13.729 14.5131 13.2649 14.3378 12.9263 14.19C12.5448 14.0233 12.1855 13.8067 12.1855 13.8067Z"
      fill="#3F545F"
    />
    <path
      d="M14.9924 2.77417C14.9924 2.77417 13.339 3.73323 10.9688 5.9762C8.83239 7.99745 7.24942 9.8262 6.86614 11.3817C6.54645 12.6845 6.71661 13.4751 7.44364 14.3293C8.21192 15.2334 9.11254 15.682 9.11254 15.682C9.11254 15.682 9.78973 15.6923 12.6154 13.5181C16.1405 10.8042 18.9644 6.25979 18.9644 6.25979L14.9924 2.77417Z"
      fill="#8DAFBF"
    />
    <path
      d="M18.4056 7.19125C18.4056 7.19125 16.967 6.57766 15.9409 5.14594C14.9217 3.72453 15.0025 2.76375 15.0025 2.76375C15.0025 2.76375 15.855 2.09516 17.7714 1.33031C19.0605 0.816407 20.8153 0.553438 21.3928 0.981407C21.9703 1.40938 20.9975 3.55609 20.9975 3.55609L19.2255 6.98672L18.4056 7.19125Z"
      fill="#D83F22"
    />
    <path
      d="M12.2169 13.8187C12.2169 13.8187 10.9742 13.1845 10.1836 12.2821C8.68828 10.5789 8.37891 8.70887 8.37891 8.70887L9.13 7.82715C9.13 7.82715 9.31734 9.81402 10.9209 11.5311C11.9659 12.65 13.0573 13.1621 13.0573 13.1621C13.0573 13.1621 12.8391 13.3461 12.6053 13.5248C12.4558 13.64 12.2169 13.8187 12.2169 13.8187Z"
      fill="#6896A5"
    />
    <path
      d="M19.8723 7.64496C19.8723 7.64496 20.6183 6.74605 21.3247 4.71965C22.3113 1.89402 21.4519 1.03809 21.4519 1.03809C21.4519 1.03809 21.1614 2.38215 20.1525 4.25043C19.343 5.74746 18.3633 7.1723 18.3633 7.1723C18.3633 7.1723 18.903 7.41465 19.1952 7.49887C19.563 7.60543 19.8723 7.64496 19.8723 7.64496Z"
      fill="#A02422"
    />
    <path
      d="M7.34216 11.9729C7.5656 12.1861 7.90419 11.9626 8.12591 11.4401C8.34935 10.9176 8.34591 10.6254 8.17403 10.5017C7.96091 10.347 7.60341 10.6856 7.447 10.9864C7.27513 11.3198 7.17372 11.8114 7.34216 11.9729ZM15.1006 3.45996C15.1006 3.45996 13.7926 4.40012 12.397 5.67887C11.1165 6.85277 10.2675 7.77059 10.1506 7.98371C10.0268 8.21231 10.0251 8.53543 10.1214 8.70043C10.2176 8.86543 10.4308 8.95137 10.625 8.80699C10.8192 8.6609 12.2028 7.13121 13.4523 6.03809C14.5953 5.03777 15.477 4.40356 15.477 4.40356C15.477 4.40356 15.8345 3.77105 15.7761 3.62668C15.7176 3.48059 15.1006 3.45996 15.1006 3.45996Z"
      fill="#B3E1EE"
    />
    <path
      d="M15.1006 3.45994C15.1006 3.45994 16.0665 2.71916 17.1184 2.19837C18.0964 1.71369 19.0692 1.30978 19.2445 1.66041C19.4112 1.99384 18.3215 2.44759 17.3625 3.05947C16.4034 3.67134 15.4804 4.40181 15.4804 4.40181C15.4804 4.40181 15.3395 4.144 15.2484 3.90509C15.1906 3.75967 15.1413 3.61104 15.1006 3.45994Z"
      fill="#ED6A65"
    />
    <path
      d="M15.8986 6.74617C14.9412 5.86445 13.6092 6.10163 12.9526 6.88538C12.2789 7.6932 12.3287 8.92726 13.1245 9.66976C13.8481 10.3435 15.1939 10.4965 16.0051 9.55117C16.727 8.71242 16.6823 7.46632 15.8986 6.74617Z"
      fill="#E1E1E1"
    />
    <path
      d="M13.4477 7.19631C12.8788 7.7549 12.9097 8.86178 13.5869 9.36709C14.1782 9.80881 15.0169 9.78646 15.5429 9.2485C16.0688 8.71053 16.0688 7.73256 15.5755 7.22725C15.0083 6.64803 14.0991 6.55693 13.4477 7.19631Z"
      fill="#3F545F"
    />
    <path
      d="M9.09419 13.2155C9.09419 13.2155 10.1581 12.1361 10.275 12.253C10.3918 12.3699 10.3764 13.0247 9.86762 13.7535C9.35716 14.4822 8.23481 15.7799 7.34622 16.6547C6.46966 17.5158 5.23387 18.5006 5.117 18.4835C5.02591 18.4697 4.65637 18.2858 4.50856 18.1071C4.36419 17.93 4.79559 17.093 4.96919 16.8163C5.1445 16.5396 9.09419 13.2155 9.09419 13.2155Z"
      fill="#A02524"
    />
    <path
      d="M4.1488 17.5054C4.13505 17.7529 4.51318 18.1139 4.51318 18.1139L10.2744 12.2547C10.2744 12.2547 10.0046 11.9504 9.21052 12.4437C8.40958 12.9421 7.70833 13.5403 6.67365 14.5423C5.23162 15.9414 4.16255 17.2579 4.1488 17.5054Z"
      fill="#CA2C31"
    />
    <path
      d="M3.77546 6.3508C3.58467 6.54158 3.57608 6.84751 3.78921 7.02283C3.97827 7.17923 4.28592 7.07783 4.40108 6.93689C4.51624 6.79595 4.50249 6.49001 4.34952 6.33876C4.19655 6.1858 3.89233 6.23564 3.77546 6.3508Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M7.55053 6.12724C7.40616 6.28708 7.45256 6.52427 7.58663 6.61193C7.72069 6.69958 7.92178 6.69099 8.01975 6.57068C8.10741 6.4624 8.11772 6.24583 7.98366 6.11177C7.86678 5.99662 7.64335 6.02412 7.55053 6.12724Z"
      fill="white"
    />
    <path
      d="M10.0476 2.04353C10.0476 2.04353 10.2573 1.44541 10.3811 1.43853C10.5065 1.43166 10.687 2.03666 10.687 2.03666C10.687 2.03666 11.3075 2.04353 11.3487 2.13463C11.402 2.2515 10.9517 2.64338 10.9517 2.64338C10.9517 2.64338 11.0978 3.22775 11.029 3.29822C10.9517 3.37557 10.4172 3.06791 10.4172 3.06791C10.4172 3.06791 9.86716 3.45119 9.74856 3.34635C9.64544 3.25525 9.86028 2.6365 9.86028 2.6365C9.86028 2.6365 9.34466 2.2601 9.37216 2.15525C9.41169 2.00744 10.0476 2.04353 10.0476 2.04353ZM17.1736 16.3848C17.3815 16.4999 17.6428 16.4346 17.739 16.2129C17.8267 16.0153 17.6651 15.7798 17.519 15.7162C17.3729 15.6526 17.1168 15.7368 17.024 15.9035C16.9329 16.0685 17.0481 16.316 17.1736 16.3848ZM20.1264 11.3162C20.0078 11.1323 19.7517 11.0721 19.585 11.2199C19.4234 11.3643 19.4629 11.5912 19.554 11.7063C19.6451 11.8215 19.8892 11.8645 20.037 11.7699C20.1848 11.6754 20.1986 11.4262 20.1264 11.3162Z"
      fill="white"
    />
  </svg>
);

export default RocketIcon;
