import { bg } from "./bg";
import { bs } from "./bs";
import { cs } from "./cs";
import { da } from "./da";
import { de } from "./de";
import { el } from "./el";
import { en } from "./en";
import { es } from "./es";
import { et } from "./et";
import { fi } from "./fi";
import { fr } from "./fr";
import { hr } from "./hr";
import { hu } from "./hu";
import { id } from "./id";
import { it } from "./it";
import { ja } from "./ja";
import { ka } from "./ka";
import { kk } from "./kk";
import { ko } from "./ko";
import { lt } from "./lt";
import { lv } from "./lv";
import { mk } from "./mk";
import { ms } from "./ms";
import { nl } from "./nl";
import { no } from "./no";
import { pl } from "./pl";
import { pt } from "./pt";
import { rom } from "./rom";
import { sk } from "./sk";
import { sl } from "./sl";
import { sq } from "./sq";
import { sr } from "./sr";
import { sv } from "./sv";
import { th } from "./th";
import { tr } from "./tr";
import { uk } from "./uk";
import { vi } from "./vi";
import { zh } from "./zh";

export {
  bg,
  bs,
  cs,
  da,
  de,
  el, // Greek
  en,
  es,
  et,
  fi,
  fr,
  hr,
  hu,
  id,
  it,
  ja,
  ka,
  kk,
  ko,
  lt,
  lv,
  mk,
  ms,
  nl,
  no,
  pl,
  pt,
  rom,
  sk,
  sl,
  sq, // Albanian
  sr, // Serbian
  sv,
  th,
  tr,
  uk,
  vi,
  zh,
};

export const locale = (languageCode: string): any => {
  switch (languageCode) {
    case "bg":
      return bg;
    case "bs":
      return bs;
    case "cs":
      return cs;
    case "da":
      return da;
    case "de":
      return de;
    case "el":
      return el;
    case "es":
      return es;
    case "et":
      return et;
    case "fi":
      return fi;
    case "fr":
      return fr;
    case "hr":
      return hr;
    case "hu":
      return hu;
    case "id":
      return id;
    case "it":
      return it;
    case "ja":
      return ja;
    case "ka":
      return ka;
    case "kk":
      return kk;
    case "ko":
      return ko;
    case "lt":
      return lt;
    case "lv":
      return lv;
    case "mk":
      return mk;
    case "ms":
      return ms;
    case "nl":
      return nl;
    case "no":
      return no;
    case "pl":
      return pl;
    case "pt":
      return pt;
    case "rom":
      return rom;
    case "sk":
      return sk;
    case "sl":
      return sl;
    case "sq":
      return sq;
    case "sr":
      return sr;
    case "sv":
      return sv;
    case "th":
      return th;
    case "tr":
      return tr;
    case "uk":
      return uk;
    case "vi":
      return vi;
    case "zh":
      return zh;
    default:
      return en;
  }
};
