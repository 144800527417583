import LEVEL_POINTS from "../constants/levelsPoints";
import { LocalStorage } from "../utils/LocalStorage";
import BaseManager from "./BaseManager";
import { getPoints } from "./pointsApis";

class PointsManager extends BaseManager {
  load() {
    return LocalStorage.getPoints();
  }

  update(points: number) {
    this.save(points);
  }

  remove() {
    LocalStorage.setPoints(0);
  }

  calculateLocalPoint(level: string, notes: boolean, hints: number) {
    if (hints >= 10) {
      this.save(0);
      return;
    }

    const basePoint = LEVEL_POINTS[level];
    if (!basePoint) {
      return;
    }

    const usedNotesFactor = notes ? 2 : 1;
    const pointsWithFactor = basePoint / usedNotesFactor;
    const points = pointsWithFactor - pointsWithFactor * (hints / 10);

    this.save(points);
  }

  calculatePoints(level: string, notes: boolean, hints: number): number {
    if (hints >= 10) {
      return 0;
    }

    const basePoint = LEVEL_POINTS[level];
    if (!basePoint) {
      return 0;
    }

    const usedNotesFactor = notes ? 2 : 1;
    const pointsWithFactor = basePoint / usedNotesFactor;
    return pointsWithFactor - pointsWithFactor * (hints / 10);
  }

  async getPoints(date: string) {
    if (!this.getUserId()) {
      this.load();
      return;
    }

    const response = await getPoints();
    if (response.erorr) {
      return this.save(0);
    }

    this.save(response.points);
  }

  private save(points: number) {
    if (this.getUserId()) {
      LocalStorage.setPoints(points);
    } else {
      const savedPoints = this.load();
      const currentPoints = savedPoints + points;
      LocalStorage.setPoints(currentPoints);
    }
  }
}

const pointsManager = new PointsManager();
export default pointsManager;
