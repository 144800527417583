export const fr: any = {
  ISOcode: "fr",
  countryName: "France",
  language: "Français",
  dailyChallenges: "Défis quotidiens",
  dailyChallenge: "DÉFI QUOTIDIEN",
  level: "Niveau: ",
  levels: "Les niveaux",
  blog: "Blog",
  privacyPolicy: "Politique de confidentialité",
  howToPlay: "Comment jouer",
  newGame: "nouveau jeu",
  undo: "Annuler",
  notes: "Remarques",
  hints: "Conseils",
  erase: "Effacer",
  nowDownload:
    "TÉLÉCHARGER MAINTENANT TÉLÉCHARGER MAINTENANT TÉLÉCHARGER MAINTENANT TÉLÉCHARGER MAINTENANT TÉLÉCHARGER MAINTENANT TÉLÉCHARGER MAINTENANT TÉLÉCHARGER MAINTENANT ",
  playBest: "Jouez mieux",
  online: "En ligne",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Vous voyez, nous vous avons dit que les règles sont faciles!",
  dontWasteYourTime: "Maintenant, ne perdez pas votre temps à douter, ",
  appIsSudokuPro: "la meilleure application de sudoku est Sudoku Pro, et vous devez l'essayer!",
  playOnline: "jouer en ligne sudoku règles",
  points: "points",
  yes: "Oui",
  no: "Non",
  playMahjong: "Jouer au Mahjong en ligne",
  siteMap: "Plan du site",
  otherGame: "D'autres jeux",
  historySudoku: "Histoire du Sudoku",
  rulesSudoku: "Règles du Sudoku",
  tipsSudoku: "Conseils pour le Sudoku",
  sudokuTipsAndTrick: "Trucs et astuces du Sudoku - De Noob à SudokuPro",
  goBack: "Retourner",
  readBlog: "Lire le blog",
  playSudokuOnline: "Jouer au Sudoku en ligne",
  playNow: "JOUER MAINTENANT",
  cookieText:
    "Notre site Web utilise des cookies pour personnaliser le contenu et analyser notre trafic, ce qui peut également donner lieu à un profilage.",
  cancel: "Annuler",
  oops: "Oups...",
  errorPageNotFound: "Erreur 404. Page non trouvée",
  sorryGoBackHome:
    "Nous sommes désolés, la page que vous avez demandée n'a pas pu être trouvée. Veuillez retourner à la page d'accueil.",
  backToHomepage: "RETOUR À LA PAGE D'ACCUEIL",
  similarArticles: "Articles similaires",
  startYourBrainTraining:
    "Commencez dès maintenant à entraîner votre cerveau, téléchargez le jeu en ligne gratuit Sudoku Pro, et ne vous plaignez pas de ne pouvoir vous arrêter de résoudre des problèmes.",
  //////// Meta and Description /////////
  metaPageTitle: "Comment jouer au Sudoku - De Noob à SudokuPro",
  metaPageDescription:
    "Lisez le blog SudokuPro pour apprendre tous les trucs et astuces pour jouer au sudoku jeu gratuit, que vous soyez un débutant absolu ou un joueur avancé. Obtenez une explication facile des stratégies des pros pour jouer plus efficacement et améliorer la santé de votre cerveau.",

  metaBlogTitle: "Trucs et astuces du Sudoku - De Noob à SudokuPro",
  metaBlogDescription:
    "Lisez notre blog pour apprendre toutes les astuces du Sudoku pour les débutants et les joueurs experts. Obtenez une explication facile des stratégies et des astuces des pros pour résoudre le Sudoku gratuit.",

  /////////////Basic rules/////////////
  gameRules: {
    subtitle: "Sudoku simplifié",
    title: "Règles de base du Sudoku",
    titleText:
      "Si vous êtes un nouveau venu dans le monde du Sudoku très difficiles gratuit, vous n'avez pas à en avoir honte. Ce jeu est très logique et facile à comprendre. Il y a 3 règles de base et selon le type de jeu que vous choisissez, il peut y avoir des modifications, cependant, les règles de base changent rarement.",
    goal: "But:",
    goalText:
      "Remplir une grille 9×9, de sorte que chaque colonne, ligne et section 3×3 contienne les chiffres de 1 à 9. Traditionnellement, il n'y a pas de limite de temps, mais certains jeux de Sudoku pour le cerveau ajoutent des limites de temps qui poussent à réfléchir plus rapidement.",
    ruleTitle: "Comment jouer au Sudoku?",
    ruleText:
      "Lorsque vous commencez, certaines cases de la grille 9×9 comportent déjà des chiffres. Votre tâche consiste à utiliser vos capacités intellectuelles pour remplir les cases manquantes et compléter la grille.",
    fail: "Vous échouez si:",
    list: "Toute ligne comporte deux mêmes chiffres <list> Toute colonne comporte deux mêmes chiffres <list> Toute grille 3×3 comporte deux mêmes chiffres.",
    remember: "Rappelez-vous:",
    rememberText:
      "Chaque chiffre de 1 à 9 ne peut être utilisé qu'une seule fois dans une ligne, une colonne ou une grille 3×3.",
  },

  /////////// Months ////////
  January: "Janvier",
  February: "Février",
  March: "Mars",
  April: "Avril",
  May: "Mai",
  June: "Juin",
  July: "Juillet",
  August: "Août",
  September: "Septembre",
  October: "Octobre",
  November: "Novembre",
  December: "Décembre",

  /////Win poup////
  youWin: "Vous avez gagné jouer sudoku!",
  youWinText: "Téléchargez l'application pour jouer à tout moment et sans aucune restriction",
  followUs: "Suivez-nous sur les médias sociaux:",
  winLevel: "Niveau",
  startNewGame: "Commencer un nouveau jeu",
  time: "Temps",
  bestResult: "Meilleur résultat",
  EASY: "Facile",
  MEDIUM: "Moyen",
  HARD: "Difficile",
  EXPERT: "Expert",
  EXTREME: "Extrême",
  /////////NoGame RC//////
  sorry: "désoléе",
  gameNotFount: "jeu non trouvé",
  chooseChallenge: "Choisissez un défi ici:",

  // Login Page
  loginMetaTitle: "Connectez-vous à Sudoku Pro",
  loginMetaDescription:
    "Connectez-vous pour sauvegarder votre progression dans le jeu et rivaliser avec d'autres joueurs",
  logIn: "Se connecter",
  welcomeBack: "Content de te revoir",
  continueWithGoogle: "Continuer avec Google",
  continueWithFacebook: "Continuer avec Facebook",
  authorizationFailedMessage: "L'autorisation a échoué, réessayez plus tard",

  // Registration Page
  registrationMetaTitle: "Terminer l'inscription - Sudoku Pro",
  registrationMetaDescription:
    "Il ne reste plus qu'une petite étape pour finaliser l'inscription et profiter au maximum du jeu Sudoku",
  createAnAccount: "Créer un compte",
  nickName: "Surnom",
  country: "Pays",
  signUp: "S'inscrire",
  createYourNickName: "Créez votre pseudo",
  registrationFailedMessage: "L'inscription a échoué, réessayez plus tard",

  // Logout Dialog
  logOutMessage: "Êtes-vous sûr de vouloir vous déconnecter? Votre progression ne sera pas enregistrée",
  logOut: "Se déconnecter",

  // Awards Page
  awardsMetaTitle: "Vos récompenses - Sudoku Pro",
  awardsMetaDescription: "Relevez les défis quotidiens de Sudoku Pro et récupérez toutes les récompenses de l'année !",
  awards: "Prix",
  yourAwards: "Vos récompenses",
  yourAwardsDescription: "Relevez des défis quotidiens et obtenez des récompenses à la fin de chaque mois !",
  awardsDontMissASingleDay: "Ne manquez pas un seul jour – jouez au sudoku !",

  // Monthly Award Dialog
  awardDialogTitle: "Récompense mensuelle reçue",
  awardDialogDescription:
    "Vous avez relevé tous les défis quotidiens pendant un mois et reçu une récompense ! Toutes nos félicitations!",
  awardShare: "Partager",

  // Authorization banner
  authorizationBannerMessage: "Connectez-vous pour enregistrer votre progression et vos récompenses 🏆",

  // You Won Dialog
  levelsLeftMessage: "Niveaux restants pour obtenir la récompense : %s",

  // Ratings Block
  topCountriesToday: "TOP 20 des pays aujourd'hui",
  topUsersToday: "TOP 100 des utilisateurs aujourd'hui",
  numberOfUsers: "Nombre d'utilisateurs",
  updatedEvery24hours: "Mis à jour toutes les 24 heures",
  experiencePoints: "Points d'experience",
  showFullList: "Afficher la liste complète",
  showShortList: "Afficher une courte liste",
};
