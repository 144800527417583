export const lt: any = {
  ISOcode: "lt",
  countryName: "Lithuania",
  language: "Lietuvių",
  dailyChallenges: "Kasdieniai iššūkiai",
  dailyChallenge: "KASDIENIS IŠŠŪKIS",
  level: "Lygis: ",
  levels: "Lygiai",
  blog: "Tinklaraštis",
  privacyPolicy: "Privatumo politika",
  howToPlay: "Kaip žaisti",
  newGame: "NAUJAS ŽAIDIMAS",
  undo: "Atšaukti",
  notes: "Pastabos",
  hints: "Patarimai",
  erase: "Ištrinti",
  nowDownload:
    "DABAR ATSISIŲSTI DABAR ATSISIŲSTI DABAR ATSISIŲSTI DABAR ATSISIŲSTI DABAR ATSISIŲSTI DABAR ATSISIŲSTI DABAR ATSISIŲSTI DABAR ATSISIŲSTI DABAR ATSISIŲSTI DABAR ATSISIŲSTI DABAR ATSISIŲSTI ",
  playBest: "Žaisti geriausiai",
  online: "Prisijungęs",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Matote, sakėme, kad taisyklės yra paprastos!",
  dontWasteYourTime: "Dabar nešvaistykite laiko abejonėms,",
  appIsSudokuPro: " geriausia sudoku programėlė yra 'Sudoku Pro', todėl privalote ją išbandyti!",
  playOnline: "žaisti internete",
  points: "taškų",
  yes: "Taip",
  no: "Nr",
  playMahjong: "Žaisti Mahjong Online",
  siteMap: "Svetainės žemėlapis",
  otherGame: "Kiti žaidimai",
  historySudoku: "Sudoku istorija",
  rulesSudoku: "Sudoku taisyklės",
  tipsSudoku: "Sudoku patarimai",
  sudokuTipsAndTrick: "Sudoku patarimai ir gudrybės - nuo noob iki SudokuPro",
  goBack: "Grįžti atgal",
  readBlog: "Skaityti tinklaraštį",
  playSudokuOnline: "Žaiskite Sudoku internete",
  playNow: "ŽAISTI DABAR",
  cookieText:
    "Mūsų svetainėje naudojami slapukai, kad būtų galima suasmeninti turinį ir analizuoti srautą, o tai taip pat gali lemti profiliavimą.",
  cancel: "Atšaukti",
  oops: "Ooops...",
  errorPageNotFound: "404 klaida. Puslapis nerastas",
  sorryGoBackHome: "Apgailestaujame, bet prašomo puslapio nepavyko rasti. Grįžkite į pagrindinį puslapį.",
  backToHomepage: "GRĮŽTI Į PAGRINDINĮ PUSLAPĮ",
  similarArticles: "Panašūs straipsniai",
  startYourBrainTraining:
    "Pradėk smegenų treniruotę dabar, atsisiųsk nemokamą internetinį žaidimą Sudoku Pro ir nesiskųsk, kad negali nustoti spręsti.",
  //////// Meta and Description /////////
  metaPageTitle: "Kaip žaisti Sudoku - nuo 'Noob' iki 'SudokuPro'",
  metaPageDescription:
    "Skaitykite 'SudokuPro' tinklaraštį ir sužinokite visus patarimus ir gudrybes, kaip žaisti Sudoku, nepriklausomai nuo to, ar esate pradedantysis, ar pažengęs žaidėjas. Gaukite paprastą profesionalų strategijų paaiškinimą, kaip efektyviau žaisti ir sustiprinti savo smegenų sveikatą.",
  metaBlogTitle: "Sudoku patarimai ir gudrybės - nuo noob iki SudokuPro",
  metaBlogDescription:
    "Skaitykite mūsų tinklaraštį ir sužinokite visas Sudoku gudrybes pradedantiesiems ir patyrusiems žaidėjams. Gaukite paprastą profesionalų strategijų ir patarimų, kaip spręsti Sudoku, paaiškinimą",

  ///////////Basic rules/////////////
  gameRules: {
    subtitle: "Sudoku tapo paprasta",
    title: "Pagrindinės Sudoku taisyklės",
    titleText:
      "Jei esate Sudoku pasaulio naujokas, nėra ko gėdytis. Šis žaidimas yra labai logiškas ir lengvai suprantamas. Yra 3 pagrindinės taisyklės ir, priklausomai nuo pasirinktos rūšies, gali būti pakeitimų, tačiau pagrindinių taisyklių rinkinys keičiasi retai.",
    goal: "Tikslas:",
    goalText:
      "Užpildykite 9×9 tinklelį taip, kad kiekviename stulpelyje, eilutėje ir 3×3 dalyje būtų skaitmenys nuo 1 iki 9. Tradiciškai laiko limito nėra, tačiau kai kuriuose Sudoku žaidimuose smegenims pridedami laiko apribojimai, kurie verčia mąstyti greičiau.",
    ruleTitle: "Kaip žaisti Sudoku?",
    ruleText:
      "Kai pradėsite, kai kuriuose 9×9 tinklelio kvadratuose jau bus skaitmenys. Jūsų užduotis - pasitelkus smegenų galią užpildyti trūkstamas vietas ir užbaigti tinklelį.",
    fail: "Jums nepavyks, jei:",
    list: "Bet kurioje eilutėje yra du vienodi skaičiai <list> Bet kuriame stulpelyje yra du vienodi skaičiai <list> Bet kuriame 3×3 tinklelyje yra du vienodi skaičiai",
    remember: "Atminkite:",
    rememberText:
      "Kiekvienas skaitmuo nuo 1 iki 9 eilutėje, stulpelyje arba 3×3 tinklelyje gali būti panaudotas tik vieną kartą.",
  },
  /////////// Months ////////
  January: "Sausis",
  February: "Vasaris",
  March: "Kovas",
  April: "Balandis",
  May: "Gegužė",
  June: "Birželis",
  July: "Liepa",
  August: "Rugpjūtis",
  September: "Rugsėjis",
  October: "Spalis",
  November: "Lapkritis",
  December: "Gruodis",
  /////Win poup////
  youWin: "Jūs laimėjote!",
  youWinText: "Atsisiųskite programą, kad galėtumėte žaisti bet kada ir be jokių apribojimų",
  followUs: "Sekite mus socialinėje žiniasklaidoje:",
  winLevel: "Lygis",
  startNewGame: "Pradėti naują žaidimą",
  time: "Laikas",
  bestResult: "Geriausias rezultatas",
  EASY: "Lengva",
  MEDIUM: "Vidutinis",
  HARD: "Sunku",
  EXPERT: "Ekspertas",
  EXTREME: "Ekstremalus",
  ///////NoGame RC//////
  sorry: "Atsiprašome",
  gameNotFount: "žaidimas nerastas",
  chooseChallenge: "Pasirinkite iššūkį čia:",

  // Login Page
  loginMetaTitle: "Prisijunkite prie Sudoku Pro",
  loginMetaDescription: "Prisijunkite, kad išsaugotumėte žaidimo eigą ir konkuruokite su kitais žaidėjais",
  logIn: "Prisijungti",
  welcomeBack: "Sveikas sugrįžęs",
  continueWithGoogle: "Tęskite su Google",
  continueWithFacebook: "Tęsti su Facebook",
  authorizationFailedMessage: "Autorizacija nepavyko, bandykite dar kartą vėliau",

  // Registration Page
  registrationMetaTitle: "Baigti registraciją - Sudoku Pro",
  registrationMetaDescription:
    "Liko tik vienas mažas žingsnis, kad užbaigtumėte registraciją ir išnaudotumėte visas Sudoku žaidimo galimybes",
  createAnAccount: "Sukurti paskyrą",
  nickName: "Slapyvardis",
  country: "Šalis",
  signUp: "Registruotis",
  createYourNickName: "Sukurkite savo slapyvardį",
  registrationFailedMessage: "Registracija nepavyko, bandykite dar kartą vėliau",

  // Logout Dialog
  logOutMessage: "Ar tikrai norite atsijungti? Jūsų pažanga nebus išsaugota",
  logOut: "Atsijungti",

  // Awards Page
  awardsMetaTitle: "Jūsų apdovanojimai – Sudoku Pro",
  awardsMetaDescription: "Atlikite kasdienius „Sudoku Pro“ iššūkius ir gaukite visus metų apdovanojimus!",
  awards: "Apdovanojimai",
  yourAwards: "Jūsų apdovanojimai",
  yourAwardsDescription: "Atlikite kasdienius iššūkius ir gaukite apdovanojimų kiekvieno mėnesio pabaigoje!",
  awardsDontMissASingleDay: "Nepraleiskite nė vienos dienos – žaiskite sudoku!",

  // Monthly Award Dialog
  awardDialogTitle: "Gautas mėnesinis apdovanojimas",
  awardDialogDescription: "Įveikėte visus mėnesio iššūkius ir gavote apdovanojimą! Sveikiname!",
  awardShare: "Dalintis",

  // Authorization banner
  authorizationBannerMessage: "Prisijunkite, kad išsaugotumėte savo pažangą ir apdovanojimus 🏆",

  // You Won Dialog
  levelsLeftMessage: "Liko lygiai, kad gautumėte atlygį: %s",

  // Ratings Block
  topCountriesToday: "TOP 20 šalių šiandien",
  topUsersToday: "TOP 100 vartotojų šiandien",
  numberOfUsers: "Vartotojų skaičius",
  updatedEvery24hours: "Atnaujinama kas 24 valandas",
  experiencePoints: "Patirties taškai",
  showFullList: "Rodyti visą sąrašą",
  showShortList: "Rodyti trumpą sąrašą",
};
