import { API_GAME } from "./api";
import { API_ROUTES_GAME } from "./apiRoutes";

export const deleteAccout = async (reqData: { userName: string }) => {
  try {
    const { data } = await API_GAME.post(API_ROUTES_GAME.USER.DELETE_ACCOUNT(), reqData);
    return data;
  } catch (error: any) {
    return { error: error.message };
  }
};
