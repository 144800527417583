import React, { useCallback, useEffect } from "react";
import styles from "./AuthInfoModal.module.scss";
import useTranslation from "../../../helpers/translationTexts/useTranslation";

type AuthInfoModalProps = {
  isOpen: boolean;
  title: string;
  description: string;
  btnNameCancel: string;
  onLogoutClicked?: () => void;
  onCloseClicked: () => void;
};

const AuthInfoModal: React.FC<AuthInfoModalProps> = ({
  isOpen,
  onLogoutClicked,
  onCloseClicked,
  title,
  description,
  btnNameCancel,
}) => {
  const handleCloseModal = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.target === e.currentTarget) {
        onCloseClicked();
      }
    },
    [onCloseClicked]
  );

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        onCloseClicked();
      }
    };
    window.addEventListener("keydown", handleEscKeyPress);

    return () => {
      window.removeEventListener("keydown", handleEscKeyPress);
    };
  }, [isOpen, onCloseClicked]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);
  const logOutText = useTranslation("logOut");

  return (
    isOpen && (
      <div className={styles.overlay} onClick={handleCloseModal}>
        <div className={styles.modal}>
          <div className={styles.wrapper}>
            <h2 className={styles.modalTitle}>{title}</h2>
            <p className={styles.modalDescrption}>{description}</p>
            <div className={styles.btnWrapper}>
              {title === logOutText ? (
                <>
                  <button type="button" onClick={onLogoutClicked} className={styles.logOutBtn}>
                    {logOutText}
                  </button>
                  <button className={styles.cancelBtn} onClick={onCloseClicked} type="button">
                    {btnNameCancel}
                  </button>
                </>
              ) : (
                <button className={styles.cancelBtnOK} onClick={onCloseClicked} type="button">
                  {btnNameCancel}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};
export default AuthInfoModal;
