import { ReactNode } from "react";
import styles from "./Container.module.scss";

type Props = {
  fullHeight?: boolean;
  children: ReactNode;
};

const Container: React.FC<Props> = ({ children, fullHeight = false }) => {
  return (
    <div className={styles.container} style={{ height: fullHeight ? "100%" : "auto" }}>
      {children}
    </div>
  );
};

export default Container;
