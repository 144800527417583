import { ReactNode } from "react";
import styles from "./SelectCountryModal.module.scss";
import { CrossIcon } from "../../../svg";

type Props = {
  children: ReactNode;
};

type OverlayProps = {
  children: ReactNode;
  handleCloseModal: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

type CloseProps = {
  onClose: () => void;
};

const SelectCountrylOverlay: React.FC<OverlayProps> = ({ children, handleCloseModal }) => (
  <div onClick={(e) => handleCloseModal(e)} className={styles.modalOverlay}>
    {children}
  </div>
);

const CloseModalButton: React.FC<CloseProps> = ({ onClose }) => (
  <button onClick={() => onClose()} className={styles.closeModalButton} type="button">
    <CrossIcon className={styles.closeModalIcon} />
  </button>
);

const SelectCountrylContent: React.FC<Props> = ({ children }) => <div className={styles.modal}>{children}</div>;

export { SelectCountrylOverlay, SelectCountrylContent, CloseModalButton };
