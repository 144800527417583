import { CacheProvider, EmotionCache, Global } from "@emotion/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import type { AppProps } from "next/app";
import React from "react";
import { useRouter } from "next/router";

import { MainWrapper, Cover, MainWrapperPost } from "@/components/common/styles";
import AppContent from "@/components/AppContent";

import createEmotionCache from "@/utils/createEmotionCache";
import theme from "@/constants/theme";
import { en as locale } from "@/helpers/translationTexts";

import { globalStyles } from "../../styles/styles";
import BaseHeader from "@/components/BaseHeader";
import { SessionProvider } from "@/context/SessionProvider";

type Props = AppProps & {
  emotionCache?: EmotionCache;
};

const MyApp: React.FC<Props> = ({ Component, pageProps, emotionCache }) => {
  const AppComponent = Component as React.FC;

  const router = useRouter();
  const postPage = router.pathname === "/blog/[url]";

  return (
    <>
      <BaseHeader
        title={locale.metaTitle}
        description={locale.metaDescription}
        keywords="play sudoku online, playing sudoku, daily sudoku puzzle, sudoku play free, sudoku free puzzles, free sudoku, sudoku mobile app, web sudoku"
      />
      <CssBaseline>
        <SessionProvider>
          <CacheProvider value={emotionCache ?? createEmotionCache()}>
            <Global styles={globalStyles} />
            <ThemeProvider theme={theme}>
              {!postPage ? (
                <MainWrapper>
                  <AppContent>
                    <AppComponent {...pageProps} />
                  </AppContent>
                </MainWrapper>
              ) : (
                <MainWrapperPost>
                  <AppContent>
                    <AppComponent {...pageProps} />
                  </AppContent>
                </MainWrapperPost>
              )}
            </ThemeProvider>
          </CacheProvider>
        </SessionProvider>
      </CssBaseline>
    </>
  );
};

export default MyApp;
