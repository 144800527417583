export const uk: any = {
  ISOcode: "uk",
  countryName: "Ukraine",
  language: "Українська",
  blog: "Блог",
  dailyChallenges: "Щоденне випробування",
  dailyChallenge: "Щоденне випробування",
  readBlog: "Читати блог",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Sudoku Pro — гра-головоломка",
  sudokuTipsAndTrick: "Судоку онлайн - поради та підказки, або як стати професіоналом",
  oops: "Оой...",
  errorPageNotFound: "404 помилка. Сторінка не знайдена",
  sorryGoBackHome:
    "На жаль, сторінку, яку ви запитували, не вдалося знайти. Будь ласка, поверніться на головну сторінку.",
  backToHomepage: "НАЗАД НА ГОЛОВНУ",
  cookieText:
    "Продовжуючи переглядати сайт ви підтверджуєте, що ознайомилися з Політикою конфіденційності сайту та погоджуєтеся на використання файлів cookies.",
  ok: "ОК",
  privacyPolicy: "Політика конфіденційності",
  howToPlay: "Як грати",
  undo: "Скасувати",
  notes: "Нотатки",
  hints: "Підказки",
  erase: "Стерти",
  newGame: "Нова Гра",
  level: "Рівень: ",
  levels: "Рівні",
  similarArticles: "Схожі статті",
  playSudokuOnline: "Грати судоку онлайн",
  startYourBrainTraining:
    "Почніть розвивати свій мозок просто зараз! Завантажте безкоштовну онлайн-гру Sudoku Pro і не скаржтеся, що не можете перестати розв’язувати головоломки судоку.",
  nowDownload: "ЗАВАНТАЖ ЗАРАЗ ЗАВАНТАЖ ЗАРАЗ ЗАВАНТАЖ ЗАРАЗ ЗАВАНТАЖ ЗАРАЗ ЗАВАНТАЖ ЗАРАЗ ЗАВАНТАЖ ЗАРАЗ ",
  playBest: "Грайте найкраще",
  online: "Онлайн",
  playSudoku: "Судоку!",
  seeWeToldYou: "Бачите, а ми ж казали, що правила прості!",
  dontWasteYourTime: "Тепер не витрачайте час на сумніви, найкращий застосунок",
  appIsSudokuPro: "для гри в судоку — Sudoku Pro, і ви повинні його спробувати!",
  goBack: "Повернутись назад",
  facebook: "Facebook",
  twitter: "Twitter",
  telegram: "Telegram",
  points: "балів",
  yes: "Так",
  no: "Ні",
  playMahjong: "Грати в маджонг онлайн",
  siteMap: "Навігація сайту",
  otherGame: "Інші ігри",
  historySudoku: "Історія судоку",
  rulesSudoku: "Правила судоку",
  tipsSudoku: "Поради для судоку",

  /////Win poup////
  youWin: "Перемога!",
  youWinText: "Завантажте додаток, щоб грати у будь-який час без обмежень",
  followUs: "Слідкуйте за нами в соціальних мережах:",
  winLevel: "Рівень",
  startNewGame: "Почати нову гру",
  time: "Час",
  bestResult: "Найкращий результат",
  ////////////////////////
  playOnline: "грати онлайн",
  cancel: "Скасувати",
  //////// Meta and Description /////////
  metaPageTitle: "Як грати в Судоку — від початківця до Судоку Про",
  metaPageDescription:
    "Читайте блог Sudoku Pro, щоб отримати поради та підказки як грати в судоку, незалежно від вашого рівня зараз. Отримайте просте пояснення складних стратегій логічної гри, щоб грати ефективніше та покращувати свою пам’ять.",

  metaBlogTitle: "Судоку Онлайн - поради та підказки | Від початківця до Судоку Про",
  metaBlogDescription:
    "Читайте наш Sudoku Pro блог, щоб дізнатися про всі особливості судоку для початківців і досвідчених гравців. Отримайте просте пояснення професійних стратегій і поради щодо вирішення судоку онлайн.",

  /////////////Basic rules/////////////

  gameRules: {
    subtitle: "Судоку — це просто!",
    title: "Основні правила гри Судоку",
    titleText:
      "Якщо ви новачок і не знаєте як розгадувати судоку, немає чого соромитися. Ця гра дуже логічна і навчитися як грати в судоку дуже легко. Є 3 основні правила, і залежно від вибраного вами типу логічної гри можуть бути певні нюанси, проте основні правила гри судоку залишаються незмінними.",
    goal: "Ціль:",
    goalText:
      "Заповнити сітку 9×9 так, щоб кожен стовпець, рядок і секція 3×3 містили цифри від 1 до 9. Традиційно немає обмежень за часом, але деякі судоку онлайн додають обмеження за часом і наче підштовхують думати швидше.",
    ruleTitle: "ЯК ГРАТИ В СУДОКУ?",
    ruleText:
      "Коли ви почнете, деякі квадрати сітки 9×9 вже матимуть цифри. Ваше завдання полягає в тому, щоб заповнити пропущені місця, не залишивши жодного пустого місця.",
    fail: "ВИ ЗАЗНАЛИ НЕВДАЧІ, ЯКЩО:",
    list: "Будь-який рядок містить два однакових числа <list> Будь-який стовпець має два однакових числа <list> Будь-яка секція 3×3 має два однакові числа",
    remember: "ПАМ’ЯТАЙТЕ:",
    rememberText: "Кожну цифру від 1 до 9 можна використовувати лише один раз у рядку, стовпці чи секції 3×3",
  },

  playNow: "Грати зараз",
  /////////// Months ////////
  January: "Січень",
  February: "Лютий",
  March: "Березень",
  April: "Квітень",
  May: "Травень",
  June: "Червень",
  July: "Липень",
  August: "Серпень",
  September: "Вересень",
  October: "Жовтень",
  November: "Листопад",
  December: "Грудень",
  ///////// Game levels //////
  EASY: "Легкий",
  MEDIUM: "Середній",
  HARD: "Важкий",
  EXPERT: "Експерт",
  EXTREME: "Екстрім",
  /////////NoGame RC//////
  sorry: "вибачте",
  gameNotFount: "гра не знайдена",
  chooseChallenge: "Виберіть випробування:",

  // Login Page
  loginMetaTitle: "Увійдіть до Sudoku Pro",
  loginMetaDescription: "Увійдіть, щоб зберегти свій прогрес у грі та змагатися з іншими гравцями",
  logIn: "Увійти",
  welcomeBack: "З поверненням",
  continueWithGoogle: "Увійти через Google",
  continueWithFacebook: "Увійти через Facebook",
  authInfoModalTitleFailed: "Помилка авторизації",
  authorizationFailedMessage: "Помилка авторизації, спробуйте пізніше",

  // Registration Page
  registrationMetaTitle: "Завершити реєстрацію - Sudoku Pro",
  registrationMetaDescription:
    "Залишився лише один маленький крок, щоб завершити реєстрацію та отримати максимальну віддачу від гри судоку",
  createAnAccount: "Створити аккаунт",
  nickName: "Псевдонім",
  country: "Країна",
  signUp: "Зареєструватися",
  createYourNickName: "Створіть свій псевдонім",
  authInfoModalRegistrationTitleErrors: "Помилка реєстрації",
  registrationFailedMessage: "Помилка реєстрації, спробуйте пізніше",

  // Logout Dialog
  logOutMessage: "Ви впевнені, що бажаєте вийти? Ваш прогрес не буде збережено",
  logOut: "Вийти",

  // Awards Page
  awardsMetaTitle: "Ваші нагороди | Судоку Про",
  awardsMetaDescription: "Проходьте щоденні випробування у Sudoku Pro, та зберіть всі нагороди за рік!",
  awards: "Нагороди",
  yourAwards: "Ваші нагороди",
  yourAwardsDescription: "Виконуйте щоденні завдання та отримуйте нагороди в кінці кожного місяця!",
  awardsDontMissASingleDay: "Не пропустіть жодного дня - грайте в судоку!",
  getAwards: "Отримати нагороди!",

  // Monthly Award Dialog
  awardDialogTitle: "Отримана місячна премія",
  awardDialogDescription: "Ви виконали всі щоденні завдання протягом місяця та отримали нагороду! Щиро вітаємо!",
  awardShare: "Поділитися",

  // Authorization banner
  authorizationBannerMessage: "Увійдіть, щоб зберегти свій прогрес і нагороди 🏆",

  // You Won Dialog
  levelsLeftMessage: "Залишилося рівнів, щоб отримати нагороду: %s",

  // Ratings Block
  topCountriesToday: "ТОП 20 країн сьогодні",
  topUsersToday: "ТОП 100 користувачів сьогодні",
  numberOfUsers: "Кількість користувачів",
  updatedEvery24hours: "Оновлюється кожні 24 години",
  experiencePoints: "Очки досвіду",
  showFullList: "Показати повний список",
  showShortList: "Показати короткий список",

  // ControlHintsModalWarning
  controlHintsModalWarningMessage: "Ця функція недоступна в щоденних завданнях",

  // ConsecutiveDaysWarningModal
  consecutiveDaysModalText: "Кількість днів безперервної гри. Грайте щодня, щоб покращити це досягнення!",
  currentConsecutiveDaysModalText: "Поточний результат:",
  maxConsecutiveDaysModalText: "Найкращий результат:",
  consecutiveDaysModalTextLogIn: "Увійдіть, щоб зберегти свої досягнення",
};
