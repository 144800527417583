export const it: any = {
  ISOcode: "it",
  countryName: "Italy",
  language: "Italiano",
  dailyChallenges: "Sfide quotidiane",
  dailyChallenge: "SFIDA QUOTIDIANA",
  level: "Livello:",
  levels: "Livelli",
  blog: "Blog",
  privacyPolicy: "Informativa sulla privacy",
  howToPlay: "Come giocare",
  newGame: "nuovo gioco",
  undo: "Annullare",
  notes: "Note",
  hints: "Suggerimenti",
  erase: "Cancella",
  nowDownload:
    "SUDOKU GRATIS DA SCARICARE SUDOKU GRATIS DA SCARICARE SUDOKU GRATIS DA SCARICARE SUDOKU GRATIS DA SCARICARE SUDOKU GRATIS DA SCARICARE ",
  playBest: "Gioca al meglio",
  online: "in linea",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Vedi, ti abbiamo detto che le regole sono facili!",
  dontWasteYourTime: "Ora, non perdete tempo a dubitare:",
  appIsSudokuPro: " la migliore app per il sudoku è Sudoku Pro, e dovete provarla! Sudoku gratis da scaricare!",
  playOnline: "giocare online",
  points: "punti",
  yes: "SÌ",
  no: "NO",
  playMahjong: "Gioca a Mahjong online",
  siteMap: "Mappa del sito",
  otherGame: "Altri giochi",
  historySudoku: "Storia del Sudoku",
  rulesSudoku: "Regole per il Sudoku",
  tipsSudoku: "Suggerimenti per il Sudoku",

  sudokuTipsAndTrick: "Suggerimenti e trucchi per il Sudoku - Da Noob a SudokuPro",
  goBack: "Torna indietro",
  readBlog: "Leggi il blog",
  playSudokuOnline: "Gioca a Sudoku online",
  playNow: "GIOCA ORA",
  cookieText:
    "Il nostro sito web utilizza i cookie per personalizzare i contenuti e per analizzare il nostro traffico, il che potrebbe anche comportare una profilazione.",
  cancel: "Annulla",
  oops: "Ooops…",
  errorPageNotFound: "Errore 404. Pagina non trovata",
  sorryGoBackHome: "Siamo spiacenti, la pagina richiesta non è stata trovata. Si prega di tornare alla homepage.",
  backToHomepage: "TORNA ALLA HOMEPAGE",
  similarArticles: "Articoli simili",
  startYourBrainTraining:
    "Inizia subito ad allenare il tuo cervello, scarica il gioco online gratuito Sudoku Pro e non lamentarti se non riesci a smettere di risolverlo.",
  //////// Meta and Description /////////
  metaPageTitle: "Come giocare a Sudoku - Da noob a SudokuPro",
  metaPageDescription:
    "Leggete il blog di SudokuPro per imparare tutti i consigli e i trucchi per giocare a Sudoku, sia che siate principianti assoluti o giocatori esperti. Scopri le strategie dei professionisti per giocare in modo più efficace e migliorare la salute del tuo cervello.",

  metaBlogTitle: "Suggerimenti e trucchi per il Sudoku - Dal principiante al SudokuPro",
  metaBlogDescription:
    "Leggete il nostro blog per imparare tutti i trucchi del Sudoku per principianti e giocatori esperti. Scopri le strategie e i suggerimenti dei professionisti per risolvere il Sudoku.",

  /////////////Basic rules/////////////
  gameRules: {
    subtitle: "Il sudoku reso facile",
    title: "Regole di base del Sudoku",
    titleText:
      "Se siete dei neofiti del Sudoku, non c'è nulla di cui vergognarsi. Questo gioco è molto logico e facile da capire. Esistono 3 regole di base e, a seconda del tipo scelto, potrebbero esserci delle modifiche, tuttavia le regole di base sono raramente modificate.",
    goal: "Obiettivo:",
    goalText:
      "Riempire una griglia 9×9, in modo che ogni colonna, riga e sezione 3×3 contenga le cifre da 1 a 9. Tradizionalmente non c'è un limite di tempo, ma alcuni giochi di Sudoku per il cervello aggiungono limiti di tempo che spingono a pensare più velocemente.",
    ruleTitle: "Come si gioca a Sudoku?",
    ruleText:
      "Quando si inizia, alcune caselle della griglia 9×9 contengono già delle cifre. Il vostro compito è quello di usare il vostro cervello per riempire i punti mancanti e completare la griglia.",
    fail: "Si fallisce se:",
    list: "Qualsiasi riga ha due numeri uguali  <list> Qualsiasi colonna ha due numeri uguali  <list> Qualsiasi griglia 3×3 ha due numeri uguali",
    remember: "Ricordate:",
    rememberText:
      "Ogni cifra da 1 a 9 può essere usata una sola volta in una riga, in una colonna o in una griglia 3×3.",
  },

  /////////// Months ////////
  January: "Gennaio",
  February: "Febbraio",
  March: "Marzo",
  April: "Aprile",
  May: "Maggio",
  June: "Giugno",
  July: "luglio",
  August: "Agosto",
  September: "Settembre",
  October: "Ottobre",
  November: "Novembre",
  December: "Dicembre",

  /////Win poup////
  youWin: "Hai vinto!",
  youWinText: "Scarica l'app per giocare in qualsiasi momento e senza alcuna restrizione",
  followUs: "Seguiteci sui social media:",
  winLevel: "Livello",
  startNewGame: "Iniziare una nuova partita",
  time: "Tempo",
  bestResult: "Miglior risultato",
  EASY: "Facile",
  MEDIUM: "Medio",
  HARD: "Difficile",
  EXPERT: "Esperto",
  EXTREME: "Estremo",
  /////////NoGame RC//////
  sorry: "Mi dispiace",
  gameNotFount: "gioco non trovato",
  chooseChallenge: "Scegli una sfida qui:",

  // Login Page
  loginMetaTitle: "Accedi a Sudoku Pro",
  loginMetaDescription: "Accedi per salvare i tuoi progressi di gioco e competere con altri giocatori",
  logIn: "Login",
  welcomeBack: "Bentornato",
  continueWithGoogle: "Continua con Google",
  continueWithFacebook: "Continua con Facebook",

  // Registration Page
  registrationMetaTitle: "Completa la registrazione - Sudoku Pro",
  registrationMetaDescription:
    "ЗResta solo un piccolo passo per completare la registrazione e ottenere il massimo dal gioco Sudoku",
  createAnAccount: "Creare un account",
  nickName: "Soprannome",
  country: "Paese",
  signUp: "Iscrizione",
  createYourNickName: "Crea il tuo soprannome",

  //Logout Dialog
  logOutMessage: "Sei sicuro di voler uscire? I tuoi progressi non verranno salvati",
  logOut: "Disconnettersi",

  // Awards Page
  awardsMetaTitle: "I tuoi premi - Sudoku Pro",
  awardsMetaDescription: "Completa le sfide quotidiane in Sudoku Pro e raccogli tutti i premi dell'anno!",
  awards: "Premi",
  yourAwards: "I tuoi premi",
  yourAwardsDescription: "Completa le sfide quotidiane e ottieni ricompense alla fine di ogni mese!",
  awardsDontMissASingleDay: "Non perdere nemmeno un giorno: gioca a sudoku!",

  // Monthly Award Dialog
  awardDialogTitle: "Premio mensile ricevuto",
  awardDialogDescription:
    "Hai completato tutte le sfide quotidiane per un mese e hai ricevuto un premio! Congratulazioni!",
  awardShare: "Condividere",

  // Authorization banner
  authorizationBannerMessage: "Accedi per salvare i tuoi progressi e i premi 🏆",

  // You Won Dialog
  levelsLeftMessage: "🏆 Livelli rimasti per ottenere la ricompensa: %s",

  // Ratings Block
  topCountriesToday: "I 20 paesi TOP oggi",
  topUsersToday: "TOP 100 utenti oggi",
  numberOfUsers: "Numero di utenti",
  updatedEvery24hours: "Aggiornato ogni 24 ore",
  experiencePoints: "Punti esperienza",
  showFullList: "Mostra l'elenco completo",
  showShortList: "Mostra un breve elenco",
};
