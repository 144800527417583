import React, { useContext, createContext, useState } from "react";
import { USER_DATA, useLocalStorage } from "../utils/LocalStorage";
import { BestTime } from "../api/models/userAnnualAward";

type SessionContextProps = {
  userData: UserData | null;
  setUserData: React.Dispatch<React.SetStateAction<UserData | null>>;
  annualAwardsCount: number;
  setAnnualAwardsCount: React.Dispatch<React.SetStateAction<number>>;
  wonHistory: BestTime[] | null;
  setWonHistory: React.Dispatch<React.SetStateAction<BestTime[] | null>>;
  userPoints: number;
  setUserPoints: React.Dispatch<React.SetStateAction<number>>;
  selectMonth: number;
  setSelectMonth: React.Dispatch<React.SetStateAction<number>>;
  shockModeData: ShockModeData | null;
  setShockModeData: React.Dispatch<React.SetStateAction<ShockModeData | null>>;
  dailyProgress: string;
  setDailyProgress: React.Dispatch<React.SetStateAction<string>>;
};

const SessionContext = createContext<SessionContextProps>({
  userData: null,
  setUserData: () => undefined,
  annualAwardsCount: 0,
  setAnnualAwardsCount: () => undefined,
  wonHistory: null,
  setWonHistory: () => undefined,
  userPoints: 0,
  setUserPoints: () => undefined,
  selectMonth: 0,
  setSelectMonth: () => undefined,
  shockModeData: null,
  setShockModeData: () => undefined,
  dailyProgress: "",
  setDailyProgress: () => undefined,
});

type SessionProviderProps = {
  children: React.ReactElement;
};

const SessionProvider = ({ children }: SessionProviderProps) => {
  const [userData, setUserData] = useLocalStorage(USER_DATA);
  const [annualAwardsCount, setAnnualAwardsCount] = useState<number>(0);
  const [wonHistory, setWonHistory] = useState<BestTime[] | null>(null);
  const [userPoints, setUserPoints] = useState<number>(0);
  const [selectMonth, setSelectMonth] = useState<number>(0);
  const [shockModeData, setShockModeData] = useState<ShockModeData | null>(null);
  const [dailyProgress, setDailyProgress] = useState<string>("");

  return (
    <SessionContext.Provider
      value={{
        userData,
        setUserData,
        annualAwardsCount,
        setAnnualAwardsCount,
        wonHistory,
        setWonHistory,
        userPoints,
        setUserPoints,
        selectMonth,
        setSelectMonth,
        shockModeData,
        setShockModeData,
        dailyProgress,
        setDailyProgress,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

const useSession = (): SessionContextProps => {
  const context = useContext(SessionContext);

  if (context === undefined) {
    throw new Error("useSession must be used within an SessionProvider");
  }

  return context;
};

export { SessionProvider, useSession };
