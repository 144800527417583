import * as Props from "./types";
import styles from "./MobileMenu.module.scss";
import Link from "next/link";
import { SudokuProIcon } from "@/svg";

export const Wrapper: React.FC<Props.MobileMenuWrapperProps> = ({ children, isMenuOpen }) => (
  <div className={isMenuOpen ? styles.wrapper + " " + styles.mobileMenuActive : styles.wrapper}>{children}</div>
);

export const MenuItem: React.FC<Props.Base> = ({ children, onClick }) => (
  <div onClick={onClick} className={styles.menuItem}>
    {children}
  </div>
);

export const MenuItemStyledLink: React.FC<Props.MenuItemStyledLink> = ({ children, href, onClick }) => (
  <Link href={href} className={styles.menuItemStyledLink} passHref onClick={onClick}>
    {children}
  </Link>
);

export const MenuItemRewardsWrapper: React.FC<Props.Base> = ({ children }) => (
  <div className={styles.menuItemRewardsWrapper}>{children}</div>
);

export const MenuItemSpan: React.FC<Props.Base> = ({ children, onClick }) => (
  <span onClick={onClick} className={styles.menuItem}>
    {children}
  </span>
);

export const PlaySudokuLink: React.FC<Props.MobileMenuItemT> = ({ href, onClick, title }) => (
  <Link href={href} className={styles.playSudokuLink} onClick={onClick}>
    {title}
  </Link>
);

export const Logo: React.FC<Props.MenuItemStyledLink> = ({ href, onClick }) => {
  return (
    <Link href={href} className={styles.logo} onClick={onClick}>
      <SudokuProIcon width={250} />
    </Link>
  );
};

export const DailyChallengesLink: React.FC<Props.MobileMenuItemT> = ({ href, onClick, title }) => (
  <MenuItemStyledLink href={href} onClick={onClick}>
    {title}
  </MenuItemStyledLink>
);

export const BlogLink: React.FC<Props.BlogLinkT> = ({ href, onClick, title, isShow }) =>
  isShow && (
    <MenuItemStyledLink href={href} onClick={onClick}>
      {title}
    </MenuItemStyledLink>
  );
