import { Icon } from "./types";

const SudokuProIcon: React.FC<Icon> = ({ className, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190 25" className={className} style={{ width }}>
      <path d="M9.56613 25C7.72857 25 5.94506 24.7685 4.21558 24.3056C2.48611 23.8426 1.08092 23.2253 0 22.4537L2.10779 17.6257C3.12386 18.3091 4.30206 18.8602 5.6424 19.2791C6.98274 19.698 8.30146 19.9074 9.59856 19.9074C12.0631 19.9074 13.2953 19.2791 13.2953 18.0225C13.2953 17.3611 12.9386 16.8761 12.2252 16.5675C11.5334 16.2368 10.4093 15.8951 8.85273 15.5423C7.14488 15.1675 5.71806 14.7707 4.57229 14.3519C3.42651 13.9109 2.44288 13.2165 1.62138 12.2685C0.79988 11.3205 0.389131 10.0419 0.389131 8.43254C0.389131 7.02161 0.767453 5.75397 1.5241 4.62963C2.28074 3.48324 3.4049 2.57937 4.89656 1.91799C6.40985 1.25661 8.25822 0.925926 10.4417 0.925926C11.9333 0.925926 13.4034 1.10229 14.8518 1.45503C16.3003 1.78571 17.5757 2.28175 18.6783 2.94312L16.7002 7.80423C14.5384 6.61376 12.4414 6.01852 10.4093 6.01852C9.13377 6.01852 8.20418 6.21693 7.62048 6.61376C7.03678 6.98854 6.74494 7.48457 6.74494 8.10185C6.74494 8.71914 7.09083 9.1821 7.78262 9.49074C8.47441 9.79938 9.58775 10.119 11.1227 10.4497C12.8521 10.8245 14.2789 11.2324 15.4031 11.6733C16.5489 12.0922 17.5325 12.7756 18.354 13.7235C19.1971 14.6495 19.6187 15.9171 19.6187 17.5265C19.6187 18.9153 19.2404 20.172 18.4837 21.2963C17.7271 22.4206 16.5921 23.3245 15.0788 24.0079C13.5655 24.6693 11.728 25 9.56613 25Z" />
      <path d="M40.7764 6.61376V24.537H34.907V22.619C34.2585 23.3466 33.4802 23.8977 32.5722 24.2725C31.6643 24.6473 30.7022 24.8347 29.6862 24.8347C27.4162 24.8347 25.6003 24.1512 24.2383 22.7844C22.898 21.4175 22.2278 19.3673 22.2278 16.6336V6.61376H28.3891V15.6085C28.3891 16.9533 28.6377 17.9343 29.1349 18.5516C29.6321 19.1689 30.3563 19.4775 31.3076 19.4775C32.2804 19.4775 33.0695 19.1358 33.6748 18.4524C34.3017 17.7469 34.6152 16.6777 34.6152 15.2447V6.61376H40.7764Z" />
      <path d="M63.4233 0V24.537H57.5539V22.7513C56.4081 24.1402 54.6894 24.8347 52.3979 24.8347C50.7981 24.8347 49.3389 24.4599 48.0202 23.7103C46.7014 22.9387 45.653 21.8474 44.8747 20.4365C44.118 19.0256 43.7397 17.3942 43.7397 15.5423C43.7397 13.6905 44.118 12.0701 44.8747 10.6812C45.653 9.27028 46.7014 8.19004 48.0202 7.44048C49.3389 6.69092 50.7981 6.31614 52.3979 6.31614C54.4949 6.31614 56.1163 6.93342 57.262 8.16799V0H63.4233ZM53.695 19.8413C54.7543 19.8413 55.6298 19.4665 56.3216 18.7169C57.0134 17.9453 57.3593 16.8871 57.3593 15.5423C57.3593 14.2196 57.0134 13.1834 56.3216 12.4339C55.6515 11.6843 54.7759 11.3095 53.695 11.3095C52.6141 11.3095 51.7277 11.6843 51.0359 12.4339C50.3441 13.1834 49.9982 14.2196 49.9982 15.5423C49.9982 16.8871 50.3441 17.9453 51.0359 18.7169C51.7277 19.4665 52.6141 19.8413 53.695 19.8413Z" />
      <path d="M76.2076 24.8347C74.3268 24.8347 72.6297 24.4378 71.1165 23.6442C69.6032 22.8505 68.4142 21.7482 67.5494 20.3373C66.7063 18.9264 66.2847 17.328 66.2847 15.5423C66.2847 13.7787 66.7063 12.1914 67.5494 10.7804C68.4142 9.36949 69.5924 8.27822 71.084 7.50661C72.5973 6.71296 74.3052 6.31614 76.2076 6.31614C78.11 6.31614 79.8179 6.71296 81.3311 7.50661C82.8444 8.27822 84.0226 9.36949 84.8657 10.7804C85.7089 12.1693 86.1304 13.7566 86.1304 15.5423C86.1304 17.328 85.7089 18.9264 84.8657 20.3373C84.0226 21.7482 82.8444 22.8505 81.3311 23.6442C79.8179 24.4378 78.11 24.8347 76.2076 24.8347ZM76.2076 19.8413C77.2885 19.8413 78.1749 19.4665 78.8666 18.7169C79.5584 17.9453 79.9043 16.8871 79.9043 15.5423C79.9043 14.2196 79.5584 13.1834 78.8666 12.4339C78.1749 11.6843 77.2885 11.3095 76.2076 11.3095C75.1267 11.3095 74.2403 11.6843 73.5485 12.4339C72.8567 13.1834 72.5108 14.2196 72.5108 15.5423C72.5108 16.8871 72.8567 17.9453 73.5485 18.7169C74.2403 19.4665 75.1267 19.8413 76.2076 19.8413Z" />
      <path d="M96.9617 18.254L95.1458 20.1389V24.537H88.9846V0H95.1458V12.8638L101.437 6.61376H108.733L101.437 14.2857L109.349 24.537H101.891L96.9617 18.254Z" />
      <path d="M129.382 6.61376V24.537H123.513V22.619C122.864 23.3466 122.086 23.8977 121.178 24.2725C120.27 24.6473 119.308 24.8347 118.292 24.8347C116.022 24.8347 114.206 24.1512 112.844 22.7844C111.504 21.4175 110.834 19.3673 110.834 16.6336V6.61376H116.995V15.6085C116.995 16.9533 117.243 17.9343 117.741 18.5516C118.238 19.1689 118.962 19.4775 119.913 19.4775C120.886 19.4775 121.675 19.1358 122.281 18.4524C122.908 17.7469 123.221 16.6777 123.221 15.2447V6.61376H129.382Z" />
      <path d="M144.019 1.38889C146.073 1.38889 147.857 1.74162 149.37 2.44709C150.883 3.13051 152.051 4.12258 152.872 5.42328C153.694 6.70194 154.104 8.21208 154.104 9.9537C154.104 11.6953 153.694 13.2055 152.872 14.4841C152.051 15.7628 150.883 16.7549 149.37 17.4603C147.857 18.1437 146.073 18.4854 144.019 18.4854H140.063V24.537H133.643V1.38889H144.019ZM143.63 13.3267C144.949 13.3267 145.944 13.0401 146.614 12.4669C147.284 11.8717 147.619 11.034 147.619 9.9537C147.619 8.87346 147.284 8.03572 146.614 7.44048C145.944 6.84524 144.949 6.54762 143.63 6.54762H140.063V13.3267H143.63Z" />
      <path d="M162.971 8.76323C163.598 7.94753 164.408 7.34127 165.403 6.94445C166.419 6.52557 167.576 6.31614 168.873 6.31614V11.9709C168.311 11.9048 167.846 11.8717 167.478 11.8717C166.16 11.8717 165.122 12.2355 164.365 12.963C163.63 13.6905 163.263 14.8038 163.263 16.3029V24.537H157.101V6.61376H162.971V8.76323Z" />
      <path d="M180.077 24.8347C178.196 24.8347 176.499 24.4378 174.986 23.6442C173.473 22.8505 172.284 21.7482 171.419 20.3373C170.576 18.9264 170.154 17.328 170.154 15.5423C170.154 13.7787 170.576 12.1914 171.419 10.7804C172.284 9.36949 173.462 8.27822 174.954 7.50661C176.467 6.71296 178.175 6.31614 180.077 6.31614C181.98 6.31614 183.687 6.71296 185.201 7.50661C186.714 8.27822 187.892 9.36949 188.735 10.7804C189.578 12.1693 190 13.7566 190 15.5423C190 17.328 189.578 18.9264 188.735 20.3373C187.892 21.7482 186.714 22.8505 185.201 23.6442C183.687 24.4378 181.98 24.8347 180.077 24.8347ZM180.077 19.8413C181.158 19.8413 182.044 19.4665 182.736 18.7169C183.428 17.9453 183.774 16.8871 183.774 15.5423C183.774 14.2196 183.428 13.1834 182.736 12.4339C182.044 11.6843 181.158 11.3095 180.077 11.3095C178.996 11.3095 178.11 11.6843 177.418 12.4339C176.726 13.1834 176.38 14.2196 176.38 15.5423C176.38 16.8871 176.726 17.9453 177.418 18.7169C178.11 19.4665 178.996 19.8413 180.077 19.8413Z" />
    </svg>
  );
};

export default SudokuProIcon;
