import React from "react";

type Props = {
  className: string;
};

const PrevArrowIcon: React.FC<Props> = ({ className }) => (
  <svg className={className} viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.570541 9.5427L8.93004 17.5077C9.06952 17.6407 9.25484 17.7148 9.44754 17.7148C9.64025 17.7148 9.82556 17.6407 9.96504 17.5077L9.97404 17.4987C10.0419 17.4342 10.0959 17.3567 10.1328 17.2707C10.1698 17.1847 10.1888 17.092 10.1888 16.9985C10.1888 16.9049 10.1698 16.8122 10.1328 16.7262C10.0959 16.6402 10.0419 16.5627 9.97404 16.4982L2.10204 8.9982L9.97404 1.5012C10.0419 1.43674 10.0959 1.35915 10.1328 1.27315C10.1698 1.18715 10.1888 1.09454 10.1888 1.00095C10.1888 0.907362 10.1698 0.81475 10.1328 0.728752C10.0959 0.642751 10.0419 0.565162 9.97404 0.500701L9.96504 0.491701C9.82556 0.358734 9.64025 0.284557 9.44754 0.284557C9.25483 0.284557 9.06952 0.358734 8.93004 0.491701L0.570541 8.4567C0.497022 8.52675 0.438494 8.61099 0.398502 8.70433C0.358511 8.79767 0.33789 8.89816 0.33789 8.9997C0.33789 9.10125 0.358511 9.20173 0.398502 9.29507C0.438494 9.38841 0.497022 9.47266 0.570541 9.5427Z"
      fill="#289AF4"
    />
  </svg>
);

export default PrevArrowIcon;
