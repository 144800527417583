import { UserMonthStatsBody } from "../types/UserMonthStatsBody";
import { LocalStorage } from "../utils/LocalStorage";
import BaseManager from "./BaseManager";

class UserMonthStatsManager extends BaseManager {
  save(data: UserMonthStatsBody[]) {
    const userId = this.getUserId();
    LocalStorage.setUserMonthStats(userId, data);
  }

  load(userId: string) {
    const storedStats: UserMonthStatsBody[] = LocalStorage.getUserMonthStats(userId);
    return storedStats;
  }

  update(data: UserMonthStatsBody) {
    const userId = this.getUserId();
    const storedStats = LocalStorage.getUserMonthStats(userId);
    storedStats.push(data);

    this.save(storedStats);
  }

  remove(userId: string) {
    LocalStorage.removeUserMonthStats(userId);
  }
}

const userMonthStatsManager = new UserMonthStatsManager();
export default userMonthStatsManager;
