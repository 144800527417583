type Props = {
  className?: string;
};

const MedalIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="23px"
    height="22px"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3745 0.72875C12.1889 0.89375 10.7451 3.75891 10.7451 3.75891L9.6709 8.09188L13.8509 7.70859C13.8509 7.70859 17.3279 1.24266 17.3984 1.09313C17.5239 0.821563 17.5909 0.709844 17.1114 0.709844C16.6318 0.708125 12.5481 0.574063 12.3745 0.72875Z"
      fill="#176CC7"
    />
    <path
      d="M14.5391 7.44036C14.3312 7.32864 8.20555 7.23239 8.06118 7.44036C7.93055 7.62942 7.94946 8.49395 8.01305 8.60567C8.07665 8.71739 10.1357 9.67473 10.1357 9.67473L10.134 10.0236C10.134 10.0236 10.2474 10.2969 11.3956 10.2969C12.5437 10.2969 12.6623 10.0649 12.6623 10.0649L12.6726 9.71255C12.6726 9.71255 14.4756 8.81192 14.5701 8.73286C14.6663 8.65208 14.7471 7.55208 14.5391 7.44036ZM12.652 8.87208C12.652 8.87208 12.6468 8.63145 12.5282 8.5713C12.4096 8.51114 10.5001 8.52145 10.3385 8.53005C10.177 8.53864 10.177 8.82739 10.177 8.82739L8.87071 8.19145V8.06426L13.7348 8.09864L13.7434 8.24301L12.652 8.87208Z"
      fill="#FCC417"
    />
    <path
      d="M10.685 8.79492C10.5235 8.79492 10.4307 8.96336 10.4307 9.25383C10.4307 9.52539 10.5235 9.75398 10.728 9.7368C10.8964 9.72305 10.9549 9.46523 10.9394 9.23664C10.9239 8.96508 10.8896 8.79492 10.685 8.79492Z"
      fill="#FDFFFF"
    />
    <path
      d="M5.48047 15.5924C5.48047 19.7106 9.20328 21.5445 11.6783 21.4568C14.4077 21.3589 17.6767 19.231 17.4791 15.302C17.2917 11.5792 14.1602 9.81401 11.448 9.83291C8.29578 9.85526 5.48047 11.9951 5.48047 15.5924Z"
      fill="#FCC417"
    />
    <path
      d="M11.5912 20.7402C11.5482 20.7402 11.5035 20.7402 11.4605 20.7385C10.1715 20.6956 8.89788 20.1524 7.96631 19.2501C6.96944 18.2842 6.42288 16.9779 6.42975 15.5737C6.44522 12.1448 9.44272 10.5549 11.476 10.5549H11.4932C14.2483 10.567 16.5102 12.7085 16.5738 15.6115C16.6013 16.904 16.0719 18.2601 15.0338 19.281C14.0644 20.2315 12.7788 20.7402 11.5912 20.7402ZM11.4726 11.1256C9.64897 11.1256 6.95225 12.6174 6.94538 15.5737C6.94022 17.784 8.65209 20.0562 11.5035 20.1524C12.5898 20.1868 13.7087 19.7709 14.6127 18.8823C15.5512 17.9593 16.0788 16.7699 16.0599 15.6184C16.0187 13.121 13.9768 11.1342 11.4983 11.1238C11.4915 11.1238 11.4777 11.1256 11.4726 11.1256Z"
      fill="#FA912C"
    />
    <path
      d="M10.4378 10.4174C10.3192 10.2541 8.97518 10.285 7.70503 11.5259C6.47956 12.7239 6.34722 13.8531 6.58268 13.9184C6.84909 13.9924 7.17393 12.7961 8.29628 11.7906C9.32753 10.8694 10.675 10.7422 10.4378 10.4174ZM15.6371 14.8638C15.2228 14.9222 15.6525 16.1631 14.6917 17.5519C13.8582 18.7567 12.9128 19.1933 13.0228 19.458C13.1707 19.812 14.6849 18.9303 15.3861 17.4934C16.0221 16.1924 15.9464 14.8191 15.6371 14.8638Z"
      fill="#FEFFFA"
    />
    <path
      d="M10.0996 13.2379C10.0102 13.3702 10.091 14.5304 10.1443 14.5819C10.2474 14.685 11.0311 14.3155 11.0311 14.3155L11.0019 17.4179C11.0019 17.4179 10.4261 17.411 10.366 17.4333C10.2474 17.478 10.2629 18.6743 10.3505 18.7327C10.4382 18.7911 12.7877 18.8221 12.8771 18.7035C12.9665 18.5849 12.9441 17.5382 12.9029 17.49C12.829 17.4007 12.2669 17.4368 12.2669 17.4368C12.2669 17.4368 12.315 12.8683 12.2996 12.7222C12.2841 12.5761 12.1225 12.485 11.93 12.5297C11.7375 12.5744 10.1563 13.1519 10.0996 13.2379Z"
      fill="#FA912C"
    />
    <path
      d="M4.8849 0.639324C4.77662 0.739012 8.91709 8.11245 8.91709 8.11245C8.91709 8.11245 9.61146 8.20182 11.1618 8.21557C12.7121 8.22932 13.4804 8.1262 13.4804 8.1262C13.4804 8.1262 10.2611 0.713231 10.0686 0.639324C9.97412 0.601512 8.69881 0.587762 7.45271 0.574012C6.2049 0.558543 4.98802 0.543074 4.8849 0.639324Z"
      fill="#2E9DF4"
    />
  </svg>
);

export default MedalIcon;
