import Image from "next/image";
import Link from "next/link";
import { IImg, ILink, ILogo, ISocials, LoginBtnMobileProps } from "./types";
import styles from "./Common.module.scss";
import {
  BuyMeACoffeeHeaderIcon,
  BuyMeACoffeeIcon,
  DiscordLegacyIcon,
  FacebookLegacyIcon,
  LoginIcon,
  SudokuProIcon,
  TelegramLegacyIcon,
} from "../../svg";
import { AnalyticsHelper } from "../../utils/analyticEvents";
import { LoginEvents, SocialEvents } from "../../constants/analyticEvents";
import { useRoutes } from "../../helpers/translationTexts/useTranslation";
import { LocalStorage } from "@/utils/LocalStorage";
import Router from "next/router";

export const StyledLink: React.FC<ILink> = ({ children, href, target, rel, className, ariaLabel, onClick }) => (
  <Link
    href={href}
    target={target}
    rel={rel ? "noreferrer noopener" : ""}
    className={className ? `${className} ${styles.link}` : styles.link}
    aria-label={ariaLabel}
    onClick={onClick}
  >
    {children}
  </Link>
);

export const StyledImg: React.FC<IImg> = ({ src, alt, width, height, className }) => (
  <Image src={src} alt={alt} width={width} height={height} className={className ? className : styles.displayBlock} />
);

export const Socials: React.FC<ISocials> = ({ section }) => (
  <div className={section === "footer" ? styles.socialsFooter : styles.socialsMobileMenu}>
    <StyledLink
      onClick={() => AnalyticsHelper({ action: SocialEvents.openTelegram })}
      href="https://t.me/omiplaycom"
      rel
      target="_blank"
      ariaLabel="Go to OmiPlay Telegram Channel"
    >
      <TelegramLegacyIcon width={38} height={38} />
    </StyledLink>

    <StyledLink
      onClick={() => AnalyticsHelper({ action: SocialEvents.openDiscord })}
      href="https://discord.gg/mWvhWqAq"
      rel
      target="_blank"
      ariaLabel="Go to OmiPlay Discord Channel"
    >
      <DiscordLegacyIcon width={38} height={38} />
    </StyledLink>

    <StyledLink
      onClick={() => AnalyticsHelper({ action: SocialEvents.openFacebook })}
      href="https://www.facebook.com/omiplaycom"
      rel
      target="_blank"
      ariaLabel="Go to OmiPlay Facebook Page"
    >
      <FacebookLegacyIcon width={38} height={38} />
    </StyledLink>
  </div>
);

export const Logo: React.FC<ILogo> = ({ section }) => {
  return (
    <StyledLink href={useRoutes().Root}>
      <SudokuProIcon className={section === "header" ? styles.logoSvgHeader : styles.logoSvgFooter} />
    </StyledLink>
  );
};

export const LoginBtnMobile: React.FC<LoginBtnMobileProps> = ({ isMenu, setMenuOpen, path }) => {
  const loginRouter = useRoutes().Login;
  return (
    <button
      onClick={() => {
        AnalyticsHelper({ action: LoginEvents.loginClicked });

        LocalStorage.setPathRedirectAfterLogin(path);
        Router.push(loginRouter);
        isMenu && setMenuOpen(!isMenu);
      }}
      type="button"
      className={styles.loginBtnMobile}
    >
      <LoginIcon />
    </button>
  );
};

export const BuyUsACoffee: React.FC<ILogo> = ({ section }) => (
  <StyledLink
    className={section === "footer" ? styles.gameLinkfooter : styles.gameLink}
    ariaLabel="Buy Me A Coffee Sudoku Pro Link"
    href="https://buymeacoffee.com/omiplay"
    rel
    target="_blank"
    onClick={() => AnalyticsHelper({ action: SocialEvents.buyMeACoffee })}
  >
    <div className={section === "header" ? styles.buyMeACoffeeButton : styles.buyMeACoffeeFooterButton}>
      {section === "header" ? (
        <BuyMeACoffeeHeaderIcon className={styles.buyMeACoffeeIcon} />
      ) : (
        <BuyMeACoffeeIcon className={styles.buyMeACoffeeIcon} />
      )}
      <div className={section === "header" ? styles.buyMeACoffeeHeaderText : styles.buyMeACoffeeText}>
        Buy us a Coffee
      </div>
    </div>
  </StyledLink>
);
