import getTransformedDataAwards from "../helpers/getTransformedDataAwards";
import { LocalStorage } from "../utils/LocalStorage";
import { getAnnualAwards } from "./awardsApis";
import {
  getSudokuForMonth,
  sendUserMonthStats,
  sendPassedDailyLevel,
  syncOfflineDailyGames,
  syncOfflineUserMonthStats,
  getShockModeDataApi,
  getDailyProgressApi,
} from "./gameApis";
import { validateDate } from "../utils/validators";
import { refreshTokenApi } from "./authApis";
import annualAwardsManager from "./AnnualAwardsManager";
import { currentDateAsString, daysInMonth, parseDate } from "../utils/dateHelper";
import { BestTime, UserAnnualAward } from "./models/userAnnualAward";
import { UserMonthStatsBody } from "../types/UserMonthStatsBody";
import userMonthStatsManager from "./UserMonthStatsManager";
import pointsManager from "./PointsManager";
import BaseManager from "./BaseManager";

class SyncManager extends BaseManager {
  /**
   * Data synchronization, the following functions will be called:
   * - @see refreshSession
   * - @see sendOfflineProgress
   * - @see loadDailySudokuForMonths
   */
  async syncData(currentYear: string) {
    await this.refreshSession(LocalStorage.getRefreshToken() || null);
    await new Promise((resovle) => setTimeout(resovle, 1000)); // TODO
    await this.sendNotSynchronizedDailyGames();
    await this.sendNotSynchronizedUserMonthStats();
    // loadDailySudokuForMonths(); TODO current & next months
    await this.syncAnnualAwards(currentYear);
  }

  /**
   * Send the completed daily challenge to the server, or save
   * it locally in case of failure
   */
  async sendOrStorePassedDailyLevel(
    sudoku: string,
    solvedSudoku: string,
    date: string,
    wastedTimeSec: number,
    notes: boolean,
    hints: number,
    level: string,
    currentDateGame: string
  ) {
    if (this.isUserAuthorized()) {
      const response = await sendPassedDailyLevel(
        sudoku,
        solvedSudoku,
        date,
        wastedTimeSec,
        notes,
        hints,
        currentDateGame
      );

      if (response.error) {
        // TODO store completed daily challenge locally
        annualAwardsManager.storePassedDailyGames(date, wastedTimeSec);
        pointsManager.calculateLocalPoint(level, notes, hints);

        return;
      } else {
        annualAwardsManager.update(date, response);

        await pointsManager.getPoints(date);
      }
    } else {
      annualAwardsManager.storePassedDailyGames(date, wastedTimeSec);

      pointsManager.calculateLocalPoint(level, notes, hints);
    }
  }

  calculateNewProgress(year: string, month: string, gamesInMonth: BestTime[]) {
    const daysInMonthVal = daysInMonth(year, month);
    if (daysInMonthVal == gamesInMonth.length) {
      return 100;
    }

    return Math.round((100 / daysInMonthVal) * gamesInMonth.length);
  }

  async sendOrStoreUserMonthStats(game: UserMonthStatsBody) {
    if (this.isUserAuthorized()) {
      game.level = game.level.toUpperCase();
      const response = await sendUserMonthStats(game);

      if (response.error) {
        userMonthStatsManager.update(game);

        pointsManager.calculateLocalPoint(game.level, game.notes, game.hints);

        return;
      } else {
        pointsManager.update(response.points);
        return response.points;
      }
    } else {
      userMonthStatsManager.update(game);

      pointsManager.calculateLocalPoint(game.level, game.notes, game.hints);
    }
  }

  getCompletedGamesInMonth(year: string, month: string) {
    return annualAwardsManager.loadMonthGames(year, month);
  }

  isLastDailyChallenge(year: string, month: string) {
    const completedGames = annualAwardsManager.loadMonthGames(year, month);
    const daysInMonth = new Date(Number(year), Number(month), 0).getDate();

    return completedGames.length === daysInMonth;
  }

  /**
   * Download awards for a specific year and transform them to proper format
   *
   * @param {string} year - for which year do you need to download the awards
   */
  async loadAnnualAwards(year: string, langPath: string) {
    const response = this.isUserAuthorized() ? await getAnnualAwards(year) : null;

    if (response == null || response.error) {
      const localAwards = annualAwardsManager.load(year);
      return getTransformedDataAwards(localAwards, langPath);
    }

    // Store annual rewards to localStorage
    annualAwardsManager.save(year, response);

    return getTransformedDataAwards(response, langPath);
  }

  /**
   * Load daily sudoku levels for current and next months, if need.
   * Previous months will be stored locally.
   */
  async loadDailySudokuForMonths(year: string, month: string) {
    const storedDailySudokuForMonth = LocalStorage.getDailyChallenge(month, year);
    if (storedDailySudokuForMonth) {
      return;
    }

    const dailySudokuForMonth = await getSudokuForMonth(year, month);
    if (!dailySudokuForMonth.error) {
      LocalStorage.setDailyChallenge(year, month, dailySudokuForMonth);
    }
  }

  async loadCurrentDailySudoku(date: string | null) {
    if (!validateDate(date)) {
      return "";
    }

    const { year, month, day } = parseDate(date!);

    const storedDailySudokuForMonth = LocalStorage.getDailyChallenge(month, year);
    if (storedDailySudokuForMonth) {
      return storedDailySudokuForMonth[Number(day) - 1]?.sudoku;
    }

    const dailySudokuForMonth = await getSudokuForMonth(year, month);
    if (!dailySudokuForMonth.error) {
      LocalStorage.setDailyChallenge(year, month, dailySudokuForMonth);
      return dailySudokuForMonth[Number(day) - 1]?.sudoku;
    }

    return "";
  }

  getAnnualAwardsCount(year: string) {
    let awardsCount: number = 0;

    const localAwards = annualAwardsManager.load(year);
    for (let i = 0; i < localAwards.length; i++) {
      if (localAwards[i].progress == 100) {
        awardsCount++;
      }
    }

    return awardsCount;
  }

  deleteSyncAwardsData(userId?: string) {
    const startDate = new Date("2022-01-01");
    const endDate = new Date();

    for (startDate; startDate < endDate; startDate.setFullYear(startDate.getFullYear() + 1)) {
      const year = startDate.getFullYear().toString();
      userId ? LocalStorage.removeAnnualAwards(year, userId) : LocalStorage.removeAnnualAwards(year);
    }
  }

  async clearUserData() {
    LocalStorage.removeTokens();
    LocalStorage.removeUserData();
  }

  async sendOfflineProgress() {
    if (!this.isUserAuthorized()) return;
    const userId = this.getUserData()?._id;

    const startDate = new Date("2022-01-01");
    const endDate = new Date();
    const monthsForSyncLS: UserAnnualAward[] = [];

    for (let year = startDate.getFullYear(); year <= endDate.getFullYear(); year++) {
      const yearStr = year.toString();
      const historyMonthsLS = LocalStorage.getAnnualAwards("", yearStr);

      monthsForSyncLS.push(
        ...historyMonthsLS.map((historyMonthLS) => ({
          ...historyMonthLS,
          userId,
          year: yearStr,
        }))
      );
      LocalStorage.removeAnnualAwards(yearStr);
    }

    if (monthsForSyncLS.length) {
      await syncOfflineDailyGames(monthsForSyncLS);
    }
  }

  updateTokens(data: any) {
    LocalStorage.setAccessToken(data.accessToken);
    LocalStorage.setRefreshToken(data.refreshToken);
    LocalStorage.setTimeRefreshToken(new Date());
  }

  async getShockModeData() {
    if (!this.isUserAuthorized()) {
      return null;
    }

    const response = await getShockModeDataApi();
    if (response.error) {
      return null;
    }

    return response;
  }

  async getDailyProgress() {
    if (!this.isUserAuthorized()) {
      const { year, month } = currentDateAsString();
      const days = daysInMonth(year, month);
      const savedMonthProgress = annualAwardsManager.loadMonthGames(year, month);

      return `${savedMonthProgress.length}/${days}`;
    }

    const response = await getDailyProgressApi();

    if (response.error) {
      return "";
    }

    return response.dailyProgress;
  }

  private async syncAnnualAwards(year: string) {
    const isUserAuthorized = this.isUserAuthorized();
    if (!isUserAuthorized) return;
    const response = await getAnnualAwards(year);
    if (response.error) {
      return;
    }

    // Store annual rewards to localStorage
    annualAwardsManager.save(year, response);
  }

  /**
   * Function to refresh the session. It is called every time you enter the game
   *
   * @param {string} refreshToken - a token that helps to get a new AccessToken
   */
  private async refreshSession(refreshToken: string | null) {
    if (!refreshToken) return;

    const startDate = LocalStorage.getTimeRefreshToken();

    if (startDate && startDate.setDate(startDate.getDate() + 1) >= new Date().getTime()) return;

    const response = await refreshTokenApi(refreshToken);
    // TODO correct detect 401 error
    if (response.error && response.error.includes("status code 401")) {
      this.clearUserData();
      return;
    }

    this.updateTokens(response);
  }

  /**
   * Send to the server all completed daily games that were not sent for any reason.
   * For example, lack of Internet, etc
   */

  private async sendNotSynchronizedDailyGames() {
    const isUserAuthorized = this.isUserAuthorized();
    if (!isUserAuthorized) return;
    const userId = this.getUserData()?._id;

    const startDate = new Date("2022-01-01");
    const endDate = new Date();
    const monthForSync: UserAnnualAward[] = [];

    for (let year = startDate.getFullYear(); year < endDate.getFullYear(); year++) {
      const yearStr = year.toString();
      const historyMonths = annualAwardsManager.load(yearStr);

      monthForSync.push(
        ...historyMonths
          .filter((historyMonth) => !historyMonth.bestTime.some((game) => !game._id))
          .map((historyMonth) => ({
            ...historyMonth,
            userId,
            year: yearStr,
          }))
      );
    }

    if (monthForSync.length) {
      return await syncOfflineDailyGames(monthForSync);
    }
  }

  private async sendNotSynchronizedUserMonthStats() {
    const isUserAuthorized = this.isUserAuthorized();

    if (!isUserAuthorized) return;

    const { _id: userId } = this.getUserData() as UserData;

    const savedStats = userMonthStatsManager.load("");
    const failedSyncGames = userMonthStatsManager.load(userId);

    savedStats.push(...failedSyncGames);

    const userStats = savedStats.map((stat) => ({ ...stat, level: stat.level.toUpperCase() }));

    if (!savedStats.length) return;
    const response = await syncOfflineUserMonthStats(userStats);

    if (response.error) {
      for (const savedStat of savedStats) {
        pointsManager.calculateLocalPoint(savedStat.level, savedStat.notes, savedStat.hints);
      }

      return;
    } else {
      pointsManager.getPoints(`${response.year}-${response.month}-01`);

      userMonthStatsManager.remove("");
      userMonthStatsManager.remove(userId);
    }
  }
}

const syncManager = new SyncManager();
export default syncManager;
