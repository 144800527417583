export enum LANGUAGES {
  EN = "en",
  BG = "bg",
  BS = "bs", // Bosnian
  CS = "cs", // Czech
  DA = "da", // Danish
  DE = "de", // German
  EL = "el", // Greek
  ES = "es",
  ET = "et", // Estonian
  FI = "fi", // Finnish
  FR = "fr",
  HR = "hr", // Croatian
  HU = "hu",
  ID = "id", // Indonesian
  IT = "it",
  JA = "ja", // Japan
  // KA = "ka", // Georgian
  KK = "kk", // Kazakh
  KO = "ko", // Korean
  LT = "lt",
  LV = "lv",
  // MK = "mk", // Makedonian
  MS = "ms", // Malay
  NL = "nl", // Dutch, Nederlands
  NO = "no",
  PL = "pl",
  PT = "pt",
  ROM = "ro",
  SK = "sk", // Slovak
  SL = "sl", // Slovenian
  SQ = "sq", // Albanian
  SR = "sr", // Serbian
  SV = "sv", // Swedish
  // TH = "th", // Thai
  TR = "tr",
  UK = "uk",
  // VI = "vi", // Vietnamese
  // ZH = "zh", // Chinese
}
