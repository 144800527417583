import React from "react";
type Props = {
  className?: string;
};
const GoogleIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5707 17.2795C31.5707 16.085 31.4715 15.2133 31.2568 14.3094H17.2969V19.7009H25.491C25.3259 21.0407 24.4338 23.0585 22.4513 24.4144L22.4235 24.5949L26.8374 27.9359L27.1432 27.9658C29.9516 25.4314 31.5707 21.7025 31.5707 17.2795Z"
        fill="#4285F4"
      />
      <path
        d="M17.2982 31.4847C21.3127 31.4847 24.6829 30.1933 27.1445 27.9657L22.4526 24.4143C21.1971 25.2699 19.512 25.8671 17.2982 25.8671C13.3664 25.8671 10.0292 23.3329 8.83962 19.83L8.66525 19.8445L4.07565 23.3151L4.01562 23.4781C6.46065 28.2239 11.4829 31.4847 17.2982 31.4847Z"
        fill="#34A853"
      />
      <path
        d="M8.83963 19.8298C8.52574 18.9259 8.34408 17.9572 8.34408 16.9565C8.34408 15.9556 8.52574 14.9871 8.82311 14.0831L8.8148 13.8906L4.16767 10.3643L4.01563 10.4349C3.00792 12.4043 2.42969 14.6158 2.42969 16.9565C2.42969 19.2971 3.00792 21.5085 4.01563 23.4779L8.83963 19.8298Z"
        fill="#FBBC05"
      />
      <path
        d="M17.2982 8.04604C20.0902 8.04604 21.9735 9.22442 23.0474 10.2092L27.2436 6.20585C24.6665 3.86523 21.3127 2.42856 17.2982 2.42856C11.4829 2.42856 6.46065 5.68928 4.01562 10.4351L8.82311 14.0833C10.0292 10.5804 13.3664 8.04604 17.2982 8.04604Z"
        fill="#EB4335"
      />
    </svg>
  );
};

export default GoogleIcon;
