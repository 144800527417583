import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";

type Props = {
  title: string;
  description: string;
  keywords?: string;
  image?: string;
};

const BaseHeader: React.FC<Props> = ({
  title,
  description,
  keywords = "",
  image = "https://sudokupro.app/images/SocialPreview.jpg",
}) => {
  const pageUrl = "https://sudokupro.app" + useRouter().asPath;

  return (
    <Head>
      <meta name="viewport" content="width=device-width,initial-scale=1" />

      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="sudokupro.app" />
      <meta property="twitter:url" content={pageUrl} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
    </Head>
  );
};

export default BaseHeader;
