import axios from "axios";
import type { AxiosRequestConfig } from "axios";
import { LocalStorage } from "../utils/LocalStorage";
import { CONFIG } from "@/config";

/**
 * @description Axios instance used for Blog API requests
 */
const API_BLOG = axios.create({
  baseURL: CONFIG.BASE_URL,
});

/**
 * @description Axios instance used for Game API requests
 */
const API_GAME = axios.create({
  baseURL: CONFIG.BASE_URL_GAME,
});

API_GAME.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const accessToken = LocalStorage.getAccessToken();

    config.headers = config.headers ?? {};
    config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { API_BLOG, API_GAME };
