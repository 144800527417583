import { Icon } from "./types";

const DiscordLegacyIcon: React.FC<Icon> = ({ className, width, height }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 150 150">
    <circle cx="75" cy="75" r="70" fill="#5865f2" strokeWidth="0.274"></circle>
    <path
      fill="#fff"
      strokeWidth="0.391"
      d="M109.71 42.783A81.455 81.455 0 0089.079 36.3a60.513 60.513 0 00-2.643 5.487c-7.692-1.157-15.314-1.157-22.864 0a58.632 58.632 0 00-2.674-5.487 81.188 81.188 0 00-20.647 6.499c-13.056 19.73-16.596 38.97-14.826 57.937 8.66 6.467 17.052 10.395 25.303 12.966a62.932 62.932 0 005.42-8.925 53.288 53.288 0 01-8.535-4.154 42.405 42.405 0 002.092-1.655c16.455 7.697 34.333 7.697 50.59 0 .685.57 1.385 1.125 2.093 1.655a53.161 53.161 0 01-8.55 4.162 62.65 62.65 0 005.42 8.925c8.258-2.57 16.658-6.5 25.317-12.974 2.077-21.987-3.547-41.051-14.865-57.953zM58.389 89.072c-4.94 0-8.99-4.611-8.99-10.227 0-5.615 3.964-10.235 8.99-10.235s9.077 4.611 8.99 10.235c.008 5.615-3.964 10.227-8.99 10.227zm33.223 0c-4.94 0-8.99-4.611-8.99-10.227 0-5.615 3.964-10.235 8.99-10.235s9.077 4.611 8.99 10.235c0 5.615-3.964 10.227-8.99 10.227z"
    ></path>
  </svg>
);

export default DiscordLegacyIcon;
