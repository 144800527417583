import {
  InstallAppEvents,
  SocialEvents,
  GameCompletedEvents,
  GameStartEvents,
  LoginEvents,
} from "../constants/analyticEvents";

type Event =
  | InstallAppEvents
  | SocialEvents
  | GameCompletedEvents
  | GameStartEvents
  | LoginEvents;

type GTagEvent = {
  action: Event;
  value?: string;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const AnalyticsHelper = ({ action, value }: GTagEvent) => {
  window.gtag("event", action, {
    value: value,
  });
};
