import React from "react";
type Props = {
  className: string;
};
const NextArrowIcon: React.FC<Props> = ({ className }) => (
  <svg className={className} viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4295 8.45632L2.06996 0.491322C1.93048 0.358355 1.74516 0.284179 1.55246 0.284179C1.35975 0.284179 1.17444 0.358355 1.03496 0.491322L1.02596 0.500322C0.958106 0.564782 0.904077 0.642372 0.867156 0.728371C0.830235 0.814371 0.811196 0.906982 0.811196 1.00057C0.811196 1.09416 0.830235 1.18677 0.867156 1.27277C0.904077 1.35877 0.958106 1.43636 1.02596 1.50082L8.89796 9.00082L1.02596 16.4978C0.958105 16.5623 0.904076 16.6399 0.867155 16.7259C0.830235 16.8119 0.811196 16.9045 0.811196 16.9981C0.811196 17.0917 0.830235 17.1843 0.867155 17.2703C0.904076 17.3563 0.958105 17.4339 1.02596 17.4983L1.03496 17.5073C1.17444 17.6403 1.35975 17.7145 1.55246 17.7145C1.74516 17.7145 1.93048 17.6403 2.06996 17.5073L10.4295 9.54232C10.503 9.47228 10.5615 9.38803 10.6015 9.29469C10.6415 9.20135 10.6621 9.10087 10.6621 8.99932C10.6621 8.89778 10.6415 8.79729 10.6015 8.70395C10.5615 8.61061 10.503 8.52637 10.4295 8.45632Z"
      fill="#289AF4"
    />
  </svg>
);

export default NextArrowIcon;
