import * as Props from "./types";
import styles from "./styles.module.scss";
import useTranslation, { useRoutes } from "../../../helpers/translationTexts/useTranslation";
import Router from "next/router";
import isUserAuthorized from "../../../helpers/isUserAuthorized";
import { LocalStorage } from "../../../utils/LocalStorage";
import { BaseModal } from "..";
import { useSession } from "@/context/SessionProvider";
import {
  Description,
  Button,
  ButtonWrapper,
  TextWrapper,
  Value,
  TextLineWrapper,
} from "./ConsecutiveDaysWargingModal.components";

const ConsecutiveDaysWargingModal: React.FC<Props.ConsecutiveModal> = ({
  isOpen,
  onCloseClicked,
  closeMobileMenu,
  currentPath,
}) => {
  const { shockModeData } = useSession();

  const loginPath = useRoutes().Login;
  const isAuthorized = isUserAuthorized();

  const loginText = useTranslation("logIn");
  const descriptionText = useTranslation("consecutiveDaysModalText");
  const currentConsecutiveDaysText = useTranslation("currentConsecutiveDaysModalText");
  const maxConsecutiveDaysText = useTranslation("maxConsecutiveDaysModalText");
  const notAuthorzedText = useTranslation("consecutiveDaysModalTextLogIn");
  const buttonOkText = useTranslation("ok");

  const normalizeNumberOfDays = (value: number | undefined) => {
    return value ? value : 0;
  };

  return (
    <BaseModal needCloseBtn={false} isOpen={isOpen} onClose={onCloseClicked} modalWrapperStyles={styles.wrapper}>
      <TextWrapper>
        <Description description={descriptionText} />
        <TextLineWrapper>
          <Description description={currentConsecutiveDaysText} />
          <Value value={normalizeNumberOfDays(shockModeData?.consecutiveDays)} />
        </TextLineWrapper>

        <TextLineWrapper>
          <Description description={maxConsecutiveDaysText} />
          <Value value={normalizeNumberOfDays(shockModeData?.maxConsecutiveDays)} />
        </TextLineWrapper>

        {!isAuthorized && <Description description={notAuthorzedText} />}
      </TextWrapper>

      <ButtonWrapper>
        <Button onClick={() => onCloseClicked()} text={buttonOkText} />
        {!isAuthorized && (
          <Button
            onClick={() => {
              LocalStorage.setPathRedirectAfterLogin(currentPath);
              Router.push(loginPath);
              onCloseClicked();
              closeMobileMenu();
            }}
            text={loginText}
          />
        )}
      </ButtonWrapper>
    </BaseModal>
  );
};

export default ConsecutiveDaysWargingModal;
