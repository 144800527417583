export enum InstallAppEvents {
  installMobApp = "install_mobile_app_clicked",
  installMobAppAndroid = "install_mobile_app_clicked_android",
  installMobAppIos = "install_mobile_app_clicked_ios",
  installAppHome1 = "install_app_home_1",
  installAppHome2 = "install_app_home_2",
  installAppHome3 = "install_app_home_3",
  installAppHomeGameOverPopup = "install_app_home_game_over_popup",
  installAppDailyCalendar = "install_app_daily_calendar",
  installAppDailyGame = "install_app_daily_game",
  installAppDailyGameOverPopup = "install_app_daily_game_over_popup",
  installAppBlogTopic = "install_app_blog_topic",
}

export enum SocialEvents {
  openDiscord = "open_discord",
  openFacebook = "open_facebook",
  openTelegram = "open_telegram",
  buyMeACoffee = "buy_me_a_coffee_clicked",
}

export enum LoginEvents {
  loginClicked = "login_clicked",
  logoutClicked = "logout_clicked",
  facebookLoginClicked = "facebook_login_clicked",
  googleLoginClicked = "google_login_clicked",
}

export enum GameCompletedEvents {
  gameCompleted = "game_completed",
  dailyGameCompleted = "daily_game_completed",
  tutorialCompleted = "tutorial_completed",
}

export enum GameStartEvents {
  "easy" = "start_game_easy",
  "medium" = "start_game_medium",
  "hard" = "start_game_hard",
  "expert" = "start_game_expert",
  "extreme" = "start_game_extreme",
}
