import React, { useCallback, useEffect } from "react";
import { CloseModalButton, Modal, Overlay } from "./BaseModal.components";
import * as Props from "./types";

const BaseModal: React.FC<Props.BaseModal> = ({
  children,
  isOpen,
  onClose,
  modalWrapperStyles,
  needCloseBtn = true,
}) => {
  const handleCloseModal = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        onClose();
      }
    };
    window.addEventListener("keydown", handleEscKeyPress);

    return () => {
      window.removeEventListener("keydown", handleEscKeyPress);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return (
    isOpen && (
      <Overlay onClose={handleCloseModal}>
        <Modal inputStyles={modalWrapperStyles}>
          {needCloseBtn && <CloseModalButton onClose={onClose} />}
          {children}
        </Modal>
      </Overlay>
    )
  );
};

export default BaseModal;
