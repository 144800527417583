export const bg: any = {
  ISOcode: "bg",
  countryName: "Bulgaria",
  language: "Български",
  blog: "Блог",
  dailyChallenges: "Ежедневно предизвикателство",
  dailyChallenge: "ЕЖЕДНЕВНО ПРЕДИЗВИКАТЕЛСТВО",
  readBlog: "Прочетете блога",
  omisoft: "OmiSoft",
  level: "Ниво: ",
  levels: "Нива",
  privacyPolicy: "Политика за поверителност",
  howToPlay: "Как да играя",
  newGame: "НОВА ИГРА",
  undo: "Отмяна на",
  notes: "Бележки",
  hints: "Съвети",
  erase: "Изтриване на",
  nowDownload:
    "ИЗТЕГЛЕТЕ СЕГА ИЗТЕГЛЕТЕ СЕГА ИЗТЕГЛЕТЕ СЕГА ИЗТЕГЛЕТЕ СЕГА ИЗТЕГЛЕТЕ СЕГА ИЗТЕГЛЕТЕ СЕГА ИЗТЕГЛЕТЕ СЕГА ИЗТЕГЛЕТЕ СЕГА ИЗТЕГЛЕТЕ ",
  playBest: "Играйте най-добре",
  online: "На линия",
  playSudoku: "Судоку!",
  seeWeToldYou: "Виждате ли, казахме ви, че правилата са прости!",
  dontWasteYourTime: "Сега не губете време да се съмнявате, ",
  appIsSudokuPro: "най-доброто приложение за судоку е Sudoku Pro и трябва да го опитате!",
  playOnline: "играят онлайн",
  points: "точки",
  yes: "да",
  no: "Не",
  playMahjong: "Играйте маджонг онлайн",
  siteMap: "Карта на сайта",
  otherGame: "Други игри",
  historySudoku: "История на судоку",
  rulesSudoku: "Правила за судоку",
  tipsSudoku: "Съвети за судоку",

  sudokuTipsAndTrick: "Судоку онлайн - съвети и трикове или как да станете професионалист",
  goBack: "Обратно към началото",

  playSudokuOnline: "Играйте Судоку онлайн",
  playNow: "Играйте сега",
  cookieText:
    "Продължавайки да разглеждате сайта, вие потвърждавате, че сте прочели Политиката за поверителност и се съгласявате с използването на бисквитки.",
  cancel: "Отмяна на",
  oops: "О-о.",
  errorPageNotFound: "Грешка 404. Страницата не е намерена",
  sorryGoBackHome: "Съжаляваме, но исканата от вас страница не беше намерена. Моля, върнете се към основната страница.",
  backToHomepage: "ВРЪЩАНЕ КЪМ ДОМА",
  similarArticles: "Similar Articles",
  startYourBrainTraining:
    "Започнете да развивате мозъка си още сега! Изтеглете безплатната онлайн игра Sudoku Pro и не се оплаквайте, че не можете да спрете да решавате пъзели Sudoku.",
  //////// Meta and Description /////////
  metaPageTitle: "Как да играем Судоку - от начинаещ до Sudoku Pro",
  metaPageDescription:
    "Прочетете блога Sudoku Pro за съвети и трикове как да играете Sudoku като професионалист, независимо от нивото ви. Получете просто обяснение на сложните стратегии на логическата игра, за да играете по-ефективно и да подобрите паметта си.",

  metaBlogTitle: "Sudoku Online - съвети и трикове | От noob до Sudoku Pro",
  metaBlogDescription:
    "Прочетете блога ни Sudoku Pro, за да научите всички функции на Sudoku за начинаещи и напреднали играчи. Получете просто обяснение на професионални стратегии и съвети за решаване на Судоку онлайн.",

  /////////////Basic rules/////////////
  gameRules: {
    subtitle: "Судоку е лесно!",
    title: "Основни правила на играта Судоку",
    titleText:
      "Ако сте начинаещ и не знаете как да решавате Судоку, няма от какво да се срамувате. Тази игра е много логична и е много лесно да се научите да играете Судоку. Има 3 основни правила и в зависимост от вида на логическата игра, която сте избрали, може да има някои нюанси, но основните правила на Судоку остават същите.",
    goal: "Цел:",
    goalText:
      "Попълнете решетката 9×9, така че всяка колона, ред и секция 3×3 да съдържа числа от 1 до 9. Традиционно няма ограничение във времето, но някои онлайн игри със судоку добавят ограничение във времето и сякаш ви карат да мислите по-бързо.",
    ruleTitle: "КАК СЕ ИГРАЕ СУДОКУ?",
    ruleText:
      "Когато започнете, някои квадрати от мрежата 9×9 вече ще имат номера. Задачата ви е да попълните липсващите места, без да оставяте празни пространства.",
    fail: "НЕ СТЕ УСПЕЛИ, АКО:",
    list: "Всеки ред съдържа две еднакви числа <list> Всяка колона има две еднакви числа <list> Всяко сечение 3×3 има две еднакви числа",
    remember: "ЗАПОМНЕТЕ:",
    rememberText: "всяка цифра от 1 до 9 може да се използва само веднъж в ред, колона или секция 3×3",
  },

  /////////// Months ////////
  January: "Януари",
  February: "февруари",
  March: "Март",
  April: "Април",
  May: "Май",
  June: "Юни",
  July: "Юли",
  August: "Август",
  September: "Септември",
  October: "Октомври",
  November: "Ноември",
  December: "Декември",

  /////Win poup////
  youWin: "Победа!!",
  youWinText: "Изтеглете приложението, за да играете по всяко време и без никакви ограничения",
  followUs: "Последвайте ни в социалните мрежи:",
  winLevel: "Ниво",
  startNewGame: "Започване на нова игра",
  time: "Време",
  bestResult: "Най-добър резултат",
  EASY: "Светлина",
  MEDIUM: "Среден",
  HARD: "Тежък",
  EXPERT: "Експерт",
  EXTREME: "Extreme",
  /////////NoGame RC//////
  sorry: "съжалявам",
  gameNotFount: "играта не е намерена",
  chooseChallenge: "Изберете предизвикателство:",

  // Login Page
  loginMetaTitle: "Влезте в Sudoku Pro",
  loginMetaDescription: "Влезте, за да запазите напредъка си в играта и да се състезавате с други играчи",
  logIn: "Влизам",
  welcomeBack: "Добре дошъл обратно",
  continueWithGoogle: "Продължете с Google",
  continueWithFacebook: "Продължете с Facebook",
  authorizationFailedMessage: "Упълномощаването е неуспешно, опитайте отново по-късно",

  // Registration Page
  registrationMetaTitle: "Завършете регистрацията - Sudoku Pro",
  registrationMetaDescription:
    "Остава само една малка стъпка, за да завършите регистрацията и да извлечете максимума от играта Судоку",
  createAnAccount: "Създай профил",
  nickName: "Псевдоним",
  country: "Държава",
  signUp: "Регистрирай се",
  createYourNickName: "Създайте свой псевдоним",
  registrationFailedMessage: "Регистрацията е неуспешна, опитайте отново по-късно",

  // Logout Dialog
  logOutMessage: "Сигурни ли сте, че искате да излезете? Вашият напредък няма да бъде запазен",
  logOut: "Излез от профила си",

  // Awards Page
  awardsMetaTitle: "Вашите награди - Sudoku Pro",
  awardsMetaDescription: "Изпълнете ежедневните предизвикателства в Sudoku Pro и съберете всички награди за годината!",
  awards: "Награди",
  yourAwards: "Вашите награди",
  yourAwardsDescription: "Изпълнявайте ежедневни предизвикателства и получавайте награди в края на всеки месец!",
  awardsDontMissASingleDay: "Не пропускайте нито един ден - играйте судоку!",

  // Monthly Award Dialog
  awardDialogTitle: "Получена месечна награда",
  awardDialogDescription: "Изпълнихте всички ежедневни предизвикателства за един месец и получихте награда! Честито!",
  awardShare: "Дял",

  // Authorization banner
  authorizationBannerMessage: "Влезте, за да запазите напредъка и наградите си 🏆",

  // You Won Dialog
  levelsLeftMessage: "Оставащи нива за получаване на наградата: %s",

  // Ratings Block
  topCountriesToday: "ТОП 20 държави днес",
  topUsersToday: "ТОП 100 потребители днес",
  numberOfUsers: "Брой потребители",
  updatedEvery24hours: "Актуализира се на всеки 24 часа",
  experiencePoints: "Точки за опит",
  showFullList: "Покажи пълния списък",
  showShortList: "Показване на кратък списък",
};
