import * as Props from "./types";
import styles from "./styles.module.scss";
import { CrossIcon } from "../../../svg";

export const Overlay: React.FC<Props.Overlay> = ({ children, onClose }) => (
  <div onClick={onClose} className={styles.overlay}>
    {children}
  </div>
);

export const Modal: React.FC<Props.Modal> = ({ children, inputStyles }) => (
  <div className={inputStyles ? `${styles.modal} ${inputStyles}` : styles.modal}>{children}</div>
);

export const CloseModalButton: React.FC<Props.CloseModalButton> = ({ onClose }) => (
  <button onClick={onClose} className={styles.closeModalButton} type="button">
    <CrossIcon className={styles.closeModalIcon} />
  </button>
);
