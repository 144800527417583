import { LocalStorage } from "../utils/LocalStorage";

const isUserAuthorized = () => {
  const savedUserData = LocalStorage.getUserData();
  const userData = savedUserData && JSON.parse(savedUserData);

  return userData && userData.nickname && userData.countryCode;
};

export default isUserAuthorized;
