const COLORS = {
  WHITE: "white",
  GRAY: "#333333",
  DEEP_GRAY: "#717384",
  GRAY_FOR_TEXT: "#616161",
  GRAY_FOR_COPY_LINK: "#757575",
  GRAY_FOR_ICONS: "#92A3BA",
  GRAY_SHORT_DESCRIPTION: "#717384",
  GRAY_LIGTHER: "#83869E",
  BUTTON_LINEAR: "linear-gradient(131.27deg, #51ADF5 8.95%, #2196F3 85.94%)",
  BUTTON_LINEAR_DAILY_CHALLENGE:
    "linear-gradient(93.62deg, #69BDFF 3.14%, #2196F3 87.37%)",
  YELLOW_BUTTON_LINEAR:
    "linear-gradient(95.66deg, #FDC065 11.47%, #F9C02E 93.62%)",
  MAIN: "#2196F3",
  MONTHS_NAMES: "#4285F4",
  SECONDARY: "#EBF6FF",
  GAME_GRID_ROWS: "#EBF6FF",
  GAME_GRID_ROWS_2: "#B9DCF7",
  ELEMENTS: "#D670FA",
  INCORRECT: "#FDD0DB",
  STAR_YELLOW: "#FEB25B",
  SHADOW: "rgba(60, 133, 191, 0.18)",
  BLUE_BUTTON_LINEAR:
    "linear-gradient(95.66deg, #65f5fd 11.47%, #2d8c91 93.62%)",
  SHARE_MODAL_LINEAR: "linear-gradient(180deg, #EBF6FF 0%, #CFE6F9 100%)",
  NUMBER_SELECTED: "#CDE6F9",
  DEEP_BLUE: "#289AF4",
  BG_DEEP_BLUE: "#3663D8",
};

export default COLORS;
