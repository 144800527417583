import { ReactNode, useCallback, useEffect } from "react";
import { CloseModalButton, SelectCountrylContent, SelectCountrylOverlay } from "./SelectCountryModal.component";

type Props = {
  children: ReactNode;
  onClose: () => void;
  isOpen: boolean;
};

const SelectCountryModal: React.FC<Props> = ({ onClose, isOpen, children }) => {
  const handleCloseModal = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        onClose();
      }
    };
    window.addEventListener("keydown", handleEscKeyPress);

    return () => {
      window.removeEventListener("keydown", handleEscKeyPress);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return (
    <SelectCountrylOverlay handleCloseModal={handleCloseModal}>
      <SelectCountrylContent>
        <CloseModalButton onClose={onClose} />
        {children}
      </SelectCountrylContent>
    </SelectCountrylOverlay>
  );
};

export default SelectCountryModal;
