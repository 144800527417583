import React from "react";

const ClockIcon: React.FC = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 0C4.93436 0 0 4.93436 0 11C0 17.0656 4.93436 22 11 22C17.0656 22 22 17.0656 22 11C22 4.93436 17.0656 0 11 0ZM16.2314 16.6897C16.0527 16.8684 15.818 16.9584 15.5834 16.9584C15.3487 16.9584 15.1139 16.8684 14.9353 16.6897L10.3519 12.1064C10.1796 11.9351 10.0834 11.7021 10.0834 11.4584V5.5C10.0834 4.9931 10.4939 4.58339 11 4.58339C11.5061 4.58339 11.9166 4.9931 11.9166 5.5V11.0789L16.2314 15.3936C16.5898 15.7521 16.5898 16.3313 16.2314 16.6897Z"
        fill="#2196F3"
      />
    </svg>
  );
};

export default ClockIcon;
