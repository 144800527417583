import { LEVELS } from "../constants/levels";
import { LocalStorage } from "../utils/LocalStorage";
import annualAwardsManager from "./AnnualAwardsManager";
import type { UserAnnualAward, BestTime } from "./models/userAnnualAward";

// Only for Offline
class MigratorService {
  private isNeedMigrated: boolean = false;

  private daysInMonth = (year: string, month: string): number => {
    return new Date(Number(year), Number(month), 0).getDate();
  };

  constructor() {
    this.isNeedMigrated = LocalStorage.getIsNeedMigrated();
  }

  doMigrationIfNeed() {
    LocalStorage.removeIsDataMigrated();
    if (this.isNeedMigrated) return;

    const { _id: id } = JSON.parse(LocalStorage.getUserData() || JSON.stringify(""));
    const endDate = new Date();

    for (let year = 2022; year <= endDate.getFullYear(); year++) {
      const completedGames: UserAnnualAward[] = [];

      for (let month = 1; month <= 12; month++) {
        const monthStr = month.toString().padStart(2, "0");
        const yearStr = year.toString();

        const oldWonHistory = LocalStorage.getWonDailyChallenge(monthStr, yearStr);

        if (oldWonHistory) {
          const days = this.daysInMonth(yearStr, monthStr);
          const bestTime: BestTime[] = [];

          for (let day = 1; day <= days; day++) {
            const dayStr = day.toString().padStart(2, "0");
            const oldBestResult = LocalStorage.getBestResult(dayStr, monthStr, yearStr);

            if (oldBestResult) {
              bestTime.push({ day: dayStr, timeSec: Number(oldBestResult) });
            }
          }

          const progress = Math.round((100 / days) * bestTime.length);

          const data: UserAnnualAward = {
            userId: id,
            year: yearStr,
            month: monthStr,
            progress,
            level: LEVELS.EASY.toUpperCase(),
            bestTime,
          };

          completedGames.push(data);
          annualAwardsManager.save(yearStr, completedGames);
          LocalStorage.removeOldDataDailyGames(yearStr, monthStr);
        }
      }
    }

    LocalStorage.removeIsDataMigrated();
  }
}

const migrator = new MigratorService();
export default migrator;
