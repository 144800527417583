import { ReactNode, FC, MouseEventHandler } from "react";
import styles from "./ControlHintsModalWarning.module.scss";

interface Props {
  children: ReactNode;
}

interface OverlayProps extends Props {
  handleCloseModal: MouseEventHandler<HTMLDivElement>;
}

type TitleProps = {
  message: string;
};

type ButtonProps = {
  text: string;
  onCloseClicked: () => void;
};

export const Overlay: FC<OverlayProps> = ({ children, handleCloseModal }) => (
  <div onClick={handleCloseModal} className={styles.overlay}>
    {children}
  </div>
);

export const Modal: FC<Props> = ({ children }) => <div className={styles.modal}>{children}</div>;

export const Message: FC<TitleProps> = ({ message }) => <h3 className={styles.message}>{message}</h3>;

export const Button: FC<ButtonProps> = ({ text, onCloseClicked }) => (
  <button onClick={onCloseClicked} type="button" className={styles.buttonOk}>
    {text}
  </button>
);
