import { ButtonProps, InputProps, TextProps, TitleProps } from "./types";
import { Base } from "@/types/PropTypes";
import styles from "./DeleteAccountModal.module.scss";

export const Title: React.FC<TitleProps> = ({ title }) => <h2>{title}</h2>;

export const Description: React.FC<TextProps> = ({ text }) => <p>{text}</p>;

export const Hint: React.FC<TextProps> = ({ text }) => <span>{text}</span>;

export const ButtonWrapper: React.FC<Base> = ({ children }) => <div className={styles.buttonWrapper}>{children}</div>;

export const CancelButton: React.FC<ButtonProps> = ({ title, onClick }) => (
  <button className={styles.cancelButton} type="button" onClick={onClick}>
    {title}
  </button>
);

export const DeleteButton: React.FC<ButtonProps> = ({ title, onClick, disabled }) => (
  <button className={styles.deleteButton} type="button" disabled={disabled} onClick={onClick}>
    {title}
  </button>
);

export const Input: React.FC<InputProps> = ({ name, onChange, value, placeholder, labelText }) => (
  <label htmlFor={name}>
    <span>{labelText}</span>
    <input type="text" name={name} id={name} onChange={(e) => onChange(e)} value={value} placeholder={placeholder} />
  </label>
);
