const BurgerMenuIcon: React.FC = () => (
  <svg width="24px" height="14px" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.875C0 0.391751 0.488417 0 1.09091 0H22.9091C23.5116 0 24 0.391751 24 0.875C24 1.35825 23.5116 1.75 22.9091 1.75H1.09091C0.488417 1.75 0 1.35825 0 0.875Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-7.64948e-08 7C-3.42479e-08 6.51675 0.732625 6.125 1.63636 6.125L22.3636 6.125C23.2674 6.125 24 6.51675 24 7C24 7.48325 23.2674 7.875 22.3636 7.875L1.63636 7.875C0.732625 7.875 -1.18742e-07 7.48325 -7.64948e-08 7Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 13.125C0 12.6418 0.488417 12.25 1.09091 12.25H22.9091C23.5116 12.25 24 12.6418 24 13.125C24 13.6082 23.5116 14 22.9091 14H1.09091C0.488417 14 0 13.6082 0 13.125Z"
      fill="#fff"
    />
  </svg>
);

export default BurgerMenuIcon;
