import React, { ReactNode, Dispatch, SetStateAction, useState } from "react";
import { useRouter } from "next/router";
import useTranslation, { useRoutes } from "../../helpers/translationTexts/useTranslation";
import style from "./Login.module.scss";
import Router from "next/router";
import GoogleIcon from "../../svg/GoogleIcon";
import { AnalyticsHelper } from "../../utils/analyticEvents";
import { LoginEvents } from "../../constants/analyticEvents";
import { useSession } from "../../context/SessionProvider";
import { LocalStorage } from "../../utils/LocalStorage";
import { LogOutBtnProps, LoginBtnProps } from "./types";
import { LogOutIcon, LoginFacebookIcon, LoginIcon } from "@/svg";

type Props = {
  children: ReactNode;
};
type MenuButtonProps = {
  isMenu: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
  setIsOpenAuthInfoModal: Dispatch<SetStateAction<boolean>>;
  path: string;
};

const AuthBox: React.FC<Props> = ({ children }) => {
  return <div className={style.authBox}>{children}</div>;
};

const Title: React.FC<Props> = ({ children }) => {
  return <h2 className={style.title}> {children} </h2>;
};

const LoginGoogleButton: React.FC<Props> = ({ children }) => {
  const language = useRouter().asPath.split("/")[1];

  const googleOAuthUrl =
    "https://accounts.google.com/o/oauth2/v2/auth?client_id=" +
    process.env.GOOGLE_CLIENT_ID +
    "&redirect_uri=" +
    process.env.GOOGLE_REDIRECT +
    "&response_type=token" +
    "&scope=https://www.googleapis.com/auth/userinfo.profile" +
    "&state=" +
    language;

  return (
    <button
      className={`${style.authButton} ${style.button}`}
      type="button"
      onClick={() => {
        AnalyticsHelper({ action: LoginEvents.googleLoginClicked });
        Router.push(googleOAuthUrl);
      }}
    >
      <GoogleIcon className={style.authButtonIcon} />
      {children}
    </button>
  );
};
const LoginFacebookButton: React.FC<Props> = ({ children }) => {
  const language = useRouter().asPath.split("/")[1];

  const facebookOAuthUrl =
    "https://www.facebook.com/v17.0/dialog/oauth?client_id=" +
    process.env.FACEBOOK_CLIENT_ID +
    "&redirect_uri=" +
    process.env.FACEBOOK_REDIRECT +
    "&response_type=token" +
    "&state=" +
    language;

  return (
    <button
      className={`${style.authButton} ${style.button}`}
      type="button"
      onClick={() => {
        AnalyticsHelper({ action: LoginEvents.facebookLoginClicked });
        Router.push(facebookOAuthUrl);
      }}
    >
      <LoginFacebookIcon className={style.authButtonIcon} />
      {children}
    </button>
  );
};

const GoBackButton: React.FC<Props> = ({ children }) => {
  return (
    <button type="button" className={`${style.button} ${style.goBackButton}`} onClick={() => Router.back()}>
      {children}
    </button>
  );
};

export const LogOutDesktopBtn: React.FC<MenuButtonProps> = ({ setIsOpenAuthInfoModal }) => {
  const { userData } = useSession();

  const logOutText = useTranslation("logOut");

  const toNameInitials = (name: string) => {
    return name
      .split(" ")
      .map((n) => n[0])
      .join("");
  };

  return (
    <button
      type="button"
      onClick={() => {
        AnalyticsHelper({ action: LoginEvents.logoutClicked });
        setIsOpenAuthInfoModal(true);
      }}
      className={`${style.logOutDesktopBtn} `}
    >
      {userData?.name ? toNameInitials(userData?.name) : logOutText}
    </button>
  );
};

export const LoginBtn: React.FC<LoginBtnProps> = ({ isMenu, setMenuOpen, path }) => {
  const loginRouter = useRoutes().Login;
  const logInText = useTranslation("logIn");
  return (
    <button
      onClick={() => {
        AnalyticsHelper({ action: LoginEvents.loginClicked });

        LocalStorage.setPathRedirectAfterLogin(path);
        Router.push(loginRouter);
        isMenu && setMenuOpen(!isMenu);
      }}
      type="button"
      className={style.logInButton}
    >
      <p className={style.loginText}>{logInText}</p>
      <LoginIcon />
    </button>
  );
};

export const LogOutBtn: React.FC<LogOutBtnProps> = ({ setIsOpenAuthInfoModal }) => {
  const logOutText = useTranslation("logOut");
  return (
    <button
      type="button"
      onClick={() => {
        AnalyticsHelper({ action: LoginEvents.logoutClicked });
        setIsOpenAuthInfoModal(true);
      }}
      className={style.logOutButton}
    >
      <p className={style.logoutText}>{logOutText}</p>
      <LogOutIcon />
    </button>
  );
};

export { AuthBox, Title, LoginGoogleButton, LoginFacebookButton, GoBackButton };
