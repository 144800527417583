import * as Props from "./types";
import styles from "./styles.module.scss";

export const Title: React.FC<Props.Text> = ({ title }) => <h3 className={styles.title}>{title}</h3>;

export const Description: React.FC<Props.Text> = ({ description }) => (
  <p className={styles.description}>{description}</p>
);

export const Value: React.FC<Props.Number> = ({ value }) => (
  <p className={styles.value}>{value}</p>
);

export const TextWrapper: React.FC<Props.Base> = ({ children }) => <div className={styles.textWrapper}>{children}</div>;

export const TextLineWrapper: React.FC<Props.Base> = ({ children }) => <div className={styles.textLineWrapper}>{children}</div>;

export const Button: React.FC<Props.Button> = ({ onClick, text }) => (
  <button onClick={onClick} className={styles.button} type="button">
    {text}
  </button>
);

export const ButtonWrapper: React.FC<Props.Base> = ({ children }) => (
  <div className={styles.buttonWrapper}>{children}</div>
);
