"use client";
import { useEffect, useState } from "react";

const useMediaQuery = (breakpoint: string): boolean => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const checkWidth = () => {
        const isMatch = window.matchMedia(breakpoint).matches;
        setMatches(isMatch);
      };

      checkWidth();

      window.addEventListener("resize", checkWidth);

      return () => {
        window.removeEventListener("resize", checkWidth);
      };
    }

    return () => {};
  }, [breakpoint]);

  return matches;
};

export default useMediaQuery;
