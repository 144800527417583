import { LEVELS } from "../constants/levels";
import { LocalStorage } from "../utils/LocalStorage";
import { parseDate } from "../utils/dateHelper";
import BaseManager from "./BaseManager";
import syncManager from "./SyncManager";
import { BestTime, UserAnnualAward } from "./models/userAnnualAward";

class AnnualAwardsManager extends BaseManager {
  load(year: string) {
    // Load user data or local data, if userId is empty
    const storedAwards = LocalStorage.getAnnualAwards(this.getUserId(), year);
    return storedAwards;
  }

  loadMonth(year: string, month: string): UserAnnualAward {
    // Load user data or local data, if userId is empty
    const awards = LocalStorage.getAnnualAwards(this.getUserId(), year);
    const requiredMonth = awards.find((item: any) => {
      return item.month === month;
    });

    return requiredMonth || this.generateMonthGames(month);
  }

  loadMonthGames(year: string, month: string) {
    // Load user data or local data, if userId is empty
    const awards = LocalStorage.getAnnualAwards(this.getUserId(), year);
    const requiredMonth = awards.find((item: any) => {
      return item.month === month;
    });

    return requiredMonth?.bestTime || [];
  }

  /**
   * Save annual awards from server to LocalStorage
   */
  async save(year: string, data: UserAnnualAward[]) {
    const userId = this.getUserId();

    // Store user data, if authorized
    if (userId !== "") {
      LocalStorage.setAnnualAwards(userId, year, data);
    } else {
      LocalStorage.setAnnualAwards("", year, data);
    }
  }

  update(date: string, award: UserAnnualAward) {
    const { year, month } = parseDate(date);

    const awards = LocalStorage.getAnnualAwards(this.getUserId(), year);

    const indexToUpdate = awards.findIndex((item: any) => {
      return item.month === month;
    });

    if (indexToUpdate == -1) {
      awards.push(award);
    } else {
      awards[indexToUpdate] = award;
    }

    this.save(year, awards);
  }

  uploadLocalAwards = async (year: number, lang: string) => {
    if (!this.isUserAuthorized()) {
      return;
    }

    // TODO add uploading logic
  };

  storePassedDailyGames = (date: string, wastedTimeSec: number) => {
    const { year, month, day } = parseDate(date);
    const awardsMonth = this.loadMonth(year, month);

    const indexToUpdate = awardsMonth.bestTime.findIndex((item: any) => {
      return item.day === day;
    });

    const gameToSave: BestTime = { day, timeSec: wastedTimeSec };

    if (indexToUpdate == -1) {
      awardsMonth.bestTime.push(gameToSave);
    } else {
      if (awardsMonth.bestTime[indexToUpdate].timeSec > wastedTimeSec) {
        awardsMonth.bestTime[indexToUpdate] = gameToSave;
      }
    }

    awardsMonth.progress = syncManager.calculateNewProgress(year, month, awardsMonth.bestTime);

    this.update(date, awardsMonth);
  };

  private generateMonthGames(month: string): UserAnnualAward {
    return {
      month,
      progress: 0,
      level: LEVELS.EASY.toUpperCase(),
      bestTime: [],
    };
  }
}
const annualAwardsManager = new AnnualAwardsManager();
export default annualAwardsManager;
