import React, { useCallback, useEffect } from "react";
import { Overlay, Message, Modal, Button } from "./ControlHintsModalWarning.component";
import useTranslation from "../../../helpers/translationTexts/useTranslation";

type ControlHintsModalWarningProps = {
  isOpen: boolean;
  onCloseClicked: () => void;
};

const ControlHintsModalWarning: React.FC<ControlHintsModalWarningProps> = ({ isOpen, onCloseClicked }) => {
  const handleCloseModal = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.target === e.currentTarget) {
        onCloseClicked();
      }
    },
    [onCloseClicked]
  );

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        onCloseClicked();
      }
    };
    window.addEventListener("keydown", handleEscKeyPress);

    return () => {
      window.removeEventListener("keydown", handleEscKeyPress);
    };
  }, [isOpen, onCloseClicked]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return (
    <Overlay handleCloseModal={handleCloseModal}>
      <Modal>
        <Message message={useTranslation("controlHintsModalWarningMessage")} />
        <Button text={useTranslation("ok")} onCloseClicked={onCloseClicked} />
      </Modal>
    </Overlay>
  );
};

export default ControlHintsModalWarning;
