type Props = {
  className?: string;
};

const DeleteIcon: React.FC<Props> = ({ className }) => (
  <svg className={className} viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.1325 1.33285C7.23624 1.12525 7.39572 0.950629 7.59309 0.828542C7.79046 0.706455 8.01792 0.641725 8.25 0.641602H15.75C15.9821 0.641725 16.2095 0.706455 16.4069 0.828542C16.6043 0.950629 16.7638 1.12525 16.8675 1.33285L19.0225 5.6416H22C22.3315 5.6416 22.6495 5.7733 22.8839 6.00772C23.1183 6.24214 23.25 6.56008 23.25 6.8916C23.25 7.22312 23.1183 7.54106 22.8839 7.77549C22.6495 8.00991 22.3315 8.1416 22 8.1416H20.75V21.8916C20.75 22.8862 20.3549 23.84 19.6517 24.5433C18.9484 25.2465 17.9946 25.6416 17 25.6416H7C6.00544 25.6416 5.05161 25.2465 4.34835 24.5433C3.64509 23.84 3.25 22.8862 3.25 21.8916V8.1416H2C1.66848 8.1416 1.35054 8.00991 1.11612 7.77549C0.881696 7.54106 0.75 7.22312 0.75 6.8916C0.75 6.56008 0.881696 6.24214 1.11612 6.00772C1.35054 5.7733 1.66848 5.6416 2 5.6416H4.9775L7.1325 1.33285ZM14.9775 3.1416L16.2275 5.6416H7.7725L9.0225 3.1416H14.9775ZM10.75 11.8916C10.75 11.5601 10.6183 11.2421 10.3839 11.0077C10.1495 10.7733 9.83152 10.6416 9.5 10.6416C9.16848 10.6416 8.85054 10.7733 8.61612 11.0077C8.3817 11.2421 8.25 11.5601 8.25 11.8916V19.3916C8.25 19.7231 8.3817 20.0411 8.61612 20.2755C8.85054 20.5099 9.16848 20.6416 9.5 20.6416C9.83152 20.6416 10.1495 20.5099 10.3839 20.2755C10.6183 20.0411 10.75 19.7231 10.75 19.3916V11.8916ZM15.75 11.8916C15.75 11.5601 15.6183 11.2421 15.3839 11.0077C15.1495 10.7733 14.8315 10.6416 14.5 10.6416C14.1685 10.6416 13.8505 10.7733 13.6161 11.0077C13.3817 11.2421 13.25 11.5601 13.25 11.8916V19.3916C13.25 19.7231 13.3817 20.0411 13.6161 20.2755C13.8505 20.5099 14.1685 20.6416 14.5 20.6416C14.8315 20.6416 15.1495 20.5099 15.3839 20.2755C15.6183 20.0411 15.75 19.7231 15.75 19.3916V11.8916Z"
      fill="white"
    />
  </svg>
);

export default DeleteIcon;
