export const sl: any = {
  ISOcode: "sl",
  countryName: "Slovene",
  language: "Slovenski",
  dailyChallenges: "Dnevni izzivi",
  dailyChallenge: "IZBERITE DNEVNI IZZIV.",
  level: "Raven: ",
  levels: "Ravni",
  blog: "Blog",
  privacyPolicy: "Politika zasebnosti",
  howToPlay: "Kako igrati",
  newGame: "NOVA IGRA",
  undo: "Razveljavi",
  notes: "Opombe",
  hints: "Namigi",
  erase: "Izbriši",
  nowDownload:
    "ZDAJ PRENESI ZDAJ PRENESI ZDAJ PRENESI ZDAJ PRENESI ZDAJ PRENESI ZDAJ PRENESI ZDAJ PRENESI ZDAJ PRENESI ZDAJ PRENESI ZDAJ PRENESI ",
  playBest: "Igrajte najbolje",
  online: "Na spletu",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Vidite, rekli smo vam, da so pravila preprosta!",
  dontWasteYourTime: "Zdaj ne izgubljajte časa z dvomi, najboljša aplikacija ",
  appIsSudokuPro: "za sudoku je Sudoku Pro, ki jo morate preizkusiti!",
  playOnline: "igranje na spletu",
  sudokuTipsAndTrick: "Nasveti in triki za sudoku - Od nooba do SudokuPro",
  goBack: "Vrni se nazaj",
  readBlog: "Preberite blog",
  playSudokuOnline: "Igrajte sudoku na spletu",
  playNow: "IGRATI ZDAJ",
  points: "točke",
  yes: "ja",
  no: "št",
  playMahjong: "Igrajte Mahjong na spletu",
  siteMap: "Zemljevid strani",
  otherGame: "Druge igre",
  historySudoku: "Sudoku zgodovina",
  rulesSudoku: "Pravila za Sudoku",
  tipsSudoku: "Nasveti za sudoku",
  cookieText:
    "Naše spletno mesto uporablja piškotke za prilagajanje vsebine in analizo prometa, kar lahko privede tudi do profiliranja.",
  cancel: "Prekliči",
  oops: "Ups...",
  errorPageNotFound: "Napaka 404. Stran ni bila najdena",
  sorryGoBackHome: "Žal strani, ki ste jo zahtevali, ni bilo mogoče najti. Vrnite se na domačo stran.",
  backToHomepage: "NAZAJ NA DOMAČO STRAN",
  similarArticles: "Podobni članki",
  startYourBrainTraining:
    "Začnite trenirati možgane zdaj, prenesite brezplačno spletno igro Sudoku Pro in se ne pritožujte, da ne morete nehati reševati.",
  //////// Meta and Description /////////
  metaPageTitle: "Kako igrati sudoku - od začetnika do SudokuPro",
  metaPageDescription:
    "Preberite blog SudokuPro in se naučite vseh nasvetov in trikov za igranje Sudokuja, ne glede na to, ali ste popoln začetnik ali napredni igralec. Pridobite preprosto razlago profesionalnih strategij za učinkovitejše igranje in krepitev zdravja možganov",

  metaBlogTitle: "Nasveti in triki za sudoku - od nooba do SudokuPro",
  metaBlogDescription:
    "Preberite naš blog in se naučite vseh trikov v igri Sudoku za začetnike in izkušene igralce. Pridobite preprosto razlago profesionalnih strategij in nasvetov za reševanje Sudokuja.",

  /////////////Basic rules/////////////
  gameRules: {
    subtitle: "Sudoku je enostaven",
    title: "Osnovna pravila igre Sudoku",
    titleText:
      "Če ste novinec v svetu igre sudoku, se nimate česa sramovati. Ta igra je zelo logična in enostavna za razumevanje. Obstajajo 3 osnovna pravila in glede na vrsto, ki jo izberete, lahko pride do sprememb, vendar se osnovni sklop pravil redko spremeni.",
    goal: "Cilj:",
    goalText:
      "Izpolnite mrežo 9×9, tako da vsak stolpec, vrstica in razdelek 3×3 vsebuje številke od 1 do 9. Tradicionalno ni časovne omejitve, vendar nekatere igre Sudoku za možgane dodajajo časovne omejitve, ki silijo k hitrejšemu razmišljanju.",
    ruleTitle: "Kako igrati Sudoku?",
    ruleText:
      "Ko začnete, bodo nekateri kvadrati v mreži 9×9 že vsebovali številke. Vaša naloga je, da z možgansko močjo zapolnite manjkajoča mesta in dokončate mrežo.",
    fail: "Neuspeli boste, če:",
    list: "Katera koli vrstica ima dve enaki številki <list> Kateri koli stolpec ima dve enaki številki <list> Katera koli mreža 3×3 ima dve enaki številki",
    remember: "Ne pozabite:",
    rememberText: "Vsaka številka od 1 do 9 se lahko v vrstici, stolpcu ali mreži 3×3 uporabi samo enkrat.",
  },
  ///////// Months ////////
  January: "Januar",
  February: "Februar",
  March: "Marec",
  April: "April",
  May: "Maj",
  June: "Junij",
  July: "Julij",
  August: "Avgust",
  September: "September",
  October: "Oktober",
  November: "November",
  December: "December",

  /////Win poup////
  youWin: "Zmagali ste!",
  youWinText: "Prenesite aplikacijo za igranje kadar koli in brez kakršnih koli omejitev",
  followUs: "Spremljajte nas v družabnih medijih:",
  winLevel: "Raven",
  startNewGame: "Začni novo igro",
  time: "Čas",
  bestResult: "Najboljši rezultat",
  EASY: "Enostavno",
  MEDIUM: "Srednja",
  HARD: "Težko",
  EXPERT: "Strokovnjak",
  EXTREME: "Ekstremno",
  /////////NoGame RC//////
  sorry: "Žal",
  gameNotFount: "igra ni bila najdena",
  chooseChallenge: "Izberite izziv tukaj:",

  // Login Page
  loginMetaTitle: "Prijavite se v Sudoku Pro",
  loginMetaDescription: "Prijavite se, da shranite napredek v igri in tekmujete z drugimi igralci",
  logIn: "Vpiši se",
  welcomeBack: "Dobrodošel nazaj",
  continueWithGoogle: "Nadaljujte z Googlom",
  continueWithFacebook: "Nadaljujte s Facebookom",
  authorizationFailedMessage: "Avtorizacija ni uspela, poskusite znova pozneje",

  // Registration Page
  registrationMetaTitle: "Dokončaj registracijo - Sudoku Pro",
  registrationMetaDescription:
    "Ostaja le še en majhen korak, da zaključite registracijo in kar najbolje izkoristite igro Sudoku",
  createAnAccount: "Ustvari račun",
  nickName: "Vzdevek",
  country: "Država",
  signUp: "Prijavite se",
  createYourNickName: "Ustvarite svoj vzdevek",
  registrationFailedMessage: "Registracija ni uspela, poskusite znova pozneje",

  // Logout Dialog
  logOutMessage: "Ste prepričani, da se želite odjaviti? Vaš napredek ne bo shranjen",
  logOut: "Odjava",

  // Awards Page
  awardsMetaTitle: "Vaše nagrade - Sudoku Pro",
  awardsMetaDescription: "Dokončajte dnevne izzive v Sudoku Pro in zberite vse nagrade za leto!",
  awards: "Nagrade",
  yourAwards: "Vaše nagrade",
  yourAwardsDescription: "Izpolnite dnevne izzive in prejmite nagrade na koncu vsakega meseca!",
  awardsDontMissASingleDay: "Ne zamudite niti enega dneva - igrajte sudoku!",

  // Monthly Award Dialog
  awardDialogTitle: "Prejeta mesečna nagrada",
  awardDialogDescription: "Opravili ste vse dnevne izzive za en mesec in prejeli nagrado! čestitke!",
  awardShare: "Deliti",

  // Authorization banner
  authorizationBannerMessage: "Prijavite se, da shranite svoj napredek in nagrade 🏆",

  // You Won Dialog
  levelsLeftMessage: "Ostale ravni za pridobitev nagrade: %s",

  // Ratings Block
  topCountriesToday: "TOP 20 držav danes",
  topUsersToday: "TOP 100 uporabnikov danes",
  numberOfUsers: "Število uporabnikov",
  updatedEvery24hours: "Posodobljeno vsakih 24 ur",
  experiencePoints: "Točke izkušenj",
  showFullList: "Prikaži celoten seznam",
  showShortList: "Prikaži kratek seznam",
};
