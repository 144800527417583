import { API_GAME } from "./api";
import { API_ROUTES_GAME } from "./apiRoutes";

export const facebookLogin = async (accessToken: string) => {
  try {
    const { data } = await API_GAME.post(API_ROUTES_GAME.AUTH.LOGIN_FACEBOOK(), {
      facebookToken: accessToken,
    });
    return data;
  } catch (error: any) {
    return { error: error.message };
  }
};

export const googleLogin = async (accessToken: string) => {
  try {
    const { data } = await API_GAME.post(API_ROUTES_GAME.AUTH.LOGIN_GOOGLE(), {
      googleToken: accessToken,
    });
    return data;
  } catch (error: any) {
    return { error: error.message };
  }
};

export const finishRegistration = async (nickname: string, countryCode: string) => {
  try {
    const { data } = await API_GAME.post(API_ROUTES_GAME.AUTH.FINISH_REGISTRATION(), {
      nickname: nickname,
      countryCode: countryCode,
    });
    return data;
  } catch (error: any) {
    return { error: error.message };
  }
};

export const refreshTokenApi = async (refreshToken: string) => {
  try {
    const { data } = await API_GAME.post(API_ROUTES_GAME.AUTH.REFRESH_TOKEN(), { refreshToken });
    return data;
  } catch (error: any) {
    return { error: error.message };
  }
};
