export const kk: any = {
  ISOcode: "kk",
  countryName: "Қазақстан",
  language: "Қазақ тілі",
  blog: "Блог",
  dailyChallenges: "Күнделікті сынақ",
  dailyChallenge: "КҮНДЕЛІК CHALENGE",
  readBlog: "Блогты оқыңыз",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Sudoku Pro - басқатырғыштар ойыны",
  sudokuTipsAndTrick: "Онлайн судоку - кеңестер мен трюктар немесе кәсіби маман болу жолы",
  oops: "Ой...",
  errorPageNotFound: "404 қатесі. Бет табылмады",
  sorryGoBackHome: "Кешіріңіз, сіз сұраған бет табылмады. Негізгі бетке оралыңыз.",
  backToHomepage: "БАСТЫ БЕТКЕ ҚАЙТУ",
  cookieText:
    "Сайтты қарауды жалғастыра отырып, сіз сайттың Құпиялық саясатымен танысқаныңызды растайсыз және cookie файлдарын пайдалануға келісесіз.",
  ok: "Жарайды ма",
  privacyPolicy: "Құпиялылық саясаты",
  howToPlay: "Қалай ойнау керек",
  undo: "Болдырмау",
  notes: "Ескертпелер",
  hints: "Кеңестер",
  erase: "Өшіру",
  newGame: "ЖАҢА ОЙЫН",
  level: "Деңгей: ",
  levels: "Деңгейлер",
  similarArticles: "Ұқсас мақалалар",
  playSudokuOnline: "Онлайн судоку ойнаңыз",
  startYourBrainTraining:
    "Дәл қазір миыңызды дамытуды бастаңыз! Тегін Sudoku Pro онлайн ойынын жүктеп алыңыз және судоку жұмбақтарын шешуді тоқтата алмайтыныңызға шағымдануды тоқтатыңыз.",
  nowDownload: "ҚАЗІР ЖҮКТЕП АЛ ҚАЗІР ЖҮКТЕП АЛ ҚАЗІР ЖҮКТЕП АЛ ҚАЗІР ЖҮКТЕП АЛ ҚАЗІР ЖҮКТЕП АЛ",
  playBest: "Ең жақсы ойнаңыз",
  online: "Желіде",
  playSudoku: "Судоку!",
  seeWeToldYou: "Қараңызшы, біз сізге ережелер оңай екенін айттық!",
  dontWasteYourTime: "Енді күмәнмен уақытыңызды жоғалтпаңыз, ең жақсы судоку қолданбасы - ",
  appIsSudokuPro: "Sudoku Pro және сіз оны қолданып көруіңіз керек!",
  goBack: "Қайтып келу",
  facebook: "Facebook",
  twitter: "Twitter",
  telegram: "Telegram",
  points: "ұпай",
  yes: "Иә",
  no: "Жоқ",
  playMahjong: "Онлайн Маджонг ойнаңыз",
  siteMap: "Сайт картасы",
  otherGame: "Басқа ойындар",
  historySudoku: "Судоку тарихы",
  rulesSudoku: "Судоку ережелері",
  tipsSudoku: "Судокуға арналған кеңестер",

  /////Win poup////
  youWin: "Жеңіс!",
  youWinText: "Кез келген уақытта шектеусіз ойнау үшін қолданбаны жүктеп алыңыз",
  followUs: "Бізді әлеуметтік желілерде бақылаңыз:",
  winLevel: "Деңгей",
  startNewGame: "Жаңа ойынды бастаңыз",
  time: "Уақыт",
  bestResult: "Жақсырақ нәтиже",
  ////////////////////////
  playOnline: "онлайн ойнау",
  cancel: "Болдырмау",
  //////// Meta and Description /////////
  metaPageTitle: "Sudoku қалай ойнауға болады — қарапайымнан Sudoku Pro-ға дейін",
  metaPageDescription:
    "Қазір деңгейіңіз қандай болса да, судоку ойнау туралы кеңестер мен амалдар алу үшін Sudoku Pro блогын оқыңыз. Тиімдірек ойнау және жадыңызды жақсарту үшін күрделі басқатырғыштар ойынының стратегиялары туралы қарапайым түсініктеме алыңыз.",

  metaBlogTitle: "Онлайн судоку - кеңестер мен амалдар | Бастауыштан Sudoku Pro-ға дейін",
  metaBlogDescription:
    "Жаңадан бастағандар мен озық ойыншыларға арналған судокының барлық мүмкіндіктері туралы білу үшін біздің Sudoku Pro блогын оқыңыз. Кәсіби стратегиялардың қарапайым түсіндірмесін және онлайн судокуды шешуге арналған кеңестерді алыңыз.",

  //////Basic rules///////
  gameRules: {
    subtitle: "Судоку қарапайым!",
    title: "Судоку ойынының негізгі ережелері",
    titleText:
      "Егер сіз бастаушы болсаңыз және судокуды қалай шешуге болатынын білмесеңіз, ұялатын ештеңе жоқ. Бұл ойын өте қисынды және судоку ойнауды үйрену өте оңай. 3 негізгі ереже бар және сіз таңдаған басқатырғыш ойынының түріне байланысты кейбір нюанстар болуы мүмкін, бірақ Судокудың негізгі ережелері өзгеріссіз қалады.",
    goal: "Мақсат:",
    goalText:
      "Әрбір баған, жол және 3×3 бөлімінде 1-ден 9-ға дейінгі сандар болатындай етіп 9×9 торын толтырыңыз. Дәстүрлі түрде уақыт шектеуі жоқ, бірақ кейбір онлайн судоку басқатырғыштары уақыт шегін қосады және сізді тезірек ойлауға шақыратын сияқты.",
    ruleTitle: "Судокуды қалай ойнауға болады?",
    ruleText:
      "Сіз бастаған кезде, кейбір 9×9 тор квадраттарында сандар болады. Сіздің міндетіңіз - бос орындарды қалдырмай толтыру.",
    fail: "СІЗ СӘТСІЗ ЕТЕДІҢІЗ ЕГЕР:",
    list: "Кез келген жолда екі бірдей сан бар <list> Кез келген бағанда екі бірдей сан бар <list> Кез келген 3×3 бөлімінде екі бірдей сан бар",
    remember: "ЕСКЕ АЛУ:",
    rememberText: "1-ден 9-ға дейінгі әрбір санды қатарда, бағанда немесе 3×3 бөлімде бір рет қана пайдалануға болады",
  },

  playNow: "Қазір ойнаңыз",
  /////////// Months ////////
  January: "Қаңтар",
  February: "Ақпан",
  March: "Наурыз",
  April: "Сәуір",
  May: "Мамыр",
  June: "Маусым",
  July: "Шілде",
  August: "Тамыз",
  September: "Қыркүйек",
  October: "Қазан",
  November: "Қараша",
  December: "Желтоқсан",
  ///////// Game levels //////
  EASY: "Жеңіл",
  MEDIUM: "Орташа",
  HARD: "Қатты",
  EXPERT: "Сарапшы",
  EXTREME: "Төтенше",
  /////////NoGame RC//////
  sorry: "Кешіріңіз",
  gameNotFount: "ойын табылмады",
  chooseChallenge: "Мұнда тапсырманы таңдаңыз:",

  // Login Page
  loginMetaTitle: "Sudoku Pro жүйесіне кіріңіз",
  loginMetaDescription: "Ойын барысын сақтау және басқа ойыншылармен бәсекелесу үшін жүйеге кіріңіз",
  logIn: "Кіру",
  welcomeBack: "Қайта келуінмен",
  continueWithGoogle: "Google арқылы жүйеге кіріңіз",
  continueWithFacebook: "Facebook арқылы жүйеге кіріңіз",
  authInfoModalTitleFailed: "Авторизация орындалмады",
  authorizationFailedMessage: "Авторизация орындалмады, әрекетті кейінірек қайталаңыз",

  // Registration Page
  registrationMetaTitle: "Тіркеуді аяқтау - Sudoku Pro",
  registrationMetaDescription: "Тіркеуді аяқтау және судоку ойынынан барынша пайда алу үшін бір ғана шағын қадам қалды",
  createAnAccount: "Аккаунты құру",
  nickName: "Лақап аты",
  country: "Ел",
  signUp: "Тіркелу",
  createYourNickName: "Лақап атыңызды жасаңыз",
  authInfoModalRegistrationTitleErrors: "Тіркеу сәтсіз аяқталды",
  registrationFailedMessage: "Тіркеу сәтсіз аяқталды, әрекетті кейінірек қайталаңыз",

  // Logout Dialog
  logOutMessage: "Жүйеден шыққыңыз келетініне сенімдісіз бе? Прогресс сақталмайды",
  logOut: "Шығу",

  // Awards Page
  awardsMetaTitle: "Сіздің марапаттарыңыз - Sudoku Pro",
  awardsMetaDescription:
    "Sudoku Pro бағдарламасында күнделікті тапсырмаларды орындаңыз және жыл бойына барлық сыйақыларды жинаңыз!",
  awards: "Марапаттары",
  yourAwards: "Сіздің марапаттарыңыз",
  yourAwardsDescription: "Күнделікті тапсырмаларды орындаңыз және әр айдың соңында сыйлықтар алыңыз!",
  awardsDontMissASingleDay: "Бір күнді жіберіп алмаңыз - судоку ойнаңыз!",
  getAwards: "Марапаттар алыңыз!",

  // Monthly Award Dialog
  awardDialogTitle: "Ай сайынғы сыйлық алды",
  awardDialogDescription: "Сіз бір ай бойы барлық күнделікті тапсырмаларды орындап, сыйлық алдыңыз! Құттықтаймыз!",
  awardShare: "Бөлісу",

  // Authorization banner
  authorizationBannerMessage: "Прогресс пен марапаттарды сақтау үшін жүйеге кіріңіз 🏆",

  // You Won Dialog
  levelsLeftMessage: "Сыйлықты алу үшін қалған деңгейлер: %s",

  // Ratings Block
  topCountriesToday: "Бүгінгі ТОП 20 ел",
  topUsersToday: "Бүгінгі ТОП 100 пайдаланушы",
  numberOfUsers: "Пайдаланушылар саны",
  updatedEvery24hours: "Әр 24 сағат сайын жаңартылады",
  experiencePoints: "Тәжірибе нүктелері",
  showFullList: "Толық тізімді көрсету",
  showShortList: "Қысқа тізімді көрсетіңіз",

  // ControlHintsModalWarning
  controlHintsModalWarningMessage: "Бұл мүмкіндік күнделікті сынақтарда қол жетімді емес",

  // ConsecutiveDaysWarningModal
  consecutiveDaysModalText: "Қатарынан өткен ойын күндерінің саны. Осы жетістікті жақсарту үшін күн сайын ойнаңыз!",
  consecutiveDaysModalTextLogIn: "Жетістіктеріңізді сақтау үшін жүйеге кіріңіз",
};
