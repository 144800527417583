export const hu: any = {
  ISOcode: "hu",
  countryName: "Hungary",
  language: "Magyar",
  dailyChallenges: "Napi kihívások",
  dailyChallenge: "NAPI KIHÍVÁS",
  level: "Szint: ",
  levels: "Szintek",
  blog: "Blog",
  privacyPolicy: "Adatvédelmi irányelvek",
  howToPlay: "Hogyan kell játszani",
  newGame: "ÚJ JÁTÉK",
  undo: "Undo",
  notes: "Jegyzetek",
  hints: "Hints",
  erase: "Törlés",
  nowDownload:
    "MOST TÖLTSE LE MOST TÖLTSE LE MOST TÖLTSE LE MOST TÖLTSE LE MOST TÖLTSE LE MOST TÖLTSE LE MOST TÖLTSE LE MOST TÖLTSE LE MOST TÖLTSE LE MOST TÖLTSE LE MOST",
  playBest: "Játssz a legjobban",
  online: "Online",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Látod, mondtuk, hogy a szabályok egyszerűek!",
  dontWasteYourTime: "Most ne vesztegesd az idődet kétkedéssel, ",
  appIsSudokuPro: "a legjobb sudoku alkalmazás a Sudoku Pro, és ki kell próbálnod!",
  playOnline: "online játszani",
  points: "pontokat",
  yes: "Igen",
  no: "Nem",
  playMahjong: "Játssz Mahjong Online",
  siteMap: "Webhelytérkép",
  otherGame: "Más játékok",
  historySudoku: "Sudoku története",
  rulesSudoku: "A Sudoku szabályai",
  tipsSudoku: "Tippek a Sudokuhoz",

  sudokuTipsAndTrick: "Sudoku tippek és trükkök - A Noobtól a SudokuPro-ig",
  goBack: "Vissza",
  readBlog: "Blog olvasása",
  playSudokuOnline: "Sudoku online játék",
  playNow: "JÁTÉK MOST",
  cookieText:
    "Weboldalunk cookie-kat használ a tartalom személyre szabásához és a forgalom elemzéséhez, ami profilalkotást is eredményezhet.",
  cancel: "Cancel",
  oops: "Hoppá...",
  errorPageNotFound: "404 hiba. Az oldal nem található",
  sorryGoBackHome: "Sajnáljuk, az Ön által kért oldalt nem találtuk meg. Kérjük, térjen vissza a kezdőlapra.",
  backToHomepage: "VISSZA A KEZDŐLAPRA",
  similarArticles: "Hasonló cikkek",
  startYourBrainTraining:
    "Kezdd el most az agytorna edzését, töltsd le az ingyenes online Sudoku Pro játékot, és ne panaszkodj, hogy nem tudod abbahagyni a megoldást.",
  //////// Meta and Description /////////
  metaPageTitle: "Hogyan kell Sudokut játszani - a Noobtól a SudokuPro-ig",
  metaPageDescription:
    "Olvassa el a SudokuPro blogot, hogy megtanulja az összes tippet és trükköt a Sudoku játékhoz, függetlenül attól, hogy abszolút kezdő vagy haladó játékos vagy. Egyszerű magyarázatot kaphatsz a profi stratégiákról, hogy hatékonyabban játszhass és fokozd az agyad egészségét.",

  metaBlogTitle: "Sudoku tippek és trükkök - Noobtól a SudokuPro-ig",
  metaBlogDescription:
    "Olvassa el blogunkat, hogy megtanulja az összes trükköt a Sudoku-ban kezdőknek és tapasztalt játékosoknak. Könnyű magyarázatot kaphatsz a profi stratégiákról és tippekről a Sudoku megoldásához.",

  /////////////Basic rules/////////////
  gameRules: {
    subtitle: "Sudoku könnyedén",
    title: "A Sudoku alapvető szabályai",
    titleText:
      "Ha újonc vagy a Sudoku világában, akkor sem kell szégyenkezned. Ez a játék nagyon logikus és könnyen elsajátítható. Van 3 alapszabály, és attól függően, hogy milyen típust választasz, lehetnek módosítások, azonban az alapszabálykészlet ritkán változik.",
    goal: "Cél:",
    goalText:
      "Egy 9×9-es rács kitöltése úgy, hogy minden oszlop, sor és 3×3-as rész tartalmazza az 1 és 9 közötti számjegyeket. Hagyományosan nincs időkorlát, de egyes Sudoku játékok az agynak időbeli korlátokat adnak hozzá, amelyek gyorsabb gondolkodásra ösztönöznek.",
    ruleTitle: "Hogyan kell Sudokut játszani?",
    ruleText:
      "Amikor elkezded, a 9×9-es rács néhány négyzetében már lesznek számjegyek. A feladatod az, hogy az agyadat használva pótold a hiányzó helyeket, és töltsd ki a rácsot.",
    fail: "Nem sikerül, ha:",
    list: "Bármelyik sorban két azonos szám <list> Bármelyik oszlopban két azonos szám <list> Bármelyik 3×3-as rácsban két azonos szám van.",
    remember: "Ne feledd!",
    rememberText: "Minden számjegy 1-től 9-ig csak egyszer használható egy sorban, oszlopban vagy 3×3-as rácsban.",
  },

  /////////// Months ////////
  January: "Január",
  February: "Február",
  March: "Március",
  April: "Április",
  May: "Május",
  June: "Június",
  July: "Július",
  August: "Augusztus",
  September: "Szeptember",
  October: "Október",
  November: "November",
  December: "December",

  /////Win poup////
  youWin: "Te nyertél!!",
  youWinText: "Töltse le az alkalmazást, hogy bármikor és korlátozások nélkül játsszon",
  followUs: "Kövess minket a közösségi médiában:",
  winLevel: "Szint",
  startNewGame: "Új játék indítása",
  time: "Idő",
  bestResult: "Legjobb eredmény",
  EASY: "Könnyű",
  MEDIUM: "Közepes",
  HARD: "Nehéz",
  EXPERT: "Szakértő",
  EXTREME: "Extrém",
  /////////NoGame RC//////
  sorry: "Bocsánat",
  gameNotFount: "játék nem található",
  chooseChallenge: "Válasszon kihívást itt:",

  // Login Page
  loginMetaTitle: "Jelentkezzen be a Sudoku Pro-ba",
  loginMetaDescription: "Jelentkezzen be, hogy mentse a játék előrehaladását, és versenyezzen más játékosokkal",
  logIn: "Belépés",
  welcomeBack: "Üdv újra",
  continueWithGoogle: "Folytassa a Google-lal",
  continueWithFacebook: "Folytatás a Facebookon",
  authorizationFailedMessage: "Az engedélyezés nem sikerült, próbálkozzon újra később",

  // Registration Page
  registrationMetaTitle: "Fejezd be a regisztrációt - Sudoku Pro",
  registrationMetaDescription:
    "Már csak egy kis lépés van hátra a regisztráció befejezéséhez és a Sudoku játékból a legtöbbet kihozásához",
  createAnAccount: "Hozzon létre egy fiókot",
  nickName: "Becenév",
  country: "Ország",
  signUp: "Regisztrálj",
  createYourNickName: "Hozd létre a becenevedet",
  registrationFailedMessage: "A regisztráció sikertelen, próbálkozzon újra később",

  // Logout Dialog
  logOutMessage: "Biztosan ki akar jelentkezni? A haladás nem kerül mentésre",
  logOut: "Kijelentkezés",

  // Awards Page
  awardsMetaTitle: "Az Ön díjai – Sudoku Pro",
  awardsMetaDescription: "Teljesítsd a napi kihívásokat a Sudoku Pro-ban, és gyűjtsd be az év összes jutalmát!",
  awards: "Díjak",
  yourAwards: "Az Ön díjai",
  yourAwardsDescription: "Teljesítsd a napi kihívásokat, és minden hónap végén nyerj jutalmakat!",
  awardsDontMissASingleDay: "Ne hagyj ki egyetlen napot sem – játssz sudokut!",

  // Monthly Award Dialog
  awardDialogTitle: "Havi díjat kapott",
  awardDialogDescription: "Egy hónapig teljesítetted az összes napi kihívást, és díjat kaptál! Gratulálunk!",
  awardShare: "Ossza meg",

  // Authorization banner
  authorizationBannerMessage: "Jelentkezzen be, hogy elmentse fejlődését és díjait 🏆",

  // You Won Dialog
  levelsLeftMessage: "A jutalom megszerzéséhez még hátralévő szintek: %s",

  // Ratings Block
  topCountriesToday: "A TOP 20 ország ma",
  topUsersToday: "A TOP 100 felhasználó ma",
  numberOfUsers: "Felhasználók száma",
  updatedEvery24hours: "24 óránként frissítve",
  experiencePoints: "Tapasztalati pontok",
  showFullList: "Teljes lista megjelenítése",
  showShortList: "Mutasson egy rövid listát",
};
