import Link from "next/link";
import { Base } from "../types";
import styles from "./HeaderContent.module.scss";
import { BurgerBtn, Btn, LinkT, AnnualAwardsCountT, ConsecutiveDaysT, DailyProgressT } from "./types";
import { BurgerMenuCrossIcon, BurgerMenuIcon, CupIcon, MedalIcon, RocketIcon, SudokuProIcon } from "@/svg";
import { useRoutes } from "@/helpers/translationTexts/useTranslation";

export const Wrapper: React.FC<Base> = ({ children }) => <div className={styles.wrapper}>{children}</div>;

export const BurgerButton: React.FC<BurgerBtn> = ({ isMenuOpen, onClick }) => (
  <button
    onClick={onClick}
    className={styles.burgerBtn}
    type="button"
    aria-label="Menu"
    aria-expanded={isMenuOpen ? true : false}
  >
    {isMenuOpen ? <BurgerMenuCrossIcon /> : <BurgerMenuIcon />}
  </button>
);

export const HeaderContentBtn: React.FC<Btn> = ({ children, onClick }) => (
  <button type="button" onClick={onClick} className={styles.btn}>
    {children}
  </button>
);

export const StyledLink: React.FC<LinkT> = ({ children, href, onClick }) => (
  <Link href={href} className={styles.link} onClick={onClick}>
    {children}
  </Link>
);

export const LinkWrapper: React.FC<Base> = ({ children }) => <div className={styles.linkWrapper}>{children}</div>;

export const Logo = () => (
  <Link href={useRoutes().Root} className={styles.logo}>
    <SudokuProIcon />
  </Link>
);

export const AnnualAwardsCount: React.FC<AnnualAwardsCountT> = ({ awardsRoute, awardsCount, onClick }) => (
  <StyledLink href={awardsRoute} onClick={onClick}>
    <CupIcon />
    {awardsCount}
  </StyledLink>
);

export const ConsecutiveDays: React.FC<ConsecutiveDaysT> = ({ consecutiveDays, onClick }) => (
  <HeaderContentBtn onClick={onClick}>
    <MedalIcon />
    {consecutiveDays}
  </HeaderContentBtn>
);

export const DailyProgress: React.FC<DailyProgressT> = ({
  dailyProgress,
  dailySudokuRoute,
  onClick,
  dailyChallengesText,
}) => (
  <StyledLink href={dailySudokuRoute} onClick={onClick}>
    <span className={styles.dailyChallengesText}>{dailyChallengesText}</span>
    <RocketIcon className={styles.rocketIcon} />
    {dailyProgress}
  </StyledLink>
);
