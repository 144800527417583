import { useRouter } from "next/router";
import { en } from "./en";
import { es } from "./es";
import { uk } from "./uk";
import { de } from "./de";
import { pl } from "./pl";
import { pt } from "./pt";
import { fr } from "./fr";
import { el } from "./el"; // Greek
import { it } from "./it";
import { rom } from "./rom";
import { bg } from "./bg";
import { et } from "./et";
import { tr } from "./tr";
import { lt } from "./lt";
import { lv } from "./lv";
import { da } from "./da";
import { sv } from "./sv";
import { fi } from "./fi";
import { cs } from "./cs";
import { hu } from "./hu";
import { nl } from "./nl";
import { sl } from "./sl";
import { sk } from "./sk";
import { id } from "./id";
import { no } from "./no";
import { ja } from "./ja";
import { ko } from "./ko";
import { sr } from "./sr";
import { sq } from "./sq";
import { hr } from "./hr";
import { bs } from "./bs";
import { vi } from "./vi";
import { th } from "./th";
import { mk } from "./mk";
import { ms } from "./ms";
import { zh } from "./zh";
import { ka } from "./ka";
import { kk } from "./kk";

export default function useTranslation(text: string | any) {
  const langPath = useRouter().asPath.split("/")[1];
  const langISO = langPath.length !== 2 ? "en" : langPath;

  switch (langISO) {
    case "en":
      return en?.[text];
    case "uk":
      if (uk?.[text] === undefined) {
        return en?.[text];
      }
      return uk?.[text];
    case "de":
      if (de?.[text] === undefined) {
        return en?.[text];
      }
      return de?.[text];
    case "es":
      if (es?.[text] === undefined) {
        return en?.[text];
      }
      return es?.[text];
    case "pt":
      if (pt?.[text] === undefined) {
        return en?.[text];
      }
      return pt?.[text];
    case "pl":
      if (pl?.[text] === undefined) {
        return en?.[text];
      }
      return pl?.[text];
    case "fr":
      if (fr?.[text] === undefined) {
        return en?.[text];
      }
      return fr?.[text];
    case "el":
      if (el?.[text] === undefined) {
        return en?.[text];
      }
      return el?.[text];
    case "it":
      if (it?.[text] === undefined) {
        return en?.[text];
      }
      return it?.[text];
    case "ro":
      if (rom?.[text] === undefined) {
        return en?.[text];
      }
      return rom?.[text];
    case "bg":
      if (bg?.[text] === undefined) {
        return en?.[text];
      }
      return bg?.[text];
    case "et":
      if (et?.[text] === undefined) {
        return en?.[text];
      }
      return et?.[text];
    case "tr":
      if (tr?.[text] === undefined) {
        return en?.[text];
      }
      return tr?.[text];
    case "lt":
      if (lt?.[text] === undefined) {
        return en?.[text];
      }
      return lt?.[text];
    case "lv":
      if (lv?.[text] === undefined) {
        return en?.[text];
      }
      return lv?.[text];
    case "da":
      if (da?.[text] === undefined) {
        return en?.[text];
      }
      return da?.[text];
    case "sv":
      if (sv?.[text] === undefined) {
        return en?.[text];
      }
      return sv?.[text];
    case "fi":
      if (fi?.[text] === undefined) {
        return en?.[text];
      }
      return fi?.[text];
    case "cs":
      if (cs?.[text] === undefined) {
        return en?.[text];
      }
      return cs?.[text];
    case "hu":
      if (hu?.[text] === undefined) {
        return en?.[text];
      }
      return hu?.[text];
    case "nl":
      if (nl?.[text] === undefined) {
        return en?.[text];
      }
      return nl?.[text];
    case "sl":
      if (sl?.[text] === undefined) {
        return en?.[text];
      }
      return sl?.[text];
    case "sk":
      if (sk?.[text] === undefined) {
        return en?.[text];
      }
      return sk?.[text];
    case "id":
      if (id?.[text] === undefined) {
        return en?.[text];
      }
      return id?.[text];
    case "no":
      if (no?.[text] === undefined) {
        return en?.[text];
      }
      return no?.[text];
    case "ja":
      if (ja?.[text] === undefined) {
        return en?.[text];
      }
      return ja?.[text];
    case "ko":
      if (ko?.[text] === undefined) {
        return en?.[text];
      }
      return ko?.[text];
    case "sr":
      if (sr?.[text] === undefined) {
        return en?.[text];
      }
      return sr?.[text];
    case "sq":
      if (sq?.[text] === undefined) {
        return en?.[text];
      }
      return sq?.[text];
    case "hr":
      if (hr?.[text] === undefined) {
        return en?.[text];
      }
      return hr?.[text];
    case "bs":
      if (bs?.[text] === undefined) {
        return en?.[text];
      }
      return bs?.[text];
    case "vi":
      if (vi?.[text] === undefined) {
        return en?.[text];
      }
      return vi?.[text];
    case "th":
      if (th?.[text] === undefined) {
        return en?.[text];
      }
      return th?.[text];
    case "mk":
      if (mk?.[text] === undefined) {
        return en?.[text];
      }
      return mk?.[text];
    case "ms":
      if (ms?.[text] === undefined) {
        return en?.[text];
      }
      return ms?.[text];
    case "zh":
      if (zh?.[text] === undefined) {
        return en?.[text];
      }
      return zh?.[text];
    case "ka":
      if (ka?.[text] === undefined) {
        return en?.[text];
      }
      return ka?.[text];
    case "kk":
      if (kk?.[text] === undefined) {
        return en?.[text];
      }
      return kk?.[text];
    default:
      return en?.[text];
  }
}

export const useRoutes = (language?: string) => {
  const router = useRouter();
  const langPath = language ? language : router.asPath.split("/")[1];

  if (langPath.length === 2) {
    return {
      Root: `/${langPath}`,
      Login: `/${langPath}/login`,
      Awards: `/${langPath}/awards`,
      Registration: `/${langPath}/registration`,
      Blog: `/${langPath}/blog`,
      DailySudoku: `/${langPath}/daily-sudoku`,
      Privacy: `/${langPath}/privacy-policy`,
      Easy: `/${langPath}/easy`,
      Medium: `/${langPath}/medium`,
      Hard: `/${langPath}/hard`,
      Expert: `/${langPath}/expert`,
      Extreme: `/${langPath}/extreme`,
      PlayDailySudoku: `/${langPath}/play-daily-sudoku`,
      HowToPlay: `/${langPath}/how-to-play`,
      howDeleteAccount: `${langPath}/how-to-delete-account`,
      HistorySudoku: `/${langPath}/history-sudoku`,
    };
  } else {
    return {
      Root: "/",
      Login: "/login",
      Awards: "/awards",
      Registration: "/registration",
      Blog: "/blog",
      DailySudoku: "/daily-sudoku",
      Privacy: "/privacy-policy",
      Easy: "/easy",
      Medium: "/medium",
      Hard: "/hard",
      Expert: "/expert",
      Extreme: "/extreme",
      PlayDailySudoku: "/play-daily-sudoku",
      HowToPlay: "/how-to-play",
      howDeleteAccount: "/how-to-delete-account",
      HistorySudoku: "/history-sudoku",
    };
  }
};
