export const lv: any = {
  ISOcode: "lv",
  countryName: "Latvia",
  language: "Latviešu",
  dailyChallenges: "Ikdienas izaicinājumi",
  dailyChallenge: "IKDIENAS IZAICINĀJUMS",
  level: "Līmenis: ",
  levels: "Līmeņi",
  blog: "Dienasgrāmata",
  privacyPolicy: "Privātuma politika",
  howToPlay: "Kā spēlēt",
  newGame: "NAUJAS ŽAIDIMAS",
  undo: "Atcelt",
  notes: "Piezīmes",
  hints: "Padomi",
  erase: "Izdzēst",
  nowDownload:
    "TAGAD LEJUPIELĀDĒT TAGAD LEJUPIELĀDĒT TAGAD LEJUPIELĀDĒT TAGAD LEJUPIELĀDĒT TAGAD LEJUPIELĀDĒT TAGAD LEJUPIELĀDĒT TAGAD LEJUPIELĀDĒT TAGAD LEJUPIELĀDĒT TAGAD LEJUPIELĀDĒT TAGAD LEJUPIELĀDĒT TAGAD LEJUPIELĀDĒT TAGAD ",
  playBest: "Spēlējiet vislabāk",
  online: "Tiešsaistē",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Redziet, mēs jums teicām, ka noteikumi ir vienkārši!",
  dontWasteYourTime: "Tagad netērējiet laiku šaubām,",
  appIsSudokuPro: "labākā sudoku lietotne ir Sudoku Pro, un jums tā ir jāizmēģina!",
  playOnline: "spēlēt tiešsaistē",
  points: "taškų",
  yes: "Taip",
  no: "Nr",
  playMahjong: "Žaisti Mahjong Online",
  siteMap: "Vietnes karte",
  otherGame: "Citas spēles",
  historySudoku: "Sudoku vēsture",
  rulesSudoku: "Sudoku noteikumi",
  tipsSudoku: "Padomi par Sudoku",
  sudokuTipsAndTrick: "Sudoku padomi un triki - No Noob To SudokuPro",
  goBack: "Atgriezties",
  readBlog: "Lasīt blogu",
  playSudokuOnline: "Spēlējiet Sudoku tiešsaistē",
  playNow: "SPĒLĒJIET TAGAD",
  cookieText:
    "Mūsu tīmekļa vietnē tiek izmantotas sīkdatnes, lai personalizētu saturu un analizētu mūsu datplūsmu, kas var izraisīt arī profilēšanu.",
  cancel: "Atcelt",
  oops: "Ooops...",
  errorPageNotFound: "404 kļūda. Lapa nav atrasta",
  sorryGoBackHome: "Atvainojamies, bet jūsu pieprasīto lapu neizdevās atrast. Lūdzu, dodieties atpakaļ uz sākumlapu.",
  backToHomepage: "ATPAKAĻ UZ SĀKUMLAPU",
  similarArticles: "Līdzīgi raksti",
  startYourBrainTraining:
    "Sāc trenēt savas smadzenes tagad, lejupielādē bezmaksas tiešsaistes spēli Sudoku Pro un nesūdzies, ka nespēj pārtraukt risināt.",
  //////// Meta and Description /////////
  metaPageTitle: "Kā spēlēt Sudoku - no No Noob līdz SudokuPro",
  metaPageDescription:
    "Lasiet SudokuPro blogu, lai uzzinātu visus padomus un trikus Sudoku spēlē neatkarīgi no tā, vai esat pilnīgs iesācējs vai pieredzējis spēlētājs. Saņemiet vienkāršu skaidrojumu par profesionāļu stratēģijām, lai spēlētu efektīvāk un uzlabotu savu smadzeņu veselību.",
  metaBlogTitle: "Sudoku padomi un triki - no 'Noob' līdz SudokuPro",
  metaBlogDescription:
    "Lasiet mūsu blogu, lai uzzinātu visus Sudoku trikus iesācējiem un pieredzējušiem spēlētājiem. Saņemiet vienkāršu skaidrojumu par profesionāļu stratēģijām un padomiem Sudoku risināšanai.",
  ///////////Basic rules/////////////
  gameRules: {
    subtitle: "Sudoku padarīts viegli",
    title: "Sudoku pamatnoteikumi",
    titleText:
      "Ja esat Sudoku pasaules iesācējs, nav par ko kaunēties. Šī spēle ir ļoti loģiska un viegli uztverama. Pastāv 3 pamatnoteikumi, un atkarībā no izvēlētā veida var būt izmaiņas, tomēr pamatnoteikumu kopums mainās reti.",
    goal: "Mērķis:",
    goalText:
      "Aizpildiet 9×9 režģi tā, lai katrā slejā, rindā un 3×3 iedaļā būtu cipari no 1 līdz 9. Tradicionāli nav laika ierobežojuma, taču dažās Sudoku spēlēs smadzenēm ir pievienoti laika ierobežojumi, kas liek domāt ātrāk.",
    ruleTitle: "Kā spēlēt Sudoku?",
    ruleText:
      "Kad sāksiet, dažos 9×9 kvadrātu režģa laukumos jau būs cipari. Tavs uzdevums ir izmantot prāta spēku, lai aizpildītu trūkstošās vietas un pabeigtu režģi.",
    fail: "Jums neizdosies, ja:",
    list: "Jebkurā rindā ir divi vienādi skaitļi <list> Jebkurā slejā ir divi vienādi skaitļi <list> Jebkurā 3×3 režģī ir divi vienādi skaitļi <list>",
    remember: "Atcerieties:",
    rememberText: "Katru ciparu no 1 līdz 9 var izmantot tikai vienu reizi rindā, slejā vai 3×3 režģī.",
  },
  /////////// Months ////////
  January: "Janvāris",
  February: "Februāris",
  March: "Marts",
  April: "Aprīlis",
  May: "Maijs",
  June: "Jūnijs",
  July: "Jūlijs",
  August: "Augusts",
  September: "Septembris",
  October: "Oktobris",
  November: "Novembris",
  December: "Decembris",
  /////Win poup////
  youWin: "Tu esi uzvarējis!!",
  youWinText: "Lejupielādējiet lietotni, lai spēlētu jebkurā laikā un bez ierobežojumiem",
  followUs: "Sekojiet mums sociālajos tīklos:",
  winLevel: "Lygis",
  startNewGame: "Sākt jaunu spēli",
  time: "Laiks",
  bestResult: "Labākais rezultāts",
  EASY: "Viegli",
  MEDIUM: "Vidēji",
  HARD: "Grūti",
  EXPERT: "Eksperts",
  EXTREME: "Ekstrēms",
  /////////NoGame RC//////
  sorry: "Atvainojiet",
  gameNotFount: "spēle nav atrasta",
  chooseChallenge: "Izvēlies izaicinājumu šeit:",

  // Login Page
  loginMetaTitle: "Piesakieties Sudoku Pro",
  loginMetaDescription: "Piesakieties, lai saglabātu spēles gaitu un sacenstos ar citiem spēlētājiem",
  logIn: "Log In",
  welcomeBack: "Laipni lūdzam atpakaļ",
  continueWithGoogle: "Turpiniet ar Google",
  continueWithFacebook: "Turpināt ar Facebook",
  authorizationFailedMessage: "Autorizācija neizdevās, vēlāk mēģiniet vēlreiz",

  // Registration Page
  registrationMetaTitle: "Pabeigt reģistrāciju - Sudoku Pro",
  registrationMetaDescription:
    "Lai pabeigtu reģistrāciju un gūtu maksimālu labumu no Sudoku spēles, atlicis tikai viens mazs solis",
  createAnAccount: "Izveidot kontu",
  nickName: "Segvārds",
  country: "Valsts",
  signUp: "Pierakstīties",
  createYourNickName: "Izveidojiet savu segvārdu",
  registrationFailedMessage: "Reģistrācija neizdevās, vēlāk mēģiniet vēlreiz",

  // Logout Dialog
  logOutMessage: "Vai tiešām vēlaties atteikties? Jūsu progress netiks saglabāts",
  logOut: "Izlogoties",

  // Awards Page
  awardsMetaTitle: "Jūsu balvas — Sudoku Pro",
  awardsMetaDescription: "Pabeidziet ikdienas izaicinājumus programmā Sudoku Pro un saņemiet visas gada balvas!",
  awards: "Apbalvojumi",
  yourAwards: "Jūsu balvas",
  yourAwardsDescription: "Izpildi ikdienas izaicinājumus un saņemiet balvas katra mēneša beigās!",
  awardsDontMissASingleDay: "Nepalaidiet garām nevienu dienu — spēlējiet sudoku!",

  // Monthly Award Dialog
  awardDialogTitle: "Saņemta mēneša balva",
  awardDialogDescription: "Jūs esat izpildījis visus ikdienas izaicinājumus mēneša garumā un saņēmis balvu! Apsveicam!",
  awardShare: "Dalīties",

  // Authorization banner
  authorizationBannerMessage: "Piesakieties, lai saglabātu savu progresu un balvas 🏆",

  // You Won Dialog
  levelsLeftMessage: "Atlikušie līmeņi, lai saņemtu atlīdzību: %s",

  // Ratings Block
  topCountriesToday: "TOP 20 valstis šodien",
  topUsersToday: "TOP 100 lietotāji šodien",
  numberOfUsers: "Lietotāju skaits",
  updatedEvery24hours: "Atjaunināts ik pēc 24 stundām",
  experiencePoints: "Pieredzes punkti",
  showFullList: "Rādīt pilnu sarakstu",
  showShortList: "Parādiet īsu sarakstu",
};
