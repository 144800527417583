export const ms: any = {
  ISOcode: "ms",
  countryName: "Malaysia",
  language: "Malay",
  blog: "Blog",
  dailyChallenges: "Cabaran Harian",
  dailyChallenge: "Cabaran Harian",
  readBlog: "Baca blog",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Sudoku Pro ialah permainan teka-teki",
  sudokuTipsAndTrick: "Sudoku dalam talian - petua dan petua, atau cara menjadi seorang profesional",
  oops: "Ooh...",
  errorPageNotFound: "Ralat 404. Laman tidak dijumpai",
  sorryGoBackHome: "Maaf, halaman yang anda minta tidak ditemui. Sila kembali ke halaman utama.",
  backToHomepage: "KEMBALI KE HALAMAN UTAMA",
  cookieText:
    "Dengan meneruskan melayari laman web ini, anda mengesahkan bahawa anda telah membaca Polisi Privasi laman web ini dan bersetuju untuk menggunakan kuki (cookies).",
  ok: "OK",
  privacyPolicy: "Dasar Privasi",
  howToPlay: "Cara bermain",
  undo: "Buat asal",
  notes: "Nota",
  hints: "Petunjuk",
  erase: "Padam",
  newGame: "Permainan baru",
  level: "Tahap: ",
  levels: "Tahap",
  similarArticles: "Artikel yang serupa",
  playSudokuOnline: "Main sudoku dalam talian",
  startYourBrainTraining:
    "Mula kembangkan minda anda sekarang! Muat turun permainan Sudoku Pro dalam talian secara percuma dan jangan mengeluh bahawa anda tidak dapat berhenti menyelesaikan teka-teki Sudoku.",
  nowDownload:
    "MUAT TURUN SEKARANG MUAT TURUN SEKARANG MUAT TURUN SEKARANG MUAT TURUN SEKARANG MUAT TURUN SEKARANG MUAT TURUN SEKARANG",
  playBest: "Main terbaik",
  online: "dalam talian",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Lihat, kami memberitahu anda bahawa peraturan adalah mudah!",
  dontWasteYourTime: "Jangan buang masa lagi dengan keraguan, aplikasi",
  appIsSudokuPro: "terbaik untuk bermain Sudoku adalah Sudoku Pro, dan anda perlu mencubanya!",
  goBack: "Pergi balik",
  facebook: "Facebook",
  twitter: "Twitter",
  telegram: "Telegram",
  points: "mata",
  yes: "ya",
  no: "Tidak",
  playMahjong: "Main Mahjong Dalam Talian",
  siteMap: "Peta tapak",
  otherGame: "Permainan lain",
  historySudoku: "Sejarah Sudoku",
  rulesSudoku: "Peraturan untuk Sudoku",
  tipsSudoku: "Petua untuk Sudoku",
  /////Win poup////
  youWin: "Kemenangan!",
  youWinText: "Muat turun aplikasi untuk bermain pada bila-bila masa tanpa had",
  followUs: "Ikuti kami di rangkaian sosial:",
  winLevel: "Tahap",
  startNewGame: "Mulakan Permainan Baharu",
  time: "Masa",
  bestResult: "Keputusan Terbaik",
  ////////////////////////
  playOnline: "Bermain dalam talian",
  cancel: "Batal",
  //////// Meta and Description /////////
  metaPageTitle: "Cara bermain Sudoku — daripada orang awam kepada Sudoku Pro",
  metaPageDescription:
    "Baca blog Sudoku Pro untuk mendapatkan tips dan petua tentang cara bermain sudoku, tidak kira tahap anda sekarang. Dapatkan penjelasan mudah tentang strategi yang rumit dalam permainan logik untuk bermain dengan lebih efektif dan meningkatkan ingatan anda.",

  metaBlogTitle: "Sudoku Online - petua dan petua | Dari pemula hingga Sudoku Pro",
  metaBlogDescription:
    "Baca blog Sudoku Pro kami untuk mengetahui semua keistimewaan sudoku bagi pemula dan pemain berpengalaman. Dapatkan penjelasan mudah tentang strategi profesional dan tips untuk menyelesaikan sudoku dalam talian.",

  //////Basic rules///////

  gameRules: {
    subtitle: "Sudoku adalah mudah!",
    title: "Peraturan asas permainan Sudoku",
    titleText:
      "Jika anda seorang pemula dan tidak tahu bagaimana untuk menyelesaikan Sudoku, tiada apa yang perlu dipersoalkan. Permainan ini sangat logik dan belajar cara bermain Sudoku sangat mudah. Terdapat 3 peraturan asas, dan bergantung kepada jenis permainan logik yang anda pilih, mungkin terdapat beberapa nuansa, tetapi peraturan asas permainan Sudoku kekal tidak berubah.",
    goal: "Matlamat:",
    goalText:
      "Isi petak 9x9 dengan angka dari 1 hingga 9 sedemikian rupa sehingga setiap kolom, baris, dan seksi 3x3 mengandungi angka-angka tersebut. Secara tradisi, tidak ada batasan waktu, tetapi beberapa Sudoku online memperkenalkan batasan waktu dan mendorong pemikiran yang lebih cepat.",
    ruleTitle: "Bagaimana untuk bermain Sudoku?",
    ruleText:
      "Apabila anda memulakan, beberapa petak dalam petak 9x9 akan mempunyai angka-angka tertentu. Tugas anda adalah mengisi ruang yang kosong tanpa meninggalkan sebarang petak yang tidak diisi.",
    fail: "Anda kalah jika:",
    list: "Mana-mana baris mengandungi dua nombor yang sama <list> Mana-mana lajur mempunyai dua nombor yang sama <list> Mana-mana seksyen 3×3 mempunyai dua nombor yang sama.",
    remember: "INGAT:",
    rememberText: "Setiap angka dari 1 hingga 9 hanya boleh digunakan sekali dalam baris, lajur atau seksyen 3×3.",
  },
  playNow: "Main sekarang",
  /////////// Months ////////
  January: "Januari",
  February: "Februari",
  March: "Mac",
  April: "April",
  May: "Mei",
  June: "Jun",
  July: "Julai",
  August: "Ogos",
  September: "September",
  October: "Oktober",
  November: "November",
  December: "Disember",
  ///////// Game levels //////
  EASY: "Mudah",
  MEDIUM: "Sederhana",
  HARD: "Keras",
  EXPERT: "Pakar",
  EXTREME: "Melampau",
  /////////NoGame RC//////
  sorry: "Maaf",
  gameNotFount: "Permainan tidak ditemui",
  chooseChallenge: "Pilih cabaran di sini:",
};
