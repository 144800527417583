/* eslint-disable react-hooks/rules-of-hooks */
import { useRoutes } from "../helpers/translationTexts/useTranslation";

export const isEnglishLang = (path: string) => {
  return Boolean((path === "/" || path.includes("en")) && path !== "404");
};

export const isMainPageCheck = (path: string) => {
  const langPath = path.split("/")[1];
  const routePath = path.split("/")[2];
  return Boolean(path === useRoutes().Root || (langPath.length <= 2 && !routePath?.length));
};

export const isDailySudokuGameCheck = (path: string) => {
  return Boolean(path.includes("play-daily-sudoku"));
};

export const isCalendarDailyCheck = (path: string) => {
  return Boolean(path.includes("daily-sudoku"));
};

export const isBlogPageCheck = (path: string) => {
  return Boolean(path.includes("blog"));
};
