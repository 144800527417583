import Router from "next/router";
import useTranslation, { useRoutes } from "../../../helpers/translationTexts/useTranslation";
import { CrossIcon } from "../../../svg";
import styles from "./MonthlyAwardModal.module.scss";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

type OverlayProps = {
  children: ReactNode;
  handleCloseModal: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};
type CupProps = {
  monthCup: string;
};

type CloseProps = {
  handleCloseModal: () => void;
};

const MonthlyAwardModalOverlay: React.FC<OverlayProps> = ({ children, handleCloseModal }) => (
  <div onClick={(e) => handleCloseModal(e)} className={styles.modalOverlay}>
    {children}
  </div>
);

const MonthlyAwardModalContent: React.FC<Props> = ({ children }) => <div className={styles.modal}>{children}</div>;

const CloseModalButton: React.FC<CloseProps> = ({ handleCloseModal }) => (
  <button onClick={() => handleCloseModal()} className={styles.closeAwardButton} type="button">
    <CrossIcon className={styles.closeModalIcon} />
  </button>
);

const MonthlyAwardModalTitle: React.FC = () => (
  <h2 className={styles.modalTitle}>{useTranslation("awardDialogTitle")}</h2>
);

const MonthlyAwardModalText: React.FC = () => (
  <p className={styles.modalText}>{useTranslation("awardDialogDescription")}</p>
);

const MonthlyAwardModalCup: React.FC<CupProps> = ({ monthCup }) => (
  <div className={styles.modalCupImgWrapper}>
    <img className={styles.modalCupImg} src={monthCup} alt="cup" />
  </div>
);

const MonthlyAwardModalOKButton: React.FC = ({}) => {
  const rootPath = useRoutes().DailySudoku;

  return (
    <button onClick={() => Router.push(rootPath)} className={styles.modalOkButton} type="button">
      OK
    </button>
  );
};

export {
  MonthlyAwardModalOverlay,
  MonthlyAwardModalContent,
  CloseModalButton,
  MonthlyAwardModalTitle,
  MonthlyAwardModalText,
  MonthlyAwardModalCup,
  MonthlyAwardModalOKButton,
};
