import AuthInfoModal from "./AuthInfoModal";
import BaseModal from "./BaseModal/BaseModal";
import ConsecutiveDaysWargingModal from "./ConsecutiveDaysWargingModal/ConsecutiveDaysWargingModal";
import ControlHintsModalWarning from "./ControlHintsModalWarning";
import DeleteAccountModal from "./DeleteAccountModal/DeleteAccountModal";
import MonthlyAwardModal from "./MonthlyAwardModal";
import SelectCountryModal from "./SelectCountryModal";

export {
  MonthlyAwardModal,
  AuthInfoModal,
  BaseModal,
  ConsecutiveDaysWargingModal,
  ControlHintsModalWarning,
  SelectCountryModal,
  DeleteAccountModal,
};
