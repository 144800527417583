import { API_GAME } from "./api";
import { API_ROUTES_GAME } from "./apiRoutes";

export const getAnnualAwards = async (year: string) => {
  try {
    const { data } = await API_GAME.get(API_ROUTES_GAME.AWARDS.GET_ANNUAL_AWARDS(year));
    return data;
  } catch (error: any) {
    return { error: error.message };
  }
};
