import { TABLET_MEDIA_QUERY } from "@/constants/breakpoints";
import useMediaQuery from "./useMediaQuery";

const useWrapsTextParentheses = (text: string | number): string => {
  const isTablet = useMediaQuery(TABLET_MEDIA_QUERY);

  if (isTablet) {
    return `(${text})`;
  }

  return text.toString();
};

export default useWrapsTextParentheses;
