const BurgerMenuCrossIcon: React.FC = () => (
  <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.824676 20.3878C0.507291 20.0095 0.709942 19.3169 1.27731 18.8409L21.8235 1.60058C22.3908 1.1245 23.1081 1.04519 23.4255 1.42344C23.7429 1.80168 23.5402 2.49425 22.9728 2.97032L2.42666 20.2106C1.85929 20.6867 1.14206 20.766 0.824676 20.3878Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.1762 20.5768C22.8589 20.9551 22.1416 20.8758 21.5743 20.3997L1.02808 3.15942C0.460713 2.68334 0.258062 1.99077 0.575447 1.61253C0.892832 1.23428 1.61007 1.31359 2.17743 1.78967L22.7236 19.03C23.291 19.506 23.4936 20.1986 23.1762 20.5768Z"
      fill="#fff"
    />
  </svg>
);

export default BurgerMenuCrossIcon;
