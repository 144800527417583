import {
  BurgerButton,
  LinkWrapper,
  Wrapper,
  Logo,
  AnnualAwardsCount,
  ConsecutiveDays,
  DailyProgress,
} from "./HeaderContent.components";
import { HeaderContentT } from "./types";
import { LogOutDesktopBtn, LoginBtn } from "@/components/Login/Login.components";
import isUserAuthorized from "@/helpers/isUserAuthorized";
import { useEffect } from "react";
import useMediaQuery from "@/utils/useMediaQuery";
import { TABLET_MEDIA_QUERY } from "@/constants/breakpoints";

const HeaderContent: React.FC<HeaderContentT> = ({
  consecutiveDays,
  annualAwardsCount,
  handleOpenConsecutiveModal,
  awardsRoute,
  dailySudokuRoute,
  path,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  dailyChallengesText,
  setIsOpenAuthInfoModal,
  dailyProgress,
}) => {
  const authorized = isUserAuthorized();

  const isTablet = useMediaQuery(TABLET_MEDIA_QUERY);

  const toggleMobileMenu = () => setIsMobileMenuOpen((prev) => !prev);
  const handleCloseMobileMenu = () => setIsMobileMenuOpen(false);

  useEffect(() => {
    if (isTablet) handleCloseMobileMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTablet]);
  return (
    <Wrapper>
      <BurgerButton onClick={toggleMobileMenu} isMenuOpen={isMobileMenuOpen} />

      <LinkWrapper>
        <DailyProgress
          dailyProgress={dailyProgress}
          dailySudokuRoute={dailySudokuRoute}
          onClick={handleCloseMobileMenu}
          dailyChallengesText={dailyChallengesText}
        />

        <ConsecutiveDays consecutiveDays={consecutiveDays} onClick={handleOpenConsecutiveModal} />

        <AnnualAwardsCount awardsCount={annualAwardsCount} awardsRoute={awardsRoute} onClick={handleCloseMobileMenu} />
      </LinkWrapper>
      {!authorized && <LoginBtn isMenu={isMobileMenuOpen} setMenuOpen={setIsMobileMenuOpen} path={path} />}
      {authorized && (
        <LogOutDesktopBtn
          isMenu={isMobileMenuOpen}
          setMenuOpen={setIsMobileMenuOpen}
          path={path}
          setIsOpenAuthInfoModal={setIsOpenAuthInfoModal}
        />
      )}
    </Wrapper>
  );
};

export default HeaderContent;
