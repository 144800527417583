import { NextRouter } from "next/router";
import { LANGUAGES } from "../constants/language";

export default function changeLanguage(language: string, router: NextRouter) {
  language = language.toLocaleLowerCase();

  const currentLanguage = router.asPath.split("/")[1].length == 2 ? router.asPath.split("/")[1] : "";

  const isEn = router.asPath.split("/")[1].length > 2;
  const pathLang = router.asPath.split("/")[1];
  const pathLevel = router.asPath.split("/")[2];
  let basicLevelRoutes = isEn ? pathLang : pathLevel;

  let basePath;

  if (basicLevelRoutes !== undefined && language !== LANGUAGES.EN) {
    basePath = router.asPath
      .replace(`/${currentLanguage}`, `/${language}`)
      .replace(pathLevel ? pathLevel : basicLevelRoutes, `/${basicLevelRoutes}`);
  }
  if (basicLevelRoutes !== undefined && language === LANGUAGES.EN) {
    basePath = router.asPath.replace(`/${currentLanguage}`, "").replace(pathLevel, `${basicLevelRoutes}`);
  }
  if (basicLevelRoutes === undefined && language !== LANGUAGES.EN) {
    basePath = router.asPath.replace(`/${currentLanguage}`, `/${language}/`);
  }
  if (basicLevelRoutes === undefined && language === LANGUAGES.EN) {
    basePath = router.asPath.replace(`/${currentLanguage}`, `/`);
  }
  basePath = basePath?.replace("//", "/");
  router.push(`${basePath}`);
}
