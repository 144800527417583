import React, { ReactNode, useEffect } from "react";
import dynamic from "next/dynamic";
import Header from "../Header";
import Footer from "../Footer";

import { StyledContent } from "../common/styles";
import { useSession } from "../../context/SessionProvider";
import SyncManager from "../../api/SyncManager";
import migrator from "../../api/MigratorService";
import pointsManager from "../../api/PointsManager";
import { currentDateAsString } from "../../utils/dateHelper";

type Props = {
  children: ReactNode;
};
const FirstRunPopupContainer = dynamic(() => import("../FirstRunPopupContainer"));

const AppContent: React.FC<Props> = ({ children }) => {
  const {
    annualAwardsCount,
    setAnnualAwardsCount,
    userData,
    userPoints,
    setUserPoints,
    setShockModeData,
    setDailyProgress,
  } = useSession();

  const { date, year } = currentDateAsString();

  useEffect(() => {
    const syncAll = async () => {
      migrator.doMigrationIfNeed();

      const dailyProgress = await SyncManager.getDailyProgress();
      setDailyProgress(dailyProgress);

      if (userData) {
        await pointsManager.getPoints(date);
        await SyncManager.syncData(year);
        const shockModeData = await SyncManager.getShockModeData();
        setShockModeData(shockModeData);
      }

      if (!annualAwardsCount) {
        const awardsCount: number = SyncManager.getAnnualAwardsCount(year);
        setAnnualAwardsCount(awardsCount);
      }

      if (!userPoints) {
        const points: number = pointsManager.load();
        setUserPoints(points);
      }
    };

    syncAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <>
      <Header />
      <StyledContent>{children}</StyledContent>
      <FirstRunPopupContainer />
      <Footer />
    </>
  );
};

export default AppContent;
