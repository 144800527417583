import { locale } from "./translationTexts";
import { Award } from "../types/awards";
import { UserAnnualAward } from "../api/models/userAnnualAward";

const getTransformedDataAwards = (awardArr: UserAnnualAward[], langPath: string): Award[] => {
  const currentLocale = locale(langPath);
  const months = [
    currentLocale.January,
    currentLocale.February,
    currentLocale.March,
    currentLocale.April,
    currentLocale.May,
    currentLocale.June,
    currentLocale.July,
    currentLocale.August,
    currentLocale.September,
    currentLocale.October,
    currentLocale.November,
    currentLocale.December,
  ];

  return months.map((month: string, index: number) => {
    const foundedMonth = awardArr.find((item) => {
      return Number(item.month) == index + 1;
    });

    const progress = foundedMonth ? foundedMonth.progress : 0;
    return {
      month,
      progress,
      svgPath: index > 8 ? String(index + 1) : `0${index + 1}`,
    };
  });
};

export default getTransformedDataAwards;
