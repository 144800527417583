import { LocalStorage } from "../utils/LocalStorage";

export default class BaseManager {
  protected isUserAuthorized() {
    const accessToken = LocalStorage.getAccessToken();
    const userData = this.getUserData();

    return accessToken && userData && userData.nickname && userData.countryCode;
  }

  protected getUserData(): UserData | null {
    const user = LocalStorage.getUserData();
    return user ? JSON.parse(user) : null;
  }

  protected getUserId() {
    const user = LocalStorage.getUserData();
    const userData = user ? JSON.parse(user) : null;

    return userData?._id || "";
  }
}
