import { useEffect } from "react";
import { useRouter } from "next/router";
import * as Props from "./types";
import useTranslation, { useRoutes } from "../../../helpers/translationTexts/useTranslation";
import { isEnglishLang } from "../../../utils/checkPageByPath";
import { BlogLink, DailyChallengesLink, Logo, PlaySudokuLink, Wrapper } from "./MobileMenu.components";
import { BuyUsACoffee, Socials } from "@/components/common";

const MobileMenu: React.FC<Props.MobileMenu> = ({ isMenuOpen, setMenuOpen, setIsOpenAuthInfoModal, dailyProgress }) => {
  const path = useRouter().asPath;
  const isEn = isEnglishLang(path);

  const blogText = useTranslation("readBlog");
  const dailyChallengeText = useTranslation("dailyChallenges") + " " + dailyProgress;
  const playSudokuText = useTranslation("playSudokuOnline");
  const rootRoute = useRoutes().Root;
  const dailyRoute = useRoutes().DailySudoku;
  const blogRoute = useRoutes().Blog as string;

  useEffect(() => {
    if (isMenuOpen) document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isMenuOpen]);

  const handleCloseMenu = () => setMenuOpen(false);

  return (
    <Wrapper isMenuOpen={isMenuOpen}>
      <Logo href={rootRoute} onClick={handleCloseMenu} />

      <BuyUsACoffee section="header" />

      <PlaySudokuLink href={rootRoute} onClick={handleCloseMenu} title={playSudokuText} />

      <DailyChallengesLink href={dailyRoute} onClick={handleCloseMenu} title={dailyChallengeText} />

      <BlogLink href={blogRoute} onClick={handleCloseMenu} title={blogText} isShow={isEn} />

      <Socials section="mobileMenu" />
    </Wrapper>
  );
};

export default MobileMenu;
