import React from "react";
import { Icon } from "./types";

const ChevronDownIcon: React.FC<Icon> = ({ className }) => (
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M11.2902 0.290003C11.1977 0.197299 11.0878 0.123752 10.9668 0.0735702C10.8459 0.0233888 10.7162 -0.00244144 10.5852 -0.00244145C10.4543 -0.00244146 10.3246 0.0233887 10.2036 0.0735702C10.0826 0.123752 9.97274 0.197299 9.88022 0.290003L6.00022 4.17L2.12022 0.290002C2.02764 0.19742 1.91773 0.123981 1.79677 0.073876C1.6758 0.023771 1.54615 -0.00201841 1.41522 -0.00201842C1.28429 -0.00201843 1.15464 0.023771 1.03368 0.0738759C0.912715 0.123981 0.802803 0.19742 0.710221 0.290002C0.61764 0.382584 0.544201 0.492495 0.494096 0.613459C0.443991 0.734423 0.418202 0.864072 0.418202 0.995002C0.418202 1.12593 0.443991 1.25558 0.494096 1.37655C0.544201 1.49751 0.617639 1.60742 0.710221 1.7L5.30022 6.29C5.39274 6.38271 5.50262 6.45625 5.6236 6.50644C5.74457 6.55662 5.87425 6.58245 6.00522 6.58245C6.13619 6.58245 6.26587 6.55662 6.38685 6.50644C6.50782 6.45625 6.61771 6.38271 6.71022 6.29L11.3002 1.7C11.6802 1.32 11.6802 0.680003 11.2902 0.290003Z"
      fill="white"
    />
  </svg>
);

export default ChevronDownIcon;
